import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { trackPromise } from 'react-promise-tracker'

const HandleUploadImages = async(base64Image: string, idNumber: number, setDisplay: (c: string) => void) => {
    try{
        const res = await trackPromise( axios.post(
            API_ADMIN + '/v5/card/uploadFile',
            {
                idRequest: idNumber,
                fileName:sessionStorage.getItem('userIdNumber') + Date(),
                url:"",
                data: base64Image
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ))
        setDisplay('none');
        swal("Exitoso", "Los fotos se cargaron de forma exitosa",'success');
    }catch (e){
        swal('Error','No se pudieron gargar las imagenes','error');
    }
   
}

export default HandleUploadImages
