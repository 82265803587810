import axios from 'axios';
import React, { useState } from 'react'
import { trackPromise } from 'react-promise-tracker';
import { API_ADMIN } from '../../../api/GlobalApi';
import { GetDataLinkPayResponse } from '../../interfaces/GetDataLinkPay';


const HandleEditLinkPay = async(payKey: string) => {

    let data = {}
    let statePay: string;
    let resp: any
    
    try {
        const res = await trackPromise(axios.post<GetDataLinkPayResponse>(
          API_ADMIN + "/v5/linkpay/getDataLinkPay",
          {
            payKey: payKey,
          },
    
          {
            headers: {
              "Content-Type": "application/json",
            },
          })

          
        );
    
        const storage = () => {

          data = {
            customerIdNumber: res.data.customerIdNumber,
            customerTypeIdNumber: res.data.customerTypeIdNumber,
            userClientName: res.data.userClientName,
            userClientLastName: res.data.userClientLastName,
            userClientIdNumber: res.data.userClientIdNumber,
            userClientTypeNumber: res.data.userClientTypeNumber,
            userClientPhone: res.data.userClientPhone,
            userClientEmail: res.data.userClientEmail,
            amountPay: res.data.amountPay,
            detailPay: res.data.detailPay,
            dueDatePay: '',
            currencyPay: res.data.currencyPay,
            expirationTimePay: res.data.expirationTimePay,
            payKey: res.data.payKey,
            linkPay: res.data.shortLinkPay,
            invoiceNumber: res.data.invoiceNumber,
            expired: res.data.expired,
            statePay: res.data.statePay,
            id: res.data.id
            
          }
          
        sessionStorage.setItem('link', res.data.shortLinkPay)          
          resp = [res.data.statePay, data]
        }

        storage()
        
      } catch (e) {
        //   (e)
      }

      return resp
    
}

export default HandleEditLinkPay
