import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useContext } from "react";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import ChatBotList from "./ChatBotList";
import HomeList from "./HomeList";
import RLinkList from "./RLinkList";
import SecurityList from "./SecurityList";
import SinpeList from "./SinpeList";
import LogoutIcon from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import "./style/sidebar.css";
import AdaList from "./AdaList";
import SinpeMassiveList from "./SinpeMassiveList";
import CardList from "./CardList";

const ListSideBar = (clasess: any, rLink: string) => {
  const { widths, isOpen } = useContext(ContextLayOut);
  const history = useHistory();
  
  const logOut = () => {
    sessionStorage.removeItem("token");
    history.push("/");
  };

    (sessionStorage.getItem("ada"))

  return (
    <>
      <List style={{ textDecoration: "none" }} component="nav">
        {<HomeList clasess={clasess.clasess} />}
        {sessionStorage.getItem("rlink") === "useRlink" ? <RLinkList clasess={clasess.clasess} /> : ""}
        {sessionStorage.getItem("chatBot") === "useChatBot" ? (
          <ChatBotList clasess={clasess.clasess}/>
        ) : (
          ""
        )}
        {sessionStorage.getItem("sinpe") === "asignarSinpe" ? (
          <SinpeList clasess={clasess.clasess} />
        ) : (
          ""
        )}
        {
          sessionStorage.getItem("card") === 'useCard'?<CardList clasess={clasess.clasess}/>:''
        }
        {sessionStorage.getItem("ada") === "consultarAda"? (
          <AdaList clasess={clasess.clasess} />
        ): ("")}
        {sessionStorage.getItem("sinpeMassive") === 'sinpeMassive'?(
          <SinpeMassiveList clasess={clasess.clasess} />
        ):("")}
       <SecurityList clasess={clasess.clasess} />
      </List>
      <Divider className="secondDivider" />
      <ListItem
        
        className={clasess.clasess.items}
        onClick={(e) => {
          logOut();
        }}
        style={{ width: `${ widths + (!isOpen?3:-5)}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon className={clasess.clasess.icon}>
          <LogoutIcon aria-label="cerrar sesion" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>Cerrar Sesión</ListItemText>
        )}
      </ListItem>
    </>
  );
};

export default ListSideBar;
