import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from "date-fns/locale/en-US";
import { Button, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, Select, TextField, TextFieldProps } from '@mui/material';
import { MenuItem } from '@material-ui/core';

const DialogSinpeFilter = (formulario:any, handleChange: any, valueSince: string, setValueSince: (c:string) => void, valueTo: string, setValueTo: (c:string) => void, props: any) => {
    return (
        <>
        <DialogTitle className="titleDialog">Búsqueda de SINPE Móvil</DialogTitle>
        <Grid container alignItems="center" justifyContent="center" spacing="15">
          
          <Grid xs={5} sm={5} item>
          <FormControl fullWidth>
                    <InputLabel>Estado</InputLabel>
                    <Select
                     
                      size="small"
                      label="Estado"
                      name="state"
                      value={
                        formulario.state
                      }
                      onChange={handleChange}
                    >
                       <MenuItem value="Confirmado">Confirmado</MenuItem>
                      <MenuItem value="Pendiente">Pendiente</MenuItem>
                      
                      
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                 
          </Grid>
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-11px" }}
                    label="Número de Identificación"
                    name="idNumber"
                    value={
                      formulario.idNumber
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Número de teléfono"
                    name="phone"
                    value={
                      formulario.phone
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Nombre"
                    name="name"
                    value={
                      formulario.name
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={10} sm={10} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Descripción"
                    name="description"
                    value={
                      formulario.description
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
         
          {/* <Grid xs={5} sm={5} item>
          <FormControl sx={{position:'relative'}} fullWidth>
                    <InputLabel>Moneda</InputLabel>
                    <Select
                     
                      size="small"
                      label="Monto"
                      name="amount"
                      value={
                        formulario.amount
                      }
                      onChange={handleChange}
                    >
                     <MenuItem value="CRC">Colones</MenuItem>
                      <MenuItem value="USD">Dólares</MenuItem>
                      <MenuItem value="EUR">Euros</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText></FormHelperText>
                 
          </Grid> */}
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          
          <Grid xs={5} sm={5} item>
          <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Desde"
                    value={valueSince}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue: any) => {
                      if (newValue !== null) {
                        
                        setValueSince(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
          <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                  
                >
                  <DatePicker
                  
                    label="Hasta"
                    value={valueTo}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue: any) => {
                      if (newValue !== null) {
                        
                        setValueTo(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '-5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid item xs={12}></Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{background: "gray", marginBottom: "10px" }}
              
              aria-label="Crear Link"
              variant="contained"
              onClick={() => {
                props.setSearchDialog(!props.searchDialog)
              }}
              fullWidth
            >
              Buscar
            </Button>
            <Button
              style={{background: "gray", marginBottom: "10px" }}
              
              aria-label="limpiar filtros"
              variant="contained"
              onClick={() => {
                window.location.reload();
              }}
              fullWidth
            >
              Limpiar filtros
            </Button>
          </Grid>
          
        </Grid>
        </>
    )
}

export default DialogSinpeFilter

