import VirtualCard from './VirtualCard'
import VirtualCardButtons from './VirtualCardButtons'
import { InfoCardResponse } from '../interfaces/CardsInterfaces'
import { Grid } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';

const CardDetail = (data: InfoCardResponse | any,setVirtualCard:(c:boolean) => void ) =>{
  
    return (
        <Grid container alignItems="center" justifyContent="center" spacing="15">  
        <Grid xs={12} sm={12} item>
        <ClearIcon onClick={() => setVirtualCard(false)}className='exit'/>  
        </Grid> 
        <Grid xs={12} sm={12} item>
          { VirtualCard(data)}
        </Grid>
        <Grid style={{textAlign: 'center'}}xs={12} sm={12} item>
        <h3>Saldo: {data?.cards[0]?.currency+" "+data?.cards[0]?.balance}</h3>
        </Grid>
        <Grid xs={12} sm={5} item>
        {VirtualCardButtons(data?.state,  data?.cards[0]?.card)}
        </Grid>
        </Grid>
    )
}

export default CardDetail
