import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { API_ADMIN } from "../../api/GlobalApi";
import { ListSinpeResponse } from "../../rlinkPay/interfaces/ListSinpeInterface";
import swal from "sweetalert";
import DateTransform from "../../hooks/utils/DateTransform";

const HandleListSinpe = async (
  setPages: (c: number) => void,
  page: number,
  setSinpe: any,
  description: string,
  name: string,
  phone: string,
  idNumber: string,
  idUser: string,
  amount: string,
  state: string,
  since: any,
  to: any
) => {
  try {
    const res = await trackPromise(
      axios.post<ListSinpeResponse>(
        API_ADMIN + "/v5/SinpeMovilEntrante/listSinpe",
        {
          page: page,
          size: 10,
          description: description,
          name: name,
          phone: phone,
          id_client: idNumber,
          id_user: idUser,
          amount: amount,
          state: state,
          dateStart: since !== "" ?DateTransform(DateTransform(since)) : "",
          dateEnd: to !== "" ? DateTransform(DateTransform(to)) : "",
          reference: "",
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
    );

    setPages(res.data.pageCount);
    setSinpe(res.data.sinpe);
  } catch (error) {
    swal("Error", "No se pudo obtener el listado de sinpe","error")
  }
};

export default HandleListSinpe;
