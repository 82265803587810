import { makeStyles } from "@material-ui/core/styles";
import { Suspense } from "react";
import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import ListUsersArticle from "./ListUsersArticle";
import useValidateJwt from "../../hooks/useValidateJwt";


const ListUsers = () => {
  const mouseDownHandler = useValidateJwt();


    const styles = makeStyles((theme) => ({
        offset: theme.mixins.toolbar,
        content: {
          padding: theme.spacing(3),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
    
        },
      }));
    
      const style4 = styles();
    return (
        <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <Suspense fallback={<div></div>}>
      <NavBar route={'Lista de usuarios'}/>
      <SideBar />
      <div className={style4.content}>
        <div className={style4.offset}></div>
            <div style={{marginTop:'30px', width: "100%", height: "90%"}}>
            
            <ListUsersArticle />
            
            </div>
      
      </div>
      </Suspense>
    </div>
    )
}

export default ListUsers
