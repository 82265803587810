import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { API_ADMIN } from "../../../../api/GlobalApi";
import { List } from "../../../interfaces/ListMasiveLinksInterface";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import SearchIcon from "@mui/icons-material/Search";
import ReusableDialogs from "../../../../reusableFunctionalComponents/ReusableDialogs";
import DateTransform from "../../../../hooks/utils/DateTransform";

const ListMasiveLinksHistory = () => {
  const [links, setLinks] = useState<List[]>([]);
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = useState(false);
  const [dif, setDif] = useState<string>("");
  const [dateSince, setDateSince] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [searchDialog, setSearchDialog] = useState<boolean>(false);
  const [idNumber, setIdNumber] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [data, setData] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };
  useEffect(() => {
    const FetchApi = async () => {
      try {
        const res = await trackPromise(
          axios.post(
            API_ADMIN + "/v5/linkpay/listMassive",
            {
              page: page,
              size: 7,
              id: idNumber,
              state: state,
              dateStart: DateTransform(dateSince),
              dateEnd: DateTransform(to),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            }
          )
        );
        setPages(res.data.pageCount);
        setLinks(res.data.list);
      } catch (error) {
        swal("Error", "No se pudo cargar el listado de masivas", "error");
      }
    };

    FetchApi();
  }, [page, searchDialog]);

  useEffect(() => {
    setPage(1);
  }, [searchDialog]);

  return (
    <div className="articleContainer">
      <div className="paperContainer">
        <Paper className="muiPaper">
          <TableContainer className="tableContainer">
            <Toolbar>
              <Button
                onClick={(e) => {
                  history.push("/createMassiveLinks");
                }}
                style={{ color: "#000" }}
              >
                <AddIcon className="addIcon" aria-label="Crear Links Masivos" />{" "}
                Crear Link Masivos
              </Button>
              <Typography style={{ flexGrow: 1 }}> </Typography>

              <Button
                onClick={() => {
                  setDif("filter");
                  handleClickOpen();
                }}
                variant="contained"
              >
                <SearchIcon />
              </Button>
            </Toolbar>

            <Table style={{ zIndex: 19 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="cell">Id</TableCell>
                  <TableCell className="cell">Fecha de creación</TableCell>
                  <TableCell className="cell">Cantidad</TableCell>
                  <TableCell className="cell">Estado</TableCell>
                  <TableCell className="cell">Porcentaje</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {links.map((link: any) => (
                  <TableRow
                    className="rowTable"
                    onClick={async () => {
                      setDif("");
                      setData(link);
                      handleClickOpen();
                    }}
                    key={link.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="row">{link.id}</TableCell>
                    <TableCell className="row">{link.create_at}</TableCell>
                    <TableCell className="row">{link.amount}</TableCell>
                    <TableCell className="row">{link.state_massive}</TableCell>
                    <TableCell className="row">
                      {link.percentage + "%"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              page={page}
              count={pages}
              color="primary"
              shape="rounded"
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </TableContainer>

          <ReusableDialogs
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            id={data}
            dif={dif}
            setDateSince={setDateSince}
            setTo={setTo}
            setSearchDialog={setSearchDialog}
            searchDialog={searchDialog}
            setIdNumber={setIdNumber}
            setState={setState}
            component={"massiveLinkHistory"}
          />
        </Paper>
      </div>
    </div>
  );
};

export default ListMasiveLinksHistory;
