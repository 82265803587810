import { makeStyles, Drawer } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import ridivi from "./style/assets/logoHeader.png";
import miniridivi from "./../../assets/iconoLogin.png";
import { FC, useContext, useEffect, useState } from "react";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import "./style/sidebar.css";
import ListSideBar from "./ListSideBar";

const SideBar: FC = () => {
  const { widths, setWidths, isOpen, setIsOpen } = useContext(ContextLayOut);
  const [rLink, setRlink] = useState<string>("");

  useEffect(() => {
    let validationRlink: null | string = sessionStorage.getItem("rlink");
    if (validationRlink !== null) {
      setRlink(validationRlink);
    }
  }, []);
  useEffect(() => {
    const prueba = () => {
      if (!isOpen) {
        setWidths(180);
      } else {
        setWidths(50);
      }
    };
    prueba();
  }, [isOpen]);

  const estilos = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    drawer: {
      width: widths,
      flexShrink: 0,
      zIndex: 20,
      overflow: 'hidden'
    },
    drawerPaper: {
      width: widths,
      background: "#4b4b4b",
      display: "flex",
      overflow: 'hidden',
      alignItems: "center",
      zIndex: 20,
    },
    icon: {
      minWidth: "30px",
      position: "relative",
      left: "-8px",
    },
    items: {
      "&:hover": {
        backgroundColor: "rgba(251,129,63,.9)",
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
    },
    tittle: {
      position: "relative",
      top: "2px",
      color: "#fff",
      flexGrow: 1,
      
    },
    tittles: {
      position: "relative",
      top: "2px",
      left: "-20px",
      color: "#fff",
      fontSize: "10px",
    },
  }));
  const clasess = estilos();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      className={clasess.drawer}
      classes={{ paper: clasess.drawerPaper }}
    >
      {isOpen ? (
        <div className="divImgClose">
          <img
            onClick={toggle}
            className="imgClose"
            alt="Logo Ridivi"
            src={miniridivi}
          />
        </div>
      ) : (
        <img
          onClick={toggle}
          className="imgOpen"
          alt="logo ridivi"
          src={ridivi}
        />
      )}
      <Divider className="divider" />
      <ListSideBar clasess={clasess} rLink={rLink} />
    </Drawer>
  );
};

export default SideBar;