import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, DialogTitle, Grid, TextField, TextFieldProps } from '@mui/material';
import enLocale from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from '@mui/lab/DatePicker';
import DateTransform from '../../../../hooks/utils/DateTransform';

const FilterMassiveDialog = (formulario:any, handleChange:any, valueSince: any, setValueSince: any,valueTo: any, setValueTo:any, props:any) => {
  const {to, dateSince} = props;  
  return (
        <>
        <DialogTitle className="titleDialog">
          Busqueda filtrada
        </DialogTitle>
        <Grid
          style={{ width: "100%", height: "250px" }}
          container
          alignItems="center"
          justifyContent="center"
          spacing="15"
        >
          
        <Grid xs={5} sm={5} item>
        <TextField
                  
                  style={{ position: "relative", top: "-5px" }}
                  label="Estado"
                  name="state"
                  value={
                    formulario.state
                  }
                  size="small"
                  onChange={handleChange}
                  // validators={[`${regexp}`]}
                  // errorMessages={[
                  //   "Ingrese su número de identificación sin guiones ni espacios",
                  // ]}
                  // InputLabelProps={{ className: "labels" }}

                  fullWidth
                />
        </Grid>
        <Grid xs={5} sm={5} item>
        <TextField
                  
                  style={{ position: "relative", top: "-5px" }}
                  label="Id"
                  name="id"
                  value={
                    formulario.id
                  }
                  size="small"
                  onChange={handleChange}
                  // validators={[`${regexp}`]}
                  // errorMessages={[
                  //   "Ingrese su número de identificación sin guiones ni espacios",
                  // ]}
                  // InputLabelProps={{ className: "labels" }}

                  fullWidth
                />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Grid xs={5} sm={5} item>
        <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Desde"
                  value={valueSince}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      
                      setValueSince(newValue);
                      
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '5%', height:'60px',}} {...params} />}
                />
              </LocalizationProvider>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
        <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
                
              >
                <DatePicker
                
                  label="Hasta"
                  value={valueTo}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      
                      setValueTo(newValue);
                      
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '-5%', height:'60px',}} {...params} />}
                />
              </LocalizationProvider>
        </Grid>
        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item xs={12}></Grid>
        <Grid xs={11} sm={10.3} item>
          <Button
            style={{background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
            
            aria-label="Crear Link"
            variant="contained"
            onClick={() => {
              props.setSearchDialog(!props.searchDialog)
            }}
            fullWidth
          >
            Buscar
          </Button>
        </Grid>
        </Grid>
      </>
    )
}

export default FilterMassiveDialog
