import axios from 'axios'
import React from 'react'
import { API_ADMIN } from '../../api/GlobalApi'
import { GetAccountsIbanResponse, ListAccounts } from '../interfaces/CardsInterfaces'
import { trackPromise } from 'react-promise-tracker'

const HandleGetAccountsIban = async(setAccounts: (c:ListAccounts) => void, currency: string) => {
    try{
        const res = await trackPromise(axios.get<GetAccountsIbanResponse[]>(
            API_ADMIN + `/v5/cBankAccounts/getIbanAccounts?currency=${currency}`,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ));
        setAccounts(res.data)
          
    }catch (e) {

    }
}

export default HandleGetAccountsIban
