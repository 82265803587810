import { useForm } from "../../hooks/useForm";
import "./login.css";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { API_ADMIN } from "../../api/GlobalApi";
import axios from "axios";
import "./auth.css";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { trackPromise } from "react-promise-tracker";

const ChangePass = () => {
  const [confirm, setConfirm] = useState<boolean>(true);
  const [passwordError, setPasswordError] = useState('');
  const [passwordErrorConfirm, setPasswordErrorConfirm] = useState('');
  const history = useHistory();
  const regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>\-+_/=¡])(?!.*\s).{8,20}$/
  const { handleChange, formulario } = useForm({
    pass: "",
    confirmPass: "",
  });

 
  
  
  const passValid = () => {
    if (regx.test(formulario.pass) && formulario.pass === formulario.confirmPass) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }



  useEffect(() => {
    const isPasswordValid = (value:string) => {
    
      if (!/[a-z]/.test(value)) {
        return 'La contraseña debe contener al menos una letra minúscula';
      }

      if (!/[A-Z]/.test(value)) {
        return 'La contraseña debe contener al menos una letra mayúscula';
      }

      if (!/\d/.test(value)) {
        return 'La contraseña debe contener al menos un dígito';
      }

      if (!/[!@#$%^&*(),.?":{}|<>\-+_/=¡]/.test(value)) {
        return 'La contraseña debe contener al menos un carácter especial';
      }

      if (value.length < 8) {
        return 'La contraseña debe tener al menos 8 caracteres';
      }


      return '';
    };

    const error = isPasswordValid(formulario.pass);
    setPasswordError(error);
  }, [formulario.pass]);

  useEffect(() => {
     const isConfirmPasswordValid = (value: string) => {
    if (formulario.pass !== formulario.confirmPass) {
      return 'Las contraseñas no coinciden';
    }

    return '';
  };

  const confirmPasswordError = isConfirmPasswordValid(formulario.confirmPass);
    setPasswordErrorConfirm(confirmPasswordError);
  },[formulario.confirmPass])

  
  useEffect(() => {
    passValid();
  }, [formulario]);
  console.log(regx.test(formulario.confirmPass))
 const id = useParams();
 const data: any = id
  const submitForm = async () => {
    if(formulario.pass === formulario.confirmPass){
      try {
        trackPromise(axios.post(API_ADMIN + "/v5/auth/changeforgetpassword", {
          token: data.id.slice(4),
          newPassword: formulario.pass,
        }));
        swal("Exitoso", "La contraseña se cambio de forma correcta", "success");
        history.push("/");
      } catch (error) {
        swal("Error", "No se pudo modificar la contraseña", "error");
      }
    }else{
      swal("Error", "Las contraseñas tiene que ser iguales", "error");
    }
    
  };

  return (
    <>
      <div className="loginContainer">
      <div  style={{ width: '100%', height: '160px',display: 'flex', alignItems: 'center', justifyContent: "center", position: "absolute", top: '-10px', left: 0, right: 0}} >
          <img src='https://storage.googleapis.com/ridivi_util/ridivi/icon-ridivi.svg' alt="RidiviLogo" style={{width: '150px', height: '150px'}}/>
          </div>
        <div className="loginForm">
          
          <Toolbar>
            {/* <Tabs
              sx={{ width: "100%" }}
               value={tab}
               onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab value={0} label="" />
              <Tab value={1} label="" />
            </Tabs> */}
          </Toolbar>
          <Grid container className="registerContainer" flexDirection="row">
            <Grid item className="gridTypografyRegister" sm={12} xs={12}>
              <Typography variant="h4" className="typografyRegisterHeader">
                Cambiar Contraseña
              </Typography>
              <Divider className="dividerRegisterHeader" />
            </Grid>
            <ValidatorForm style={{width: "100%"}} onSubmit={() => {}}>
            <Grid item className="gridPasswordRegister" sm={12} xs={12}>
            <TextValidator
              className="passwordRegister"
              style={{ width: "355px"}}
              label="Contraseña"
              name="pass"
              type="password"
              value={formulario.pass}
              onChange={handleChange}
              error={Boolean(passwordError)}
        helperText={passwordError}
        validators={['required']}
        errorMessages={['Este campo es requerido']} // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
              
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item className="confirmPasswordRegister" sm={12} xs={12}>
            <TextValidator
              className="passwordRegister"
              style={{ width: "355px"}}
              label="Confirmar contraseña"
              type="password"
              name="confirmPass"
              value={formulario.confirmPass}
              onChange={handleChange}
              error={Boolean(passwordErrorConfirm)}
        helperText={passwordErrorConfirm}
        validators={['required', 'isPasswordMatch']}
        errorMessages={['Este campo es requerido']}
              fullWidth
            />
            
            </Grid>
           
            </ValidatorForm>
            <Grid item className="buttonContainerRegister" sm={12} xs={12}>
              <Button
                style={{
                  backgroundColor: `${!confirm ? "#4892DB" : "#808080"}`,
                  color: `${!confirm ? "#fff" : ""}`,textTransform: "capitalize"
                }}
                onClick={submitForm}
                disabled={confirm}
                variant="contained"
                className="buttonLogin"
              >
                CAMBIAR CONTRASEÑA
              </Button>
            </Grid>
            <Divider className="dividerFooterRegister" />
            <Typography
              className="typografyFooterRegister"
              sx={{ fontSize: "15px" }}
            >
              Todos los derechos reservados Ridivi
            </Typography>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ChangePass;
