import { AppBar, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { useContext } from 'react';
import { ContextLayOut } from '../layoutContext/LayOutContext';
import MenuIcon from '@mui/icons-material/Menu';
import './style/navBar.css'
const  useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    tittle:{
        flexGrow:1,
        position:'relative',left:'15px', color:'#FC923F', fontWeight:'bold'
    },
   
}))



const NavBar = (route: any) => {
    const {widths,setIsOpen,isOpen} = useContext(ContextLayOut);
    
     const styles5 = useStyles();

     const open = () => {
        setIsOpen(!isOpen)
     }
    return ( 
            <AppBar sx={{ backgroundColor: '#D3D3D3 !important', 
        height: '60px', width: `calc(100% - ${widths === 50? 0: widths+1}px)`,
        marginLeft: widths, zIndex:30}}>
                <Toolbar className='tooContainer'>
                <MenuIcon onClick={open} className='menuIcon'/>
                <Typography className='fistText'  >
                    Ridivi Admin Suite |                 
                </Typography>
                <Typography className={styles5.tittle}>
                    {route.route}                 
                </Typography>
                </Toolbar> 
            </AppBar>
    )
}

export default NavBar
