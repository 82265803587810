import { useState } from "react";
import HandleReports from "../handles/HandleReports";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, Card, Grid, TextField, TextFieldProps } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import enLocale from "date-fns/locale/en-US";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { usePromiseTracker } from "react-promise-tracker";
import DateTransform from "../../hooks/utils/DateTransform";
import Loading from "../../components/globalUtils/Loading";

const SinpeReportsArticle = () => {
  const [valueSince, setValueSince] = useState("");
  const [valueTo, setValueTo] = useState("");
  const promiseInProgress = usePromiseTracker();

  const submitForm = () => {
    HandleReports(DateTransform(valueSince), DateTransform(valueTo));
  };
  return (
    <div>
      <Card
        style={{
          height: "80vh",
          width: "100%",
          backgroundColor: "rgba(255,255,255,0)",
          marginTop: "60px",
        }}
      >
        <ValidatorForm onSubmit={submitForm}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="30"
            sx={{ position: "relative", top: "20px" }}
          >
            <Grid xs={12} sm={5.2} item>
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Desde"
                  value={valueSince}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue: any) => {
                    if (newValue !== null) {
                      setValueSince(newValue);
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField style={{ width: "100%" }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            &nbsp;
            <Grid xs={12} sm={5.2} item>
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Hasta"
                  value={valueTo}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue: any) => {
                    if (newValue !== null) {
                      setValueTo(newValue);
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField style={{ width: "100%" }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={12} sm={10.5} item>
              <Button
                style={{
                  background: "gray",
                  color: "#fff",
                }}
                variant="contained"
                onClick={submitForm}
                fullWidth
              >
                Generar Reporte
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Card>
      {promiseInProgress.promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default SinpeReportsArticle;
