import axios from 'axios'
import React from 'react'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { GetUserDataCardResponse } from '../interfaces/CardsInterfaces'
import { trackPromise } from 'react-promise-tracker'

const HandleGetDataUser = async(setDataUser: (c:GetUserDataCardResponse) => void, setNewCard: (c:boolean) => void) => {
    try{
        const res = await trackPromise(axios.get<GetUserDataCardResponse>(
            API_ADMIN + `/v5/card/getUserData`,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ))
        setDataUser(res.data);
    }catch (e){
        swal('Error','No se pudo obtener la data del usuario', 'error');
        setNewCard(false);
    }
}

export default HandleGetDataUser
