import axios from "axios"
import { trackPromise } from "react-promise-tracker"
import { API_ADMIN } from "../../api/GlobalApi"
import swal from "sweetalert"
import { InfoCardResponse } from "../interfaces/CardsInterfaces"

const HandleGetCardData = async(cardNumber:string,setData: (c: InfoCardResponse) => void) => {
    try{
        const res = await trackPromise(axios.post<InfoCardResponse>(
            API_ADMIN + '/v5/card/getDataCard',
            {card: cardNumber},
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ))
        setData(res.data);
    }catch(e){
        swal('Error','No se pudo optener la informacion de la trajeta', 'errors');
    }
}

export default HandleGetCardData
