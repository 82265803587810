import React, { useEffect, useState } from 'react'
import { Movement, MovementsPost } from '../interfaces/CardsInterfaces';
import HandleGetMovements from '../utils/HandleGetMovements';
import { useForm } from '../../hooks/useForm';

import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Toolbar } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ReusableDialogs from '../../reusableFunctionalComponents/ReusableDialogs';
import useValidateJwt from '../../hooks/useValidateJwt';

const MovementsArticle = () => {
    const [data,setData] = useState<Movement[]>([]);
    const {formulario, handleChange} = useForm<MovementsPost>({
        card:sessionStorage.getItem('cardNumber'),
        initialDate:"",
        finalDate:"",
        maxMovements:999
    })
    const mouseDownHandler = useValidateJwt();
    const [dif, setDif] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [searchDialog,setSearchDialog] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = (value: string) => {
        setOpen(false);
        setSelectedValue(value);
      };
    
    useEffect(() => {
        HandleGetMovements(formulario, setData)
    },[])
    return (
        <div onMouseDown={mouseDownHandler} className="articleContainer">
        <div className="paperContainer">
          <Paper className="muiPaper">
            <TableContainer className="tableContainer">
              <Toolbar>
                {/* <Button
                  onClick={(e) => {
                    history.push("/createNewCard");
                  }}
                  style={{ color: "#000" }}
                >
                  <AddIcon className="addIcon" aria-label="Solicitar tarjeta" />{" "}
                  Solicitar tarjeta
                </Button> */}
                <Typography style={{ flexGrow: 1 }}> </Typography>
  
                <Button
                  onClick={() => {
                    setDif("filter");
                    handleClickOpen();
                  }}
                  variant="contained"
                >
                  <SearchIcon />
                </Button>
              </Toolbar>
              <Table style={{ zIndex: 19 }}>
                <TableHead>
                  <TableRow>
                  <TableCell className="cell">Id</TableCell>
                  <TableCell className="cell">Fecha</TableCell>
                    <TableCell className="cell">Monto</TableCell>
                    <TableCell className="cell">Comercio</TableCell>
                    <TableCell className="cell">Descripcion</TableCell>
                    <TableCell className="cell">Tipo</TableCell>
                    <TableCell className="cell">Autorizacion</TableCell>
                    <TableCell className="cell">Saldo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((movement) => (
                    <TableRow
                      className="rowTable"
                    //   onClick={async () => {
                    //     setDif("edit");
                    //     setCard(movement);
                    //     handleClickOpen();
                    //   }}
                      key={movement.reference}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="row">{movement.movementId}</TableCell>
                      <TableCell className="row">{movement.dateMovement}</TableCell>
                      <TableCell className="row">{movement.currencyOriginMovement+" "+  movement.amountMovement}</TableCell>
                      <TableCell className="row">{movement.descriptionTrade}</TableCell>
                      <TableCell className="row">{movement.postOperationDescription}</TableCell>
                      <TableCell className="row">{movement.typeMovementChargeCredit}</TableCell>
                      <TableCell className="row">{movement.authorization}</TableCell>
                      <TableCell className="row">{movement.currencyOriginMovement+" "+ movement.balanceAfterMovement}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                page={1}
                count={1}
                color="primary"
                shape="rounded"
                hideNextButton={true}
                hidePrevButton={true}
                onChange={(event, value) => {
                //   setPage(value);
                }}
              />
            </TableContainer>
  
            <ReusableDialogs
              selectedValue={selectedValue}
              open={open}
              onClose={handleClose}
              form={formulario}
              dif={dif}
              dateSince={formulario.initialDate}
              to={formulario.finalDate}
              component={"movements"}
              handleClose={handleClose}
              setSearchDialog={setSearchDialog}
              searchDialog={searchDialog}
              handleChange={handleChange}
            />
          </Paper>
        </div>
      </div>
    )
}

export default MovementsArticle
