import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { LoginPost } from "../../rlinkPay/interfaces/LoginInterface";
import { AuthContext } from "../../routes/auth/store/context/AuthContext";
import { AuthServices } from "../../services/auth/AuthServices";
import swal from "sweetalert";
import "./auth.css";
import { ContextLogin } from "./context/LoginContext";
import { Button } from "@material-ui/core";
import HandleMenus from "../globalUtils/HandleMenus";
import { MenusLayOutResponse } from "../../globalInterfaces/MenusLayOutInterface";
//import logo from 'https://storage.googleapis.com/ridivi_util/ridivi/LOGO%20ICONO%20RIDIVI.jpg';

const LoginArticle = () => {
  const { setToken, setRefresh } = useContext(ContextLogin);
  const { dispatchUser }: any = useContext(AuthContext);
  const [button, setButton] = useState(true);
  const history = useHistory();
  const [data, setData] = useState<MenusLayOutResponse[]>([]);
  const [privileges, setPrivileges] = useState<string[]>([]);

  function guardarPrivilegiosEnSessionStorage(
    privilegios: any,
    nombresPrivilegios: any
  ) {
    nombresPrivilegios.forEach((nombrePrivilegio: MenusLayOutResponse) => {
      const coincidencia = privilegios.find(
        (privilegio: any) => privilegio === nombrePrivilegio.code
      );
      if (coincidencia) {
        sessionStorage.setItem(nombrePrivilegio.menu, coincidencia);
      }
    });
  }

  useEffect(() => {
    HandleMenus(setData);
    sessionStorage.removeItem('backOffice')
    sessionStorage.clear();
  }, []);
  useEffect(() => {
    guardarPrivilegiosEnSessionStorage(privileges, data);
  }, [privileges]);

  const { handleChange, formulario } = useForm<LoginPost>({
    user: "",
    pass: "",
  });

  useEffect(() => {
    if (formulario.pass !== "" && formulario.user !== "") {
      setButton(false);
    } else setButton(true);
  }, [formulario.pass, formulario.user]);

  const submitForm = async () => {
    try {
      const resp: any = await AuthServices.login(formulario);
      sessionStorage.setItem('email', resp.email);
      sessionStorage.setItem("userIdNumber", resp.user_id_number);
      sessionStorage.setItem('userId', resp.id)
      setPrivileges(resp.privilege);
      setToken(resp.token);
      setRefresh(resp.refreshToken);
      sessionStorage.setItem("refreshToken", resp.refreshToken);
      sessionStorage.setItem(
        "customerId",
        JSON.stringify({ ...resp, loggedIn: true })
      );
      sessionStorage.setItem("token", resp.token);
      sessionStorage.setItem("customerId", resp.customerIdNumber);
      sessionStorage.setItem("customerTypeId", resp.customerTypeIdNumber);
      dispatchUser({ type: "login", payload: resp });
      history.push("/home");
    } catch (error: any) {
      if (error.response.data.code === "132") {
        swal(
          "Error",
          'Contraseña expirada. Favor ingrese a "Olvidé mi contraseña" y recupere la misma',
          "error"
        );
      } else {
        swal("Error", "Usuario o contraseña inválido", "error");
      }
    }
  };

  return (
    <Grid container className="gridContainerLogin" flexDirection="row">
      <div
        style={{
          width: "100%",
          height: "160px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "-10px",
          left: 0,
          right: 0,
        }}
      >
        <img
          src="https://storage.googleapis.com/ridivi_util/ridivi/icon-ridivi.svg"
          alt="RidiviLogo"
          className="logoLogin"
        />
      </div>

      <Grid item className="gridTypografy" sm={12} xs={12}>
        <Typography variant="h4" className="typografyStyle">
          Iniciar sesión
        </Typography>
        <Divider className="dividerHeaderLogin" />
      </Grid>
      <Grid
        style={{ marginBottom: "20px" }}
        item
        className="gridEmail"
        sm={12}
        xs={12}
      >
        <TextField
          className="emailInput"
          label="Nombre de usuario"
          name="user"
          type="email"
          onChange={handleChange}
        />
      </Grid>
      <Grid item className="gridPassword" sm={12} xs={12}>
        <TextField
          className="passwordInput"
          name="pass"
          label="Contraseña"
          type="password"
          onChange={handleChange}
        />
      </Grid>
      <Grid item className="gridButton" sm={12} xs={12}>
        <Button
          style={{
            backgroundColor: `${!button ? "#FB813F" : "#808080"}`,
            color: `${!button ? "#fff" : ""}`,
            textTransform: "capitalize",
          }}
          disabled={button}
          onClick={submitForm}
          variant="contained"
          className="buttonLogin"
        >
          Ingresar
        </Button>
      </Grid>
      <Divider className="dividerFooterLogin" />
      <Typography className="typografyFooterLogin" sx={{ fontSize: "15px" }}>
        Todos los derechos reservados Ridivi
      </Typography>
    </Grid>
  );
};

export default LoginArticle;
