import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import HandleGenerateDashboard from "../../chatbot/components/generateDashboard/HandleGenerateDashboard";
import HandleDashboarLinkPay from "../../rlinkPay/components/linkPayDashboard/handles/HandleDashboarLinkPay";

const VisitResumeMessagesGraphicWidget = ({form, change,reportType,page, title}:any) => {
    const [data, setData] = useState<any>();
    const [arrayData, setArrayData] = useState<any>(["1"]);
    const [arrayHeaders, setArrayHeaders] = useState<any>();
  
    useEffect(() => {
      const getData = () => {
        let arrayMessagesString: String[] = [];
        let arrayHeaders: String[] = [];
        if(page !== 'linkpay'){
        if (data !== undefined) {
          data.map((resp: any) => {
            arrayMessagesString.push(JSON.stringify(resp.Mensajes_Integer));
            arrayHeaders.push(JSON.stringify(resp.Fecha_String));
          });
        }
        if (arrayMessagesString !== [] && arrayHeaders !== []) {
          setArrayData(arrayMessagesString);
          setArrayHeaders(arrayHeaders);
        }
      }else{
        if (data !== undefined) {
          data.map((resp: any) => {
            arrayMessagesString.push(JSON.stringify(resp.CantidadTotal_Integer));
            arrayHeaders.push(JSON.stringify(resp.Fecha_String));
          });
        }
        if (arrayMessagesString !== [] && arrayHeaders !== []) {
          setArrayData(arrayMessagesString);
          setArrayHeaders(arrayHeaders);
        }

      }
    };
      getData();
    }, [data]);
    useEffect(() => {
      const prueba = async () => {
        if(page=== 'linkpay'){
          const result = await HandleDashboarLinkPay(reportType, form);
        setData(result);
        }else{
          const result = await HandleGenerateDashboard(reportType, form);
        setData(result);
        }
      };
        
     setTimeout(() => { prueba();}, 800)
    }, [change]);
  
  
    
  
    const options = {
      series: [
        {
          name: title,
          data: arrayData,
        },
      ],
  
      charts: [
        {
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            },
            autoSelected: "zoom"
          }
        },
      ],
      
      dataLabels: {
        enabled: true
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              width: 1000,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },{
          breakpoint: 1500,
          options: {
            chart: {
              width: 850,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 800,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 600
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 800,
          options: {
            chart: {
              width: 400
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              width: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 200
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        }
      ],
  
  
      xaxis: {
        categories: arrayHeaders,
      },
    };
    return (
  
     
        <Chart 
              options={options}
              series={options.series}
              width={'100%'}
              charts={options.charts}
              
              
            />    
    );
  };
  
  export default VisitResumeMessagesGraphicWidget ;
