import React, { useContext, useEffect, useState } from 'react'
import { ContextLayOut } from '../layoutContext/LayOutContext';
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PaymentIcon from '@mui/icons-material/Payment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from "react-router-dom";
import AddCardIcon from '@mui/icons-material/AddCard';
import HistoryIcon from '@mui/icons-material/History';
const CardList = (clasess:any) => {
    const { widths,isOpen, setIsOpen } = useContext(ContextLayOut);
    const [displayLink,setDisplayLink] = useState<string>('none');

    useEffect(() => {
        if(isOpen) {
            setDisplayLink('none');
        }
    },[isOpen])
    
    return (
        <>
             <ListItem
        className={clasess.clasess.items}
        onClick={(e) => {
          setDisplayLink(
            displayLink === "" ? "none" : displayLink === "none" ? "" : "none"
          );
          if (isOpen !== false) {
            setIsOpen(false);
          }
        }}
        style={{ width: `${widths - 5}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon 
        className={clasess.clasess.icon}
        >
          <PaymentIcon aria-label="RLink" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>
            Tarjetas{" "}
            {displayLink === "none" ? (
              <KeyboardArrowUpIcon
                style={{ position: "relative", top: "6px", left: "41px" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ position: "relative", top: "6px", left: "41px" }}
              />
            )}
          </ListItemText>
        )}
      </ListItem>
      <List style={{ display: `${displayLink}` }}>

         <Link style={{ textDecoration: "none" }} to="/listCardInfo">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <AddCardIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Listado</ListItemText>
          </ListItem>
        </Link>
      </List>
    </>
       
    )
}

export default CardList
