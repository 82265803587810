const useDate = (dates: any) => {
  let dateSince: any = dates.toLocaleString().replace('/', '-').replace('/','-');
  const a = dateSince.split(",").shift();
  const b = a.split();
  const c = b.reverse();
  const d: any = c.join();
  const e = d.slice(0, 10);
  const f = e.split("-");
  let g: string = "";
  let h: string = "";
  let i: string = "";
  if (f && f[1]?.length < 2) {
      
    g = "0" + f[1];
  } else {
      
    g = f[1];
  }
  if (f[0].length < 2) {
    h = "0" + f[0];
  } else {
    h = f[0];
  }

  return(`${f[2]}-${g}-${h}`);
};

export default useDate;
