import {  Divider, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "../../hooks/useForm"
import HandlechangePassword from "./handles/HandlechangePassword"
import './auth.css'
import { Button } from "@material-ui/core"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"

const Forgot = () => {
    const [button, setButton] = useState(true)
    const {formulario, handleChange} = useForm({
        email:''
    })

    const submitForm = () => {
        HandlechangePassword(formulario.email)
    }

    useEffect(() => {
        if(formulario.email !== ''){
            setButton(false)
        }else{
          setButton(true)
        }
    },[formulario.email])
    return (
        <Grid container className="gridContainerLogin" flexDirection="row">
           <div  style={{ width: '100%', height: '160px',display: 'flex', alignItems: 'center', justifyContent: "center", position: "absolute", top: '-10px', left: 0, right: 0}} >
          <img src='https://storage.googleapis.com/ridivi_util/ridivi/icon-ridivi.svg' alt="RidiviLogo" className="logoLogin"/>
          </div>
      <Grid item className="gridTypografy" sm={12} xs={12}>
        <Typography variant="h4" className="typografyStyle">
          Recuperar contraseña
        </Typography>
        <Divider className="dividerHeaderLogin" />
      </Grid>

      <ValidatorForm style={{width: "100%"}} onSubmit={() => {}}>
      <Grid item className="gridEmail" sm={12} xs={12}>
        
        <TextValidator
        className="emailInput"
              style={{ width: "355px"}}
              label="Correo electrónico"
              name="email"
              value={formulario.email}
              onChange={handleChange}
              validators={["isEmail"]}
              errorMessages={["Ingrese su correo electrónico example@exaple.com"]}
              // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
      </Grid>
      </ValidatorForm>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Grid item className="gridButton" sm={12} xs={12}>
        <Button
        style={{backgroundColor: `${!button? '#4892DB': '#808080'}`, color: `${!button? '#fff': ''}`, textTransform: "capitalize"}}
          disabled={button}
          onClick={submitForm}
          variant="contained"
          className="buttonLogin"
        >
          Recuperar Contraseña
        </Button>
      </Grid>
      <Divider className="dividerFooterLogin" />
      <Typography className="typografyFooterLogin" sx={{fontSize: "15px"}}>
        Todos los derechos reservados Ridivi
      </Typography>
    </Grid>
    )
}

export default Forgot
