import { createContext, FC, useState } from "react";
import {linkpayInterface} from './linkPayContextInterface'
import { linkPayData } from "./LinkPayStore";

export const LinkPaycontext = createContext<linkpayInterface>(linkPayData);

export const LinkPayContextProvider: FC = ({ children }) => {
  const [id, setId] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [iban, setIban] = useState<string>("CR");
  const [amount, setAmount] = useState<number>(0);
  const [button, setButton] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("");
  const [processPay, setProcessPay] = useState<string>("Pendiente");
  const [messageErrorPay, setMessageErrorPay] = useState<string>("");
  const [iconClientPay, setIconClientPay] = useState<string>("");  
  const [payKey, setPayKey] = useState<string>("");
  const [propsImg, setPropsImg] = useState<string>("");
  const [textLegend, setTextLegend] = useState<string>("");
  const [successImg, setSuccessImg] = useState<string>('');
  const [textLegendSuccess, setTextLegendSuccess] = useState<string>('')
  const [urlImgCard, setUrlImgCard] = useState<string>('');
  return (
    <LinkPaycontext.Provider
      value={{
        id,
        setId,
        detail,
        setDetail,
        iban,
        setIban,
        amount,
        setAmount,
        button,
        setButton,
        terms,
        setTerms,
        currency,
        setCurrency,
        processPay,
        setProcessPay,
        messageErrorPay,
        setMessageErrorPay,
        iconClientPay,
        setIconClientPay,
        payKey,
        setPayKey,
        setPropsImg,
        propsImg,
        textLegend,
        setTextLegend,
        successImg,
        setSuccessImg,
        textLegendSuccess,
        setTextLegendSuccess,
        urlImgCard,
        setUrlImgCard,
      }}
    >
      {children}
    </LinkPaycontext.Provider>
  );
};
