import axios from "axios";
import swal from "sweetalert";
import { API_ADMIN } from "../../api/GlobalApi";
import { ReportSinpeOperationMassiveResponse } from "../interfaces/ReportSinpeOperationMassive";
import { trackPromise } from "react-promise-tracker";

const HandleSinpeOperationMassiveStateReport = async (
  setData: (c: ReportSinpeOperationMassiveResponse) => void
) => {
  try {
    const res = await trackPromise(
      axios.get(API_ADMIN + "/v5/sinpe/reportSinpeOperationMassiveState", {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
    );
    setData(res.data);
  } catch (error) {
    swal("Error", "Error al obtener reporte", "error");
  }
};

export default HandleSinpeOperationMassiveStateReport;