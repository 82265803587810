import DetailCardInfo from "./DetailCardInfo";
import FilterCardInfo from "./FilterCardInfo"


const SampleCardInfoDialogs = (props:any) => {
    const {dif} = props;

    return (
        <div>
            {
                dif !== 'filter'?
                <DetailCardInfo props={props}/>:
                <FilterCardInfo props={props}/>
            }
        </div>
    )
}

export default SampleCardInfoDialogs
