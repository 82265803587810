import { Button, DialogTitle, Grid, InputLabel, Typography } from '@mui/material'
import axios from 'axios';
import { useState } from 'react'
import { API_ADMIN } from '../../../../api/GlobalApi';
import swal from "sweetalert";
import { trackPromise } from 'react-promise-tracker';
const DetailMassiveDialog = (data: any, handleExecute:any, id: number,disabled: boolean, setDisabled: (c:boolean) => void, setExecute:any, execute:boolean) => {
    
  const HandleGenerateReport = (formulario:any) => {
   
    let data = {
      id: id
    };
    
    trackPromise(fetch(API_ADMIN + "/v5/linkpay/downloadMasiveDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if(response.status === 200) {
          response.blob().then((blob) => {
            
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `LinkMasivos.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
        }else if(response.status === 505){
          swal("Error", "Los datos ingresados no son correctos", "error");
        }else{
          swal("Error", "La consulta no ha generado resultados", "error");
        }
        
      }))
      ;
  };
    return (
        <>
        {
          !execute ? 
          <DialogTitle className="titleDialog">
           Información del Link Nº {data.id}
        </DialogTitle>:
        <DialogTitle className="titleDialog">
        Confirmación
      </DialogTitle>
        
        }
        
        
          <Grid
          style={{ width: "100%", height: "250px" }}
          container
          alignItems="center"
          justifyContent="center"
          spacing="15"
        >
          {!execute?  <>
          <Grid xs={5} sm={5} item>
            <InputLabel className="dialogInputsLabels">Cantidad</InputLabel>
            <Typography className="dialogsData">{data.amount}</Typography>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
            <InputLabel className="dialogInputsLabels">Fecha</InputLabel>
            <Typography className="dialogsData">{data.create_at !== undefined ? data.create_at.slice(0,19): ''}</Typography>
          </Grid>
          <Grid xs={5} sm={5} item>
            <InputLabel className="dialogInputsLabels">Estado</InputLabel>
            <Typography className="dialogsData">{data.state_massive}</Typography>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
            <InputLabel className="dialogInputsLabels">Porcentaje</InputLabel>
            <Typography className="dialogsData">{data.percentage + "%"}</Typography>
          </Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{ background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              aria-label="Ejecutar"
              variant="contained"
              onClick={() => {
                const a = () => {
                  setDisabled(true);
                  //handleExecute();
                  setExecute(true);
                }
                a();
              }}
              disabled={data.state_massive !== "Completado" && data.state_massive !== "Ejecutando"? false : true}
              fullWidth
            >
              Ejecutar
            </Button>
          </Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{ background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              aria-label="Descargar"
              variant="contained"
              onClick={HandleGenerateReport}
              fullWidth
            >
              Descargar
            </Button>
          </Grid>
          </> :
          <>
          <Grid xs={11} sm={10.3} item>
            <Typography className="dialogsData">¿Está seguro que desea ejecutar la masiva?</Typography>
          </Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{ background: "gray",textTransform: "capitalize" }}
              aria-label="Ejecutar"
              variant="contained"
              onClick={() => {
                const a = () => {
                  setDisabled(true);
                  handleExecute(); 
                }
                a();
              }}
              disabled={data.state_massive !== "Completado" && data.state_massive !== "Ejecutando"? false : true}
              fullWidth
            >
              Ejecutar
            </Button>
          </Grid>
          <Grid style={{display: "flex", position: "relative", top: '-20px'}} xs={11} sm={10.3} item>
            <Button
              style={{ background: "gray",textTransform: "capitalize" }}
              aria-label="Ejecutar"
              variant="contained"
              onClick={() => {
                const a = () => {
                  setDisabled(true);
                  //handleExecute(); 
                  setExecute(false);
                }
                a();
              }}
              disabled={data.state_massive !== "Completado" && data.state_massive !== "Ejecutando"? false : true}
              fullWidth
            >
              Cancelar
            </Button>
          </Grid>
          </>}
        
        </Grid>
      </>
    )
}

export default DetailMassiveDialog