import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { trackPromise } from 'react-promise-tracker'

const UserReportTypes = async(setData: (c: any) => void, route: string) => {
    try{
        const res = await trackPromise(axios.get(
            API_ADMIN + `/v5/reports/userReportTypes?userId=${sessionStorage.getItem('userId')}&route=${route}`,
            {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}}
        ))
        setData(res.data)
    }catch (e) {
        swal('Error', 'No se pudo obtener el listado de reportes')
    }
}

export default UserReportTypes
