import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'

const HandleReports = (valueSince:any, valueTo: any) => {
   
    let data = {
      dateStart: valueSince,
      dateEnd: valueTo,
    };
    
    trackPromise(fetch(API_ADMIN + "/v5/SinpeMovilEntrante/reportlistaSinpe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if(response.status === 200) {
          response.blob().then((blob) => {
            
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `sinpeReport.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
        }else if(response.status === 505){
          swal("Error", "Los datos ingresados no son correctos", "error");
        }else{
          swal("Error", "La consulta no ha generado resultados", "error");
        }
        
      }))
      ;
  };

export default HandleReports
