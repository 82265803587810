import React from 'react'
import { API_ADMIN } from '../../api/GlobalApi'
import { Movement, MovementsPost, MovementsResponse } from '../interfaces/CardsInterfaces'
import axios from 'axios'
import swal from 'sweetalert'
import { trackPromise } from 'react-promise-tracker'

const HandleGetMovements = async(form: MovementsPost, setData: (c:Movement[]) => void) => {
    try {
        const res = await trackPromise(axios.post<MovementsResponse>(
            API_ADMIN + '/v5/card/consultaMovimientosVirtual',
            form,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ))
        setData(res.data.movements)
    } catch (error) {
        swal("Error","No se pudo obtener el lstado de movimientos","error")
    }
}

export default HandleGetMovements
