import { FadeLoader } from "react-spinners"

const Loading = () => {
    return (
        <div
          style={{
            position: "absolute" ,
            top: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000000,
          }}
        >
          <FadeLoader color="#fc923f" loading={true} />
        </div>
    )
}

export default Loading;