import useValidateJwt from "../hooks/useValidateJwt";
import NavBar from "../layout/navBar/NavBar";
import SideBar from "../layout/sideBar/SideBar";
import SinpeArticle from "./SinpeArticle";
import { makeStyles } from "@material-ui/core/styles";

const Sinpe = () => {
  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }));

  const sinpeStyle = styles();
  const mouseDownHandler = useValidateJwt();

  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Verificador de SINPE Móvil"} />
      <SideBar />
      <div className={sinpeStyle.content}>
        <div className={sinpeStyle.offset}></div>

        <div
          style={{
            width: "100%",
            height: "80vh",
            position: "relative",
            top: "40px",
          }}
        >
          <SinpeArticle />
        </div>
      </div>
    </div>
  );
};

export default Sinpe;
