import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import {AdaDatum, DashboardAdaPost, DashboardAdaResponse} from '../interfaces/DashboardAdaInterface'
import { trackPromise } from 'react-promise-tracker'
const DashboardAda = async(formulario: DashboardAdaPost, setData: (c: AdaDatum) => void) => {
    try{
        const res = await trackPromise(axios.post<DashboardAdaResponse>(
            API_ADMIN + '',
            formulario,
            {
                headers: { Authorization: 'Bearer' + sessionStorage.getItem('token')}
            }
        ))
        setData(res.data.data[0])
    }catch (e) {
        swal('Error', 'Error', 'error')
    }
}

export default DashboardAda
