import { Button, DialogTitle, Grid } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CardInfo, GenerateOptResponse } from '../../interfaces/CardsInterfaces';
import HandleGenerateOtp from "../../utils/HandleGenerateOtp";
import { useState } from "react";
import ValidateOtp from "../pinAndOtp/ValidateOtp";

const DetailCardInfo = (props: any,) => {
  const [opt, setOpt] = useState<number | string>("");
  const [isValidOpt, setIsValidOpt] = useState<boolean>(false);
  const [dataOpt, setDataOpt] = useState<GenerateOptResponse>();
  
    const data: CardInfo = props.props.card;
    return (
        <div style={{ display: "flex", width: "100%", height:'60vh' }}>
          
      <ValidatorForm style={{ width: "100%", position: 'relative', top: "20px", marginBottom: "20px" }} onSubmit={() => {}}>
      <DialogTitle className="titleDialog">{props?.props?.card?.cardType !== "PS"?"Detalle de solicitud de tarjeta":'Detalle de tarjeta'}</DialogTitle>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing="30"
        >
          
          {/* {!isValidOpt && props?.props?.card?.cardType !== "PS"?
          <> */}
          
          <Grid xs={12} sm={5} item>
            <TextValidator
              style={{ zIndex: 1 }}
              label="Tipo de tarjeta"
              size="small"
              name="cardType"
              disabled={true}
              value={data?.cardType? data?.cardType: ''}
              fullWidth
            />
          </Grid>
        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
              style={{ zIndex: 1 }}
              label="Fecha de nacimiento"
            size="small"
            multiline
            name="birthDate"
            value={data?.birthDate?data?.birthDate:''}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ zIndex: 1 }}
            label="Nombre de la tarjeta"
            size="small"
            name="cadName"
            value={data?.cardName?data?.cardName:''}
            disabled={true}
            fullWidth
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Grid xs={12} sm={5} item>
          <TextValidator
              style={{ zIndex: 1 }}
              label="Nombre"
            name="name"
            value={data?.name?data?.name:''}
            size="small"
            disabled={true}
            // InputLabelProps={{ className: "labels" }}

            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
              style={{ zIndex: 1 }}
              label="Primer apellido"
            name="lastName"
            value={data?.lastName?data?.lastName:''}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
              style={{ zIndex: 1 }}
              label="Segundo Apellido"
            name="secondLastName"
            value={data?.secondLastName?data?.secondLastName:''}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={10.5} item>
          <TextValidator
              style={{ zIndex: 1 }}
              label="Numero de identificación"
            name="identificationNumber"
            value={data?.identificationNumber?data?.identificationNumber:''}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        
       
        {/* {
        data.cards && props?.props?.card?.cardType !== "5"?
        <Grid xs={12} sm={5} item><Grid xs={12} sm={5} item>
        <Button
              style={{background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              
              aria-label="Crear OTP"
              variant="contained"
              onClick={() => {
                HandleGenerateOtp(setIsValidOpt,setDataOpt);
              }}
              fullWidth
            >
              Cambiar pin
            </Button>
        </Grid>

        </Grid>:""} */}
        {/* </>:
      ValidateOtp(opt,dataOpt?.id,setIsValidOpt,setOpt)
      } */}
        </Grid>
      </ValidatorForm>

    </div>
    )
}

export default DetailCardInfo
