import { Grid } from "@mui/material";
import { useContext } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NewCardContext } from "../context/NewCardContext";
import useValidateJwt from "../../hooks/useValidateJwt";

const Resume = () => {
  const {newCards} = useContext(NewCardContext);
  const mouseDownHandler = useValidateJwt();

  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex", width: "100%", position: "relative", top:'100px' }}>
      <ValidatorForm style={{ width: "100%" }} onSubmit={() => {}}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing="30"
        >
          <Grid xs={12} sm={5} item>
            <TextValidator
              style={{ zIndex: 1 }}
              label="Tipo de tarjeta"
              size="small"
              name="cardType"
              disabled={true}
              value={newCards[0]?.cardType}
              fullWidth
            />
          </Grid>
        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ zIndex: 1 }}
            label="Genero"
            size="small"
            name="gender"
            value={newCards[0]?.gender}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ zIndex: 1 }}
            label="Nombre de la tarjeta"
            size="small"
            name="cadName"
            value={newCards[0]?.cardName}
            disabled={true}
            fullWidth
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
            label="Teléfono"
            size="small"
            name="cellPhone"
            value={newCards[0]?.cellPhone}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-10px" }}
            label="Código Postal"
            size="small"
            name="postalCode"
            value={newCards[0]?.postalCode}
            disabled={true}
            fullWidth
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Nombre"
            name="name"
            value={newCards[0]?.name}
            size="small"
            disabled={true}
            // InputLabelProps={{ className: "labels" }}

            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Primer apellido"
            name="lastName"
            value={newCards[0]?.lastName}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Segundo Apellido"
            name="secondLastName"
            value={newCards[0]?.secondLastName}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Numero de identificación"
            name="identificationNumber"
            value={newCards[0]?.identificationNumber}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Cuenta en Dólares"
            name="AccountUSD"
            value={newCards[0]?.iban_usd}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Cuenta en Colones"
            name="AccountUSD"
            value={newCards[0]?.iban_crc}
            size="small"
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <TextValidator
            style={{ position: "relative", top: "-21px" }}
            label="Fecha de nacimiento"
            size="small"
            multiline
            name="birthDate"
            value={newCards[0]?.birthDate}
            disabled={true}
            fullWidth
          />
        </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

export default Resume;
