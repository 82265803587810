import { Button, DialogTitle, Grid, InputLabel, Typography } from '@mui/material';
import HandleAssign from '../handles/HandleAssign';

const DialogAssign = (data: any, setDif:(c:string) => void) => {
  
    const submitAssign = () => {
        HandleAssign(data.id);
    }
    return (
        <>
        <DialogTitle className="titleDialog">Asignar SINPE Móvil</DialogTitle>
      <Grid container alignItems="center" justifyContent="center" spacing="15">
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Identificación
          </InputLabel>
          <Typography className="dialogsData">
            {data.idClient}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Nombre
          </InputLabel>
          <Typography className="dialogsData">
            {data.name}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Teléfono</InputLabel>
          <Typography className="dialogsData">
            {data.phone}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Monto</InputLabel>
          <Typography className="dialogsData">
            {"₡ " + data.amount}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Estado
          </InputLabel>
          <Typography style={{textTransform: 'capitalize'}} className="dialogsData">
            {data.state}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Descripción
          </InputLabel>
          <Typography className="dialogsData">
            {data.description}
          </Typography>
        </Grid>

        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Movimiento
          </InputLabel>
          <Typography className="dialogsData">
            {data.movNum}
          </Typography>
        </Grid>
       
        <Grid item xs={12}></Grid>
        
        </Grid>
        <Grid style={{
            width: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }} item>
          <Button
            style={{background: "gray", marginBottom: "10px", width: "90%", display: "flex", left: '5%'}}
            aria-label="Copiar Link"
            variant="contained"
            onClick={() => {
                data.state !== 'Confirmado'?
              submitAssign():setDif('liberar')

            }}
            
          >
            {
                data.state !== 'Confirmado'?
                'Confirmar' : 'Liberar'
            }
          </Button>
        </Grid>
      </>
    )
}

export default DialogAssign
