import { useState } from "react";

export const useForm = <T extends Object>(initState: T) => {
  const [formulario, setFromulario] = useState(initState);

  const handleChange = ( e: any) => {
    const { name, value } = e.target;
    

    setFromulario({
      ...formulario,
      [name]: value,
    });
  };


  return {
    formulario,
    handleChange,
    
    ...formulario,
  };
};
