import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import "./styles/icon.css";
import "./styles/dialogLinkHistory.css";
import DialogValidations from "./validations/DialogValidations";
import { useForm } from "../../../hooks/useForm";
import DetailDialogLinkHistory from "./dialog/DetailDialogLinkHistory";
import DialogLinkHistoryFilter from "./dialog/DialogLinkHistoryFilter";
import { SimpleDialogPropsLinkHistory } from "../../interfaces/SimpleDialogPropsLinkHistory";
import DateTransform from "../../../hooks/utils/DateTransform";

function SimpleDialogLinkHistory(props: SimpleDialogPropsLinkHistory) {
  const history = useHistory();
  const [dataLink, setDataLink] = useState<any>();
  const [simbol, setSimbol] = useState<string>("");
  const [typeId, setTypeId] = useState<string>("");
  const [exp, setExp] = useState<string>("");
  const {handleClose} = props;
  useEffect(() => {
    const data = async () => {
      if (props.payKey) {
        setDataLink(props?.payKey[1]);
        sessionStorage.setItem("payKey", props.payKey);
        DialogValidations(dataLink, setSimbol,setTypeId,setExp);
      }
    };
    data();
  }, [props.payKey, dataLink]);
  
  const copy = () => {
    navigator.clipboard.writeText(dataLink?.linkPay ? dataLink.linkPay : "");
    if (dataLink?.linkPay !== "" && dataLink.linkPay !== null) {
      swal("Exito", "Su link se copió al portapapeles", "success");
    }
  };

  const {formulario, handleChange} = useForm(
    {
      idNumber: '',
      name: '',
      currency: '',
      invoiceNumber: '',
      state:'',
      detail:''
    }
  );

  useEffect(() => {
    props.setName(formulario.name);
    props.setIdNumber(formulario.idNumber);
    props.setCurrency(formulario.currency);
    props.setInvoiceNumber(formulario.invoiceNumber);
    props.setState(formulario.state);
    props.setDetail(formulario.detail)
  },[formulario])
  return (
    <>
      {
        props.dif !== 'filter'? 
        DetailDialogLinkHistory(copy, history, dataLink, typeId, simbol, exp, handleClose)
        :
        DialogLinkHistoryFilter(formulario, handleChange, props)
      }
    </>
  );
}

export default SimpleDialogLinkHistory;