import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";
import enLocale from "date-fns/locale/en-US";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTransform from "../hooks/utils/DateTransform";
import { MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import UserReportTypes from "./utils/UserReportTypes";
const DownloadReport = (
  submitForm: any,
  formulario: any,
  handleChange: any,
  valueSince: any,
  setValueSince: any,
  valueTo: any,
  setValueTo: any,
  route: any
) => {
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    UserReportTypes(setData, route);
  }, []);
  return (
    <Card
      style={{
        height: "80vh",
        width: "100%",
        backgroundColor: "rgba(255,255,255,0)",
        marginTop: "60px",
      }}
    >
      <ValidatorForm onSubmit={submitForm}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="30"
        >
          <Grid xs={12} sm={10.5} item>
            <FormControl
              style={{ position: "relative", top: "15px", left: "1.5%" }}
              fullWidth
            >
              <InputLabel
                style={{ position: "relative", top: "23px" }}
                id="demo-simple-select-label"
              >
                Tipo de Reporte
              </InputLabel>

              <Select
                label="Tipo de Reporte"
                name="reportType"
                value={formulario.reportType}
                onChange={handleChange}
              >
                {data.map((element: any) => (
                  <MenuItem key={element.id} value={element.value}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormHelperText
              style={{ position: "relative", top: "15px", left: "2%" }}
            >
              Seleccione el tipo de reporte
            </FormHelperText>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={12} sm={5.2} item>
            <LocalizationProvider
              locale={enLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Desde"
                value={valueSince}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  if (newValue !== null) {
                    // sessionStorage.setItem("token", prueba )
                    setValueSince(newValue);
                    formulario.dateInitial = DateTransform(newValue);
                  }
                }}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps
                ) => <TextField style={{ width: "100%" }} {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          &nbsp;
          <Grid xs={12} sm={5.2} item>
            <LocalizationProvider
              locale={enLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Hasta"
                value={valueTo}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  if (newValue !== null) {
                    // sessionStorage.setItem("token", prueba )
                    setValueTo(newValue);
                    formulario.dateFinish = DateTransform(newValue);
                  }
                }}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps
                ) => <TextField style={{ width: "100%" }} {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} sm={10.5} item>
            {formulario.reportType === "ReportConversationDetail" ? (
              <TextValidator
                style={{ position: "relative", top: "5px" }}
                label="Número de celular"
                name="phoneNumber"
                value={formulario.phoneNumber}
                onChange={handleChange}
                validators={["required", "matchRegexp:^[+,(,), ,0-9]{1,9999}$"]}
                errorMessages={[
                  "Se requiere un número celular",
                  "Ingrese el número de celular",
                ]}
                fullWidth
              />
            ) : formulario.reportType === "CustomerExperience" ? (
              <TextValidator
                style={{ position: "relative", top: "5px" }}
                label="Número de celular"
                name="phoneNumber"
                value={formulario.phoneNumber}
                onChange={handleChange}
                validators={["required", "matchRegexp:^[+,(,), ,0-9]{1,9999}$"]}
                errorMessages={[
                  "Se requiere un número celular",
                  "Ingrese el número de celular",
                ]}
                fullWidth
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid xs={12} sm={10.5} item>
            <Button
              style={{
                background: "gray",
                color: "#fff",
                textTransform: "capitalize",
              }}
              variant="contained"
              onClick={submitForm}
              fullWidth
            >
              Generar Reporte
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Card>
  );
};

export default DownloadReport;
