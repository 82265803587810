import "./../virtualCard/virtualCard.css";
import mastercard from "./mastercard.png";
import chip from "./chip.png";
import logo from "./logo.png";
import nfc from "./nfc.png";
import { InfoCardResponse } from "../interfaces/CardsInterfaces";

const VirtualCard = (card: InfoCardResponse | undefined) => {
 

  const exp = card?.cards[0]?.fechaVencimiento;
  const ccv = card?.cards[0].cvc;
  const formattedStr = card?.cards[0]?.card?.replace(/(.{4})/g, "$1 ");

  const year = parseInt(exp !== undefined ?exp.substr(2, 2): '');
  const month = parseInt(exp !== undefined ?exp.substr(4, 2): ''); // Restamos 1 para ajustar el mes a partir de 0

  const formattedDate = `${year}/${month}`;

  return (
    <div
      style={{
        width: "100%",
        
        display: "flex",
        position: "relative",
        top: "20px",
        justifyContent: "center",
      }}
    >
      <div
        className="card"
      >
        <img src={logo} alt="imagen" className="imagen" />
        <img src={mastercard} alt="imagen" className="mastercard" />
        <img src={chip} alt="chip" className="chip" />
        <img src={nfc} alt="nfc" className="nfc" />
        <p className="cardNumber">
          {formattedStr}
        </p>

        <p className="cardName">
          {card?.cardName}
        </p>

       
      
      <div className="items">
          <p className="date">{formattedDate}</p>
          <p className="exp">
            <b>EXP</b>
          </p>
          
        <p className="ccv">
            <b>CVV</b>
          </p> 
          <p className="number">{ccv}</p>
      </div>
      </div>
    </div>
  );
};

export default VirtualCard;
