import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../../api/GlobalApi'
import { trackPromise } from 'react-promise-tracker'

const HandleAction = async(status: boolean, id: number) => {
    let link: string = ''
    if(status === true) {
        link = '/v5/auth/disableUser'
    }else{
        link = '/v5/auth/enableUser'
    }

    try{
        await trackPromise(axios.post(
            API_ADMIN + link,
            {
                id: id
            },
            {
                headers: { Authorization: "Bearer " + sessionStorage.getItem("token")}
            } 
        ))
        window.location.reload();
    }catch(err){
        swal('Error','No se pudo realizar la acción, intente mas tarde','error')
    }
}

export default HandleAction
