import { usePromiseTracker } from 'react-promise-tracker';
import NavBar from '../../layout/navBar/NavBar';
import SideBar from '../../layout/sideBar/SideBar';
import Loading from '../../components/globalUtils/Loading';
import MovementsArticle from './MovementsArticle';
import useValidateJwt from '../../hooks/useValidateJwt';

const Movements = () => {
    const promiseInProgress = usePromiseTracker();
    const mouseDownHandler = useValidateJwt();

    return (
        <div onMouseDown={mouseDownHandler} style={{display: "flex"}}>
            <NavBar route={'Listado de movimientos'}/>
            <SideBar />
            <div style={{width: "100%",height:'80vh', position:'relative', top: '50px'}}>
                <MovementsArticle/>  
            </div>
            {promiseInProgress.promiseInProgress === true ? <Loading /> : null}

        </div>
    )
}

export default Movements
