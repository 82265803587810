import axios from "axios";
import { useEffect, useState } from "react";
import { isExpired } from "react-jwt";
import { API_ADMIN } from "../api/GlobalApi";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import swal from "sweetalert";

const useValidateJwt = () => {
  const history = useHistory();
  const token = sessionStorage.getItem("token");
  const [expired, setExpired] = useState<boolean>(false);
  const refreshToken = sessionStorage.getItem("refresh");
  const [clicks,setClicks] = useState<boolean>(false);
    


    const mouseDownHandler = (event: any) =>{
        if(event.button === 0){
            setClicks(!clicks);        
        }
    }


  useEffect(() => {
    const validate = () => {
      if (token !== null) {
        const res = isExpired(token);
        setExpired(res);
    }
    }
    
    validate();
  }, [clicks]);
 
  useEffect(() => {
    if (expired === true) {
      const refresh = async () => {
        try {
          const res = await trackPromise(axios.post(API_ADMIN + "/v5/auth/refreshToken", {
            refreshToken: refreshToken,
          }));
          sessionStorage.setItem('token', res.data.accessToken);
          sessionStorage.setItem('refresh', res.data.refreshToken);
          
        } catch (error) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("refresh");
          swal("Advertencia", "Su sesión ha expirado")
          history.push("/");
        }
      };
      refresh();
    }
  }, [expired]);

  return mouseDownHandler
};

export default useValidateJwt;