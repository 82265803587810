import axios from 'axios'
import React from 'react'
import { LoginResponse } from '../../rlinkPay/interfaces/LoginInterface'
import { LoginPost } from '../../rlinkPay/interfaces/LoginInterface'
import {LoginRequest} from './LoginRequest'
export class AuthServices{
    public static async login(formulario: {user: string, pass: string}): Promise<LoginResponse>{
        const prueba = formulario.user
        const data = await LoginRequest.post('v5/auth/signin', {username: formulario.user, password: formulario.pass})
        sessionStorage.setItem('customerIdNumber', data.data.customerIdNumber)
        sessionStorage.setItem('customerTypeIdNumber', data.data.customerTypeIdNumber)
        return(data.data)
    }
   

}
