import { useEffect, useState,lazy,Suspense } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "./../../../hooks/useForm";
import HandleEditLinkPay from "./HandleEditLinkPay";
import swal from "sweetalert";
import { GenerateLinkPayPost } from "../../interfaces/GenerateLinkPayInterface";
import HandleProcessEdit from "./HandleProcessEdit";

const GenerateEditLinkPay = lazy(() => import("../../../reusableFunctionalComponents/GenerateEditLinkPay"))
const EditLinkpay = () => {
  const history = useHistory();
  const route = history.location.pathname.slice(13);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [data, setData] = useState({});
  const [links, setLinks] = useState<string>("");
  const [statePay, setStatePay] = useState<string>("");
  const [validState, setValidState] = useState(true);
  const router = history.location.pathname.slice(13);
  const linkpay = sessionStorage.getItem("link");
  const [mailNoti, setMailNoti] = useState<boolean>(false);
  const [smsNoti, setSmsNoti] = useState<boolean>(false);
  const { handleChange, formulario } = useForm<GenerateLinkPayPost>({
    customerIdNumber: "",
    customerTypeIdNumber: "",
    userClientName: "",
    userClientLastName: "",
    userClientIdNumber: "",
    userClientTypeNumber: "0",
    userClientPhone: "",
    userClientEmail: "",
    amountPay: "",
    detailPay: "",
    dueDatePay: "",
    currencyPay: "CRC",
    expirationTimePay: 1440,
    invoiceNumber: '',
    sendMailNotification: false,
    sendSmsNotification: false
    });
  
  useEffect(() => {
    const validation = () => {
      if (router.length !== 64) {
        history.push("/");
      }
    };

    validation();
    waitData();
  }, []);
  
  useEffect(() => {
    const validateStatePay = () => {
      if (statePay === "Pendiente") {
        setValidState(false);
      }
    };
    validateStatePay();
  }, [statePay]);

  const setterLink = () => {
    if (linkpay !== null) {
      setLinks(linkpay);
    }
  };

  const submitForm = async () => {
    HandleProcessEdit(router, formulario, smsNoti, mailNoti);
  };
  
  const submitCopy = (): void => {
    setterLink();
    const copy = () => {
      navigator.clipboard.writeText(links);
      if (link !== "" && link !== null) {
        swal("Exito", "Su link se copió al portapapeles", "success");
        setRefreshData(!refreshData);
      }
    };
    copy();
  };

  const waitData = async () => {
    let key = sessionStorage.getItem("payKey");
    let payKey: string = "";
    if (key !== null) {
      payKey = key;
      if (payKey !== "") {
        const response = await HandleEditLinkPay(route);
        setData(response[1]);
        setStatePay(response[0]);
      }
    }
  };

  useEffect(() => {
    setRefreshData(!refreshData);
  }, [data]);
  const button:boolean = false;
  const falsed: boolean = true;
  const falsed1: boolean = validState;
  const link: string | null = sessionStorage.getItem("link")
  const da: any = {
    formulario,
        handleChange,
        submitForm,
        data,
        submitCopy,
        refreshData,
        link,
        button,
        falsed,
        falsed1,
        setMailNoti,
        mailNoti,
        smsNoti,
        setSmsNoti
  }; 
  return (
    <div style={{ display: "flex" }}>
      <Suspense fallback={<div>cargando</div>}>
      <GenerateEditLinkPay datas={da}/>
      </Suspense>
    </div>
  );
};

export default EditLinkpay;
