import { Redirect, Route } from 'react-router-dom'

interface Props{
    loggedIn: boolean | undefined,
    path: any,
    component: any,
}

const Private = ( {loggedIn ,path ,component}:Props) => {

    

    return(
        <>
            {
                sessionStorage.getItem('token') !== null ?<Route path={path} component={component}/>:<Redirect to="/"/>
            }
        </>
    )
    
}

export default Private
