import { useEffect, useState } from "react";
import swal from "sweetalert";
import { API_ADMIN } from "../../../api/GlobalApi";
import axios from "axios";
import "./../linkHistory/styles/dialogLinkHistory.css";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useForm } from "../../../hooks/useForm";
import DetailMassiveDialog from "./dialog/DetailMassiveDialog";
import FilterMassiveDialog from "./dialog/FilterMassiveDialog";
import { SimpleDialogPropsMassive } from "../../interfaces/SimpleDialogPropsMassive";
import Loading from "../../../components/globalUtils/Loading";

function SimpleDialogMassive(props: SimpleDialogPropsMassive) {
  const { promiseInProgress } = usePromiseTracker();
  const { id } = props;
  const stateMasive: string = "";
  const [valueSince, setValueSince] = useState<any>(new Date());
  const [valueTo, setValueTo] = useState<any>(new Date());
  const [disabled, setDisabled] = useState<boolean>(false);
  const [execute, setExecute] = useState<boolean>(false);
  const { formulario, handleChange } = useForm({
    id: "",
    state: "",
  });

  useEffect(() => {
    props.setIdNumber(formulario.id);
    props.setState(formulario.state);
    props.setDateSince(valueSince);
    props.setTo(valueTo);
  }, [formulario, valueSince, valueTo]);

  const handleExecute = async () => {
    if (stateMasive !== "Ejecutando") {
      try {
        await trackPromise(
          axios.post(
            API_ADMIN + "/v5/linkpay/executeLinkPayMassive",
            {
              id: id.id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            }
          )
        );
        swal("Exitoso", "Se ejecutó la masiva correctamente", "success");
      } catch (error) {}
    }
  };

  return (
    <>
      {props.dif !== "filter"
        ? DetailMassiveDialog(
            id,
            handleExecute,
            id.id,
            disabled,
            setDisabled,
            setExecute,
            execute
          )
        : FilterMassiveDialog(
            formulario,
            handleChange,
            valueSince,
            setValueSince,
            valueTo,
            setValueTo,
            props
          )}
      {promiseInProgress === true ? <Loading /> : null}
    </>
  );
}

export default SimpleDialogMassive;
