const GenerateLinkpayArticleValidation = (formulario:any,setButton: (c:boolean) => void, name: string,lastName: string,email: string,phoneNumber: string) => {
    if(formulario.userClientTypeNumber === '0' || formulario.userClientTypeNumber === '1' || formulario.userClientTypeNumber === '2'){
       
      const prueba = () => {
        if(name!== '' &&
           lastName!== '' &&
           phoneNumber !== '' &&
           email !== '' &&
           formulario.userClientIdNumber.length > 3 &&
           formulario.detailPay.length > 9 &&
           formulario.amountPay.length > 0
        ){
         setButton(false)
        }else if (
          formulario.userClientLastName.length > 3 &&
          formulario.userClientIdNumber.length > 3 &&
          formulario.userClientName.length > 2 &&
          formulario.detailPay.length > 9 &&
          formulario.userClientEmail.length !== 1 &&
          formulario.userClientPhone.length === 8 &&
          formulario.amountPay.length > 0
          
        ) {
         
          setButton(false);
        } 
      }
      prueba()
       }else{
        if (
          formulario.userClientIdNumber.length > 8 &&
          formulario.userClientName.length > 3 &&
          formulario.detailPay.length > 9 &&
          formulario.userClientEmail.length !== 1 &&
          formulario.userClientPhone.length === 8 &&
          formulario.amountPay.length
        ) {
          setButton(false);
        } 
       }
      //    (formulario.userClientLastName.length,
      //   formulario.userClientIdNumber.length,
      //   formulario.userClientName.length,
      //   formulario.detailPay.length,
      //   formulario.userClientEmail.length,
      //   formulario.userClientPhone,
      //   formulario.amountPay.length)
}


export default GenerateLinkpayArticleValidation
