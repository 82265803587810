import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  TextField,
  TextFieldProps,
  Toolbar,
  Typography,
} from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { useForm } from "../../../hooks/useForm";
import NavBar from "../../../layout/navBar/NavBar";
import SideBar from "../../../layout/sideBar/SideBar";
import ResumeFrecOptionsGraphicWidget from "../../../reusableFunctionalComponents/reusableDashboardWidgets/ResumeFrecOptionsGraphicWidget";
import VisitResumeMessagesGraphicWidget from "../../../reusableFunctionalComponents/reusableDashboardWidgets/VisitResumeMessagesGraphicWidget";
import VisitResumeUsersGraphicWidget from "../../../reusableFunctionalComponents/reusableDashboardWidgets/VisitResumeUsersGraphicWidget";
import enLocale from "date-fns/locale/en-US";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles } from "@material-ui/core/styles";
import ResumeMassiveLinksstate from "../../../reusableFunctionalComponents/reusableDashboardWidgets/ResumeMassiveLinksState";
import CurrencyReportWidget from "../../../reusableFunctionalComponents/reusableDashboardWidgets/CurrencyReportWidget";
import DateTransform from "../../../hooks/utils/DateTransform";
import useValidateJwt from "../../../hooks/useValidateJwt";
import Loading from "../../../components/globalUtils/Loading";

const LinkPayDashboardArticle = () => {
  const mouseDownHandler = useValidateJwt();
  const daysBack = 7;
  let date = new Date();
  let initialDate = date.getDate() - daysBack;
  date.setDate(initialDate);
  let initialDay: any = date;
  const { promiseInProgress } = usePromiseTracker();
  const [valueSince, setValueSince] = useState<any>(initialDay);
  const [valueTo, setValueTo] = useState<any>(new Date());
  const [refreshGraphics, setRefreshGraphics] = useState<boolean>(false);
  const [day, setDay] = useState<boolean>(false);
  const [week, setWeek] = useState<boolean>(true);
  const [mount, setMount] = useState<boolean>(false);
  const [year, setYear] = useState<boolean>(false);
  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }));
  const { formulario } = useForm<any>({
    dateInitial: valueSince,
    dateFinish: new Date(),
  });

  useEffect(() => {
    setRefreshGraphics(!refreshGraphics);
  }, [valueSince, valueTo]);

  useEffect(() => {
    setValueSince(initialDay);
  }, []);

  const style12 = styles();

  return (
    <div
      onMouseDown={mouseDownHandler}
      style={{ display: "flex", width: "100%", height: "100%" }}
    >
      <Suspense fallback={<div>cargando</div>}>
        <NavBar route={"Dashboard"} />
        <SideBar />
        <div className={style12.content}>
          <div className={style12.offset}></div>

          <div
            style={{
              position: "relative",
              top: "50px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginTop: "30px", flexDirection: "column" }}>
              <Toolbar>
                <Button
                  disabled={day}
                  onClick={() => {
                    setDay(true);
                    setWeek(false);
                    setMount(false);
                    setYear(false);
                    let h = new Date();
                    let newDate: any = h.getDate() - 1;
                    h.setDate(newDate);
                    newDate = h;

                    formulario.dateInitial = DateTransform(newDate);
                    setValueSince(newDate);
                  }}
                  sx={{ flexGrow: 1 }}
                >
                  24h
                </Button>
                <Button
                  disabled={week}
                  onClick={() => {
                    setDay(false);
                    setWeek(true);
                    setMount(false);
                    setYear(false);
                    let h = new Date();
                    let newDate: any = h.getDate() - 7;
                    h.setDate(newDate);
                    newDate = h;

                    formulario.dateInitial = DateTransform(newDate);
                    setValueSince(newDate);
                  }}
                  sx={{ flexGrow: 1 }}
                >
                  1S
                </Button>
                <Button
                  disabled={mount}
                  onClick={() => {
                    setDay(false);
                    setWeek(false);
                    setMount(true);
                    setYear(false);
                    let h = new Date();
                    let newDate: any = h.getDate() - 30;
                    h.setDate(newDate);
                    newDate = h;

                    formulario.dateInitial = newDate;
                    setValueSince(newDate);
                  }}
                  sx={{ flexGrow: 1 }}
                >
                  1M
                </Button>
                <Button
                  disabled={year}
                  onClick={() => {
                    setDay(false);
                    setWeek(false);
                    setMount(false);
                    setYear(true);
                    let h = new Date();
                    let newDate: any = h.getDate() - 365;
                    h.setDate(newDate);
                    newDate = h;

                    formulario.dateInitial = newDate;
                    setValueSince(newDate);
                  }}
                  sx={{ flexGrow: 1 }}
                >
                  1A
                </Button>

                <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Desde"
                    value={valueSince}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        formulario.dateInitial = DateTransform(newValue);
                        setValueSince(newValue);
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        size="small"
                        variant="standard"
                        style={{
                          width: "200px",
                          marginRight: "10px",
                          height: "60px",
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Hasta"
                    value={valueTo}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        formulario.dateFinish = DateTransform(newValue);
                        setValueTo(newValue);
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        size="small"
                        variant="standard"
                        style={{ width: "200px", height: "60px" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Toolbar>

              <Typography style={{ width: "100%", textAlign: "center" }}>
                Resumen de links creados
              </Typography>
              <VisitResumeMessagesGraphicWidget
                form={formulario}
                change={refreshGraphics}
                reportType={"StaticsDay"}
                page={"linkpay"}
                title={"Resumen de links creados"}
              />
              <Toolbar style={{ width: "100%" }}>
                <span style={{ width: "50%" }}>
                  <Typography style={{ width: "100%", textAlign: "center" }}>
                    Resumen de links finalizados
                  </Typography>
                  <VisitResumeUsersGraphicWidget
                    form={formulario}
                    change={refreshGraphics}
                    reportType={"StaticsDay"}
                    page={"linkpay"}
                    name={"Resumen de links finalizados"}
                  />
                </span>
                <div style={{ width: "10%" }}></div>
                <span style={{ width: "50%", display: "flex" }}>
                  <div style={{ height: "340px" }}>
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      Resumen de links por estado
                    </Typography>

                    <ResumeFrecOptionsGraphicWidget
                      form={formulario}
                      change={refreshGraphics}
                      page={"linkpay"}
                    />
                  </div>
                </span>
              </Toolbar>
              <Toolbar style={{ width: "100%" }}>
                <span style={{ width: "50%", display: "flex" }}>
                  <div style={{ height: "340px" }}>
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      Resumen de links masivos por estado
                    </Typography>

                    <ResumeMassiveLinksstate
                      form={formulario}
                      change={refreshGraphics}
                      page={"linkpay"}
                    />
                  </div>
                </span>
                <div style={{ width: "10%" }}></div>
                <span style={{ width: "50%", display: "flex" }}>
                  <div style={{ height: "340px" }}>
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      Resumen de links por tipo de moneda
                    </Typography>

                    <CurrencyReportWidget
                      form={formulario}
                      change={refreshGraphics}
                      page={"linkpay"}
                    />
                  </div>
                </span>
              </Toolbar>
            </div>
          </div>
        </div>
      </Suspense>
      {promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default LinkPayDashboardArticle;
