import { Button, Grid } from '@mui/material'
import HandleLockUnlockCard from '../utils/HandleLockUnlockCard'
import { useHistory } from 'react-router-dom';

const VirtualCardButtons = (data: string, cardNumber: string) => {
    const form = {
      card: cardNumber,
      cancelled: false
    }
const history = useHistory();
    
    return (
      
         <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing="30"
        >
          {data !== "Cerrada"?<>
          <Grid xs={6} sm={6} item>
          
            <Button
              style={{
                background: "gray",
                textTransform: "capitalize",
              }}
              fullWidth
              aria-label="Solicitar tarjeta"
              variant="contained"
              onClick={() =>{HandleLockUnlockCard(data === 'ACTIVA' ? "blockCard":data === "BLOQUEADA"?"unlockCard":'', form);
              setTimeout(() => window.location.reload(), 3000)
            }
                
              }
            >
               {data === 'ACTIVA' ? "Bloquear":data === "BLOQUEADA"?"Desbloquear":''}
            </Button>
            
          
          </Grid>
          <Grid xs={6} sm={6} item>
          
            <Button
            fullWidth
              style={{
                background: "gray",
                textTransform: "capitalize",
              }}
              disabled={data === 'Activa'? false: true}
              aria-label="Solicitar tarjeta"
              variant="contained"
              onClick={() =>{HandleLockUnlockCard( "blockCard", {card: cardNumber,cancelled: true});setTimeout(() => window.location.reload(), 3000)}
                
              }
            >
              Cancelar
            </Button>
            
          
          </Grid>
          </>:''}
          <Grid xs={6} sm={6} item>
          
            <Button
              style={{
                background: "gray",
                textTransform: "capitalize",
              }}
              fullWidth
              aria-label="Solicitar tarjeta"
              variant="contained"
              onClick={() =>{history.push('/movementsList')}}
            >
              Movimientos
            </Button>
            
          
          </Grid>
        </Grid> 
    )
}

export default VirtualCardButtons
