import axios from "axios";
import swal from "sweetalert";
import { GenerateLinkPayResponse } from "../../../interfaces/GenerateLinkPayInterface";
import { GenerateLinkPayReducer } from "./../store/storeGenerate";
import { trackPromise} from 'react-promise-tracker'
import { API_ADMIN } from "../../../../api/GlobalApi";
const HandleGenerateLinkPay = async (
  formulario: {
    customerIdNumber: string;
    customerTypeIdNumber: string;
    userClientName: string;
    userClientLastName: string;
    userClientIdNumber: string;
    userClientTypeNumber: string;
    userClientPhone: string;
    userClientEmail: string;
    amountPay: number | string;
    detailPay: string;
    dueDatePay: string;
    currencyPay: string;
    expirationTimePay: number;
    invoiceNumber: number | string;
    sendMailNotification: boolean,
    sendSmsNotification: boolean
  },
  mailNoti: boolean | number,
  smsNoti: boolean | number,
  saveAda: boolean
) => {
  let lastName: string = formulario.userClientLastName;
  const typeNumberValidation = () => {
    
    if(formulario.userClientTypeNumber === '3'){
      lastName = formulario.userClientName
    }
  }
  typeNumberValidation();
  
  let linkPay = '';  
  
  try {
    const res = await trackPromise( axios.post<GenerateLinkPayResponse>(
      API_ADMIN + "/v5/linkpay/getLinkPay",
      {
        userClientName: formulario.userClientName,
        userClientLastName: lastName,
        userClientIdNumber: formulario.userClientIdNumber,
        userClientTypeNumber: formulario.userClientTypeNumber,
        userClientPhone: formulario.userClientPhone,
        userClientEmail: formulario.userClientEmail,
        amountPay: formulario.amountPay,
        detailPay: formulario.detailPay,
        currencyPay: formulario.currencyPay,
        expirationTimePay: formulario.expirationTimePay,
        customerIdNumber: sessionStorage.getItem("customerIdNumber"),
        customerTypeIdNumber: sessionStorage.getItem("customerTypeIdNumber"),
        invoiceNumber: formulario.invoiceNumber,
        sendMailNotification: mailNoti,
        sendSmsNotification: smsNoti,
        saveAda: saveAda

      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
    );

    const actionLink = {
      type: 'link'
    }

    linkPay = GenerateLinkPayReducer(res.data.shortLinkPay, actionLink);
    
  } catch (e) {
    swal("Error", "Se ha producido un error al crear el link", "error");
  }
 
 return linkPay;
};



export default HandleGenerateLinkPay;
