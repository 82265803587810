import { BrowserRouter, Switch, Route } from "react-router-dom";
import GenerateLinkPay from "../rlinkPay/components/generateLinkPay/GenerateLinkPay";
import Login from "../components/login/Login";

import LinkPayV2 from "../rlinkPay/components/payV2/LinkPayV2";
import {AuthContext} from "./auth/store/context/AuthContext";
import { useContext } from "react";
import RoutesPrivate from "./Private";
import EditLinkPay from "./../rlinkPay/components/editLink/EditLinkpay";
import GenerateReportChatBot from "../chatbot/components/generateReports/GenerateReportChatBot";

import Home from "../components/home/Home";
import ErrorPay from "../rlinkPay/components/payV2/ErrorPay";
import GenerateReportLinkPay from "../rlinkPay/components/reportsLinkPay/GenerateReportLinkPay";
import GenerateReportChatBotDashboard from "../chatbot/components/generateDashboard/GenerateReportChatBotDashboard";
import LinkPayDashboardArticle from "../rlinkPay/components/linkPayDashboard/LinkPayDashboardArticle";
import CreateUsers from "../components/createUsers/CreateUsers";
import ListUsers from "../components/listUsers/ListUsers";
import CreateMassiveLinks from "../rlinkPay/components/linkHistory/CreateMassiveLinks";
import MasivesLinks from "../rlinkPay/components/masivesLinks/MasivesLinks";
import LinkHistory from "../rlinkPay/components/linkHistory/LinkHistory";
import ChangePass from "../components/login/ChangePass";
import Sinpe from "../sinpe/Sinpe";
import CreatePin from "../sinpe/pin/CreatePin";
import Sinpedashboard from "../sinpe/dashBoard/Sinpedashboard";
import SinpeReports from "../sinpe/reports/SinpeReports";
import Success from "../rlinkPay/components/payV2/Success";
import SinpeOperationMassiveState from "../sinpeMassive/dashboard/SinpeOperationMassiveState";
import ListSinpeOperationMassive from "../sinpeMassive/listSinpeOperationMassive/ListSinpeOperationMassive";
import SaveSinpeOperationMassive from "../sinpeMassive/saveSinpeOperationMassive/SaveSinpeOperationMassive";
import ListAdas from "../adas/ListAdas";
import GenerateAdaReport from "../adas/GenerateAdaReport";
import AdaDashboard from "../adas/AdaDashboard";
import NewCard from "../cards/createCard/NewCard";
import ListCardInfo from "../cards/listCardInfo/ListCardInfo";
import VirtualCard from "../cards/virtualCard/VirtualCard";
import Movements from "../cards/movements/Movements";


interface Context{
  dispatchUser?:any,
  user?: User
}

interface User{
  loggedIn: boolean
}


  const AppRoutes = () => {
  
    const { user }:Context = useContext(AuthContext)

  return (
   
      <BrowserRouter>
      <Switch>
        
        <Route path="/pay" component={LinkPayV2}/>
        <Route path="/errorPay" component={ErrorPay}/>
        <Route path="/success" component={Success}/>
        <Route path='/changePass/:id' component={ChangePass}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/home' component={Home}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/dashboard' component={GenerateReportChatBotDashboard}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/dashboardLinkPay' component={LinkPayDashboardArticle}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/generateLinkPay' component={GenerateLinkPay}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/linkHistory' component={LinkHistory}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/editLinkPay' component={EditLinkPay}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/editLinkPay' component={EditLinkPay}/>   
        <RoutesPrivate loggedIn={user?.loggedIn} path='/reportChatBots' component={GenerateReportChatBot}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/reportLinkPay' component={GenerateReportLinkPay}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/createUsers' component={CreateUsers}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/listUsers' component={ListUsers}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/massiveLinks' component={MasivesLinks}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/createMassiveLinks' component={CreateMassiveLinks}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/sinpe' component={Sinpe}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/pin' component={CreatePin}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/sinpeDashBoard' component={Sinpedashboard}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/sinpeReports' component={SinpeReports}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/sinpeMassiveDashboard' component={SinpeOperationMassiveState}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/listSinpeMassive' component={ListSinpeOperationMassive}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/saveSinpeMassive' component={SaveSinpeOperationMassive}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/listAda' component={ListAdas}/> 
        <RoutesPrivate loggedIn={user?.loggedIn} path='/adaReport' component={GenerateAdaReport}/> 
        <RoutesPrivate loggedIn={user?.loggedIn} path='/adaDashboard' component={AdaDashboard}/> 
        <RoutesPrivate loggedIn={user?.loggedIn} path='/createNewCard' component={NewCard}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/listCardInfo' component={ListCardInfo}/>
        <RoutesPrivate loggedIn={user?.loggedIn} path='/movementsList' component={Movements}/>
        <Route exact path="/" component={Login} />
      </Switch>
    </BrowserRouter>
    
    
  );
  };

export default AppRoutes;
