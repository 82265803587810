import {lazy, Suspense} from 'react'
import "./styles/generateLinkpay.css";

import  {GenerateLinkPayArticle} from'./GenerateLinkPayArticle';

const GenerateLinkPay = () => {
  
  return (
    
      
      <div>
        <Suspense fallback={<div>cargando</div>}>
        <GenerateLinkPayArticle/>
      </Suspense>  
    </div>
    
  );
};

export default GenerateLinkPay;