import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { Ada, AdaListInterfaceResponse, AdaListPost } from '../../rlinkPay/interfaces/AdaListInterface'
import { trackPromise } from 'react-promise-tracker'

const GetListAdas = async(setAda: (c:Ada[]) => void, form: AdaListPost) => {
    try{
        const res = await trackPromise(axios.post<AdaListInterfaceResponse>(
            API_ADMIN + '/v5/adas/getListAdas',
            form,
            {
                headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ))
        setAda(res.data.adas)
    }catch (e){
        swal('Error','No se pudo obtener el listado de adas','error')
    }
}

export default GetListAdas
