import axios from 'axios'
import { API_ADMIN } from '../../api/GlobalApi'
import swal from 'sweetalert'
import { trackPromise } from 'react-promise-tracker'

const HandleExecuteSinpeMassive = async(id: number | string) => {
    try{
        await trackPromise(axios.post(
            API_ADMIN + '/v5/sinpe/executeSinpeOperationMassive',
            {
                id: id
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ));
    }catch (e) {
        swal('error','error','error')
    }
}

export default HandleExecuteSinpeMassive
