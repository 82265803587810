import { Button, DialogTitle, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from '../hooks/useForm';
import { SimpleDialogPropsSinpe } from '../rlinkPay/interfaces/SimpleDialogPropsSinpe';
import DialogAssign from './dialog/DialogAssign';
import DialogSinpeFilter from './dialog/DialogSinpeFilter';

const SinpeDialogs = (props: SimpleDialogPropsSinpe) => {
    
    const [valueSince, setValueSince] = useState<any>(new Date());
    const [valueTo, setValueTo] = useState<any>(new Date());
    const [pin, setPin] = useState<string>('');
    
    useEffect(()=>{
      let varSince: string = JSON.stringify(valueSince);
      let varTo: string = JSON.stringify(valueTo);
  
      props.setDateSince(varSince.slice(1,11))
      props.setTo(varTo.slice(1,11))
    },[valueSince,valueTo])
  
    const {formulario, handleChange} = useForm(
      {
        idNumber: '',
        name: '',
        phone: '',
        state:'',
        description: '',
        amount: '',
        idUser: '',
      }
    );
  
    useEffect(() => {
      props.setName(formulario.name);
      props.setIdNumber(formulario.idNumber);
      props.setState(formulario.state);
      props.setDescription(formulario.description);
      props.setPhoneNumber(formulario.phone);
      props.setAmount(formulario.amount);
      props.setIdUser(formulario.idUser)

    },[formulario])
    
    return (
        <>
           {
            props.dif === 'filter' ?
            DialogSinpeFilter(formulario, handleChange, valueSince, setValueSince, valueTo, setValueTo, props):
            props.dif === 'liberar'?'':DialogAssign(props.data, props.setDif)
           }
           {
            props.dif === 'liberar'?
            <div style={{width: '500px'}}>
               <DialogTitle className="titleDialog">Pin de validación</DialogTitle>
      <Grid container alignItems="center" justifyContent="center" spacing="15">
        <Grid xs={5} sm={5} item>
          <TextField
            label="Pin"
            name='pin'
            value={pin}
            onChange={(e: any) => {setPin(e.target.value)}}
          />
        </Grid>
      
          
       
        <Grid item xs={12}></Grid>
        
        </Grid>
        <Grid style={{
            width: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }} item>
          <Button
            style={{background: "gray", marginBottom: "10px", width: "90%", display: "flex", left: '5%'}}
            aria-label="Copiar Link"
            variant="contained"
           
            
          >
            Validar pin
          </Button>
        </Grid>
            </div>:''
           } 
        </>
    )
}

export default SinpeDialogs
