import { Button, DialogTitle, Grid, InputLabel, Typography } from '@mui/material';
import { DetailSinpeOperationMassiveResponse } from '../interfaces/DetailSinpeOperationMassive';
import HandleExecuteSinpeMassive from '../utils/HandleExecuteSinpeMassive';

const DialogInfo = (detail: DetailSinpeOperationMassiveResponse) => {

    return (
        <>
        <DialogTitle className="titleDialog">Detalle de SINPE Masivo</DialogTitle>
      <Grid container alignItems="center" justifyContent="center" spacing="15">
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Id de usuario
          </InputLabel>
          <Typography className="dialogsData">
            {detail?.id_user? detail.id_user: '--'}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Estado
          </InputLabel>
          <Typography className="dialogsData">
            {detail?.state? detail.state: '--'}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
        <InputLabel className="dialogInputsLabels">
            Id
          </InputLabel>
          <Typography className="dialogsData">
            {detail?.id? detail.id: '--'}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Activo</InputLabel>
          <Typography className="dialogsData">
            {detail?.active === true ? 'Si': detail?.active === false ? 'No':'--'}
          </Typography>
        </Grid>
       

        <Grid xs={12} sm={12} item>
        <InputLabel className="dialogInputsLabels">Fecha</InputLabel>
          <Typography className="dialogsData">
            {detail?.create_at? detail.create_at: '--'}
          </Typography>
          
        </Grid>
       
        <Grid xs={4} sm={4} item>
            <Button
              style={{ background: "gray",textTransform: "capitalize", marginBottom: "10px" }}
              aria-label="Ejecutar"
              variant="contained"
              onClick={() => {
                const a = () => {
                  // setDisabled(true);
                  HandleExecuteSinpeMassive(detail?.id)
                }
                a();
              }}
              disabled={detail?.state !== 'Guardado'? true: false}
              fullWidth
            >
              Ejecutar
            </Button>
          </Grid>
        </Grid>
      </>
    )
}

export default DialogInfo
