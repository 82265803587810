import axios from "axios";
import { useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { trackPromise } from "react-promise-tracker";
import { API_ADMIN } from "../../api/GlobalApi";
import { FreqOptionsResponse } from "../../chatbot/interfaces/FreqoptionsInterface";
import DateTransform from "../../hooks/utils/DateTransform";
import {FrecOptionContext} from './../../chatbot/components/generateDashboard/context/FrecOptionsContext';
const ResumeMassiveLinksstate = ({form, change, page}:any) => {
     
    const [labels, setLabels] = useState<string[]>([]);
    const [saved, setSaved] = useState<number>(0);
    const [executed, setExecuted] = useState<number>(0);
    const [completed, setCompleted] = useState<number>(0);
  
    let prueba = new Date();
    prueba.setDate(prueba.getDate() - 6)
    
    const Series = async() => {
      // const result:any = await HandleGenerateDashboard(formulario);
     
      try {

        const res = await trackPromise( axios.get(
          API_ADMIN + `/v5/linkpay/reportMassiveState?dateStart=${form.dateInitial.length !== 10? DateTransform(form.dateInitial): form.dateInitial}&dateEnd=${form.dateFinish.length !== 10? DateTransform(form.dateFinish): form.dateFinish}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
        );
        
        setLabels(Object.keys(res.data))
        setSaved(res.data.Guardado);
        setExecuted(res.data.Ejecutando)
        setCompleted(res.data.Completado)
        
      } catch (e) {
       
      }
    
    }
    
    useEffect(() => {
      setTimeout(() => {
        Series()
      }, 800)
      
    },[change,form]
  
    )
  
    const titles = {
      title: {
        text: 'Estado de masivas',
        align: 'left'
      }
    }
    const charts = {
      chart: {
        type: 'pie',
        height: 350,
        zoom: {
          enabled: true
        }
      }
    }
  
  
    const options = {
      series:  [executed,saved,completed],
     
      dataLabels: {
        enabled: true,
        
      },
     labels:labels,
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              width: 450,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },{
          breakpoint: 1500,
          options: {
            chart: {
              width: 300,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 400,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 800,
          options: {
            chart: {
              width: 200
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              width: 150
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 100
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        }
      ],
    };
    return (
      
            <ReactApexChart
              width={425}
              options={options}
              series={options.series}
              type="pie"
              height={425}
            />
         
    );
  };
  
  export default ResumeMassiveLinksstate;