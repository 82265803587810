import { Button, DialogTitle, Grid, InputLabel, Typography } from '@mui/material';
import React from 'react';


const DetailDialogLinkHistory = (copy: any, history: any, dataLink: any, typeId: any, simbol: any, exp: any, handleClose: () => {}) => {
    return (
        <>
        <DialogTitle className="titleDialog">{`Información del Link Nº ${dataLink?.id? dataLink.id: ''}`}</DialogTitle>
        
      <Grid container alignItems="center" justifyContent="center" spacing="15">
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Tipo de identificación
          </InputLabel>
          <Typography className="dialogsData">
            {dataLink?.userClientTypeNumber ? typeId : ""}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Número de identificación
          </InputLabel>
          <Typography className="dialogsData">
            {dataLink?.userClientIdNumber ? dataLink.userClientIdNumber : ""}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Nombre</InputLabel>
          <Typography className="dialogsData">
            {dataLink?.userClientName ? dataLink.userClientName : ""}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Apellido</InputLabel>
          <Typography className="dialogsData">
            {dataLink?.userClientLastName ? dataLink.userClientLastName : ""}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Correo electrónico
          </InputLabel>
          <Typography className="dialogsData">
            {dataLink?.userClientEmail ? dataLink.userClientEmail : ""}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Número de teléfono
          </InputLabel>
          <Typography className="dialogsData">
            {dataLink?.userClientPhone ? dataLink.userClientPhone : ""}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Moneda</InputLabel>
          <Typography className="dialogsData">
            {dataLink?.currencyPay ? dataLink.currencyPay : ""}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">Monto</InputLabel>
          <Typography className="dialogsData">
            {dataLink
              ? simbol +
                dataLink.amountPay.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : ""}
          </Typography>
        </Grid>
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Tiempo de expiración
          </InputLabel>
          <Typography className="dialogsData">
            {dataLink?.expirationTimePay ? exp : ""}
          </Typography>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Grid xs={5} sm={5} item>
          <InputLabel className="dialogInputsLabels">
            Número de factura
          </InputLabel>
          <Typography className="dialogsData">
            {dataLink?.invoiceNumber ? dataLink.invoiceNumber : ""}
          </Typography>
        </Grid>
        <Grid xs={11} sm={10.3} item>
          <InputLabel className="dialogInputsLabels">Detalle</InputLabel>
          <Typography className="dialogsData">
            {dataLink?.detailPay ? dataLink.detailPay : ""}
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid xs={11} sm={10.3} item>
          <Button
            style={{background: "gray",textTransform: "capitalize"}}
            disabled={
              dataLink?.expired === true || dataLink?.statePay !== "Pendiente"
                ? true
                : false
            }
            aria-label="Crear Link"
            variant="contained"
            onClick={() => {
              history.push("/editLinkPay/" + dataLink.payKey);
            }}
            fullWidth
          >
            Editar Link
          </Button>
        </Grid>
        <Grid xs={11} sm={10.3} item>
          <Button
            style={{background: "gray", marginBottom: "10px",textTransform: "capitalize"}}
            aria-label="Copiar Link"
            variant="contained"
            onClick={() => {
              copy();
            }}
            fullWidth
          >
            Copiar Link
          </Button>
        </Grid>
      </Grid>
      </>
    )
}

export default DetailDialogLinkHistory
