import axios from "axios";
import swal from "sweetalert";
import { API_ADMIN } from "../../api/GlobalApi";
import { trackPromise } from "react-promise-tracker";

const HandleCreateOrEditPin = async (pin: string) => {
  try {
    await trackPromise(
      axios.post(
        API_ADMIN + "/v5/auth/saveAccesPin",
        {
          pin: pin,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
    );
    swal("Exitoso", "Se creo el pin de forma correcta", "success");
  } catch (error) {
    swal("Error", "No se pudo crear el pin", "error");
  }
};

export default HandleCreateOrEditPin;
