import { useEffect, useState } from 'react'
import NavBar from '../layout/navBar/NavBar'
import SideBar from '../layout/sideBar/SideBar'
import ReactApexChart from "react-apexcharts";
import { Typography } from "@mui/material";
import { AdaDatum, DashboardAdaPost } from './interfaces/DashboardAdaInterface';
import { useForm } from '../hooks/useForm';
import DashboardAda from './utils/DashboardAda';
import useValidateJwt from '../hooks/useValidateJwt';
const AdaDashboard = () => {
  const mouseDownHandler = useValidateJwt();
    const [data, setData] = useState<AdaDatum>({
        Activas_Integer: 0,
        Inactivas_Integer:0
    })

    const {formulario} = useForm<DashboardAdaPost>({
        ownerAccountIdNumber:   '603780859',
        ownerAccountTypeIdNumber:  '0'
    })

    useEffect(() => {
        DashboardAda(formulario, setData)
    },[])

    const options = {
        series: [data?.Activas_Integer, data?.Inactivas_Integer],
    
        dataLabels: {
          enabled: true,
        },
        labels: ["Activas", "Inactivas"],
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        responsive: [
          {
            breakpoint: 2000,
            options: {
              chart: {
                width: 450,
                height: 300,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 1500,
            options: {
              chart: {
                width: 300,
                height: 300,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 400,
                height: 300,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 1000,
            options: {
              chart: {
                width: 300,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 800,
            options: {
              chart: {
                width: 200,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 600,
            options: {
              chart: {
                width: 150,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 100,
              },
    
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    return (
        <div onMouseDown={mouseDownHandler} style={{display: 'flex'}}>
            <NavBar route={'Dashboard Pagos Recurrentes'}/>
            <SideBar/>
            <div
        style={{
          width: "100%",
          height: "80vh",
          position: "relative",
          top: "90px",
          flexDirection: "column",
        }}
      >
        <Typography style={{ width: "100%", textAlign: "center" }}>
          Reporte de estados de sinpe masivo
        </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <ReactApexChart
            width={"100%"}
            options={options}
            series={options.series}
            type="pie"
            height={400}
          />
        </div>
      </div>
        </div>
    )
}

export default AdaDashboard
