import axios from "axios";
import { API_ADMIN } from "../../api/GlobalApi";
import { trackPromise } from "react-promise-tracker";


export class LoginRequest{
   static baseUrl = API_ADMIN + '/'

   public static post(path:string, obj:any):Promise<any>{
      return trackPromise( axios.post(this.baseUrl + path, obj));
   }
}

