import axios from 'axios'
import {API_ADMIN} from '../../api/GlobalApi';
import {MenusLayOutResponse} from '../../globalInterfaces/MenusLayOutInterface'
import { trackPromise } from 'react-promise-tracker';

const HandleMenus = async(setData: (c: MenusLayOutResponse[]) => void) => {
   try{
    const res = await trackPromise(axios.get<MenusLayOutResponse[]>(
        API_ADMIN + '/v5/privileges/menus',
        {
            headers: {Authorization: "Bearer" + sessionStorage.getItem('token')}
        }
    ))
    setData(res.data);
   }catch (e){
   }
   
}

export default HandleMenus
