export const GenerateLinkPayReducer = (state: string = '', action: { type: string; }, ) => {
  
    switch (action.type) {
        case 'link':              
            return state
        default:
            return state
    }

  }


  

 
  