import { useHistory } from "react-router-dom";
import {lazy, Suspense} from 'react'
import "./generateReports.css";
import GenerateDashboard from "../../../reusableFunctionalComponents/GenerateDashboard";
import useValidateJwt from "../../../hooks/useValidateJwt";

const GenerateReportChatBotDashboard = () => {
  const mouseDownHandler = useValidateJwt();

  return (
      <div onMouseDown={mouseDownHandler}>
        <Suspense fallback={<div>cargando</div>}>
        <GenerateDashboard/> 
        </Suspense>
    </div>    
  );
};

export default GenerateReportChatBotDashboard;