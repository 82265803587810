import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { API_ADMIN } from '../../api/GlobalApi'

const HandleDashboard = async(dateStart: any, dateEnd: any, setArrayData: any, setArrayHeaders: any) => {
    try {
        const res = await trackPromise( axios.post(
            API_ADMIN + '/v5/SinpeMovilEntrante/reportSinpe',
            {
                dateStart: dateStart,
                dateEnd: dateEnd
            },
            {
                headers: { Authorization: "Bearer " + sessionStorage.getItem("token")}
            }
        ))
        
        setArrayData(res.data?.[0].mes, res.data?.[0].cantidad, [res.data?.[0].total])
        setArrayHeaders(Object.keys(res.data[0]))
    } catch (error) {
          
    }
    
}

export default HandleDashboard;
