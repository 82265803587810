import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import Widget from "./Widget";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, TextFieldProps, Toolbar } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useState } from "react";
import { useForm } from "../../hooks/useForm";
import enLocale from "date-fns/locale/en-US";
import { Typography } from "@material-ui/core";
import { usePromiseTracker } from "react-promise-tracker";
import DateTransform from "../../hooks/utils/DateTransform";
import useValidateJwt from "../../hooks/useValidateJwt";
import Loading from "../../components/globalUtils/Loading";

const Sinpedashboard = () => {
  const mouseDownHandler = useValidateJwt();
  let date = new Date();
  let initialDate = date.getDate() - 7;
  date.setDate(initialDate);
  let initialDay: any = date;
  const [week, setWeek] = useState(true);
  const [mount, setMount] = useState(false);
  const [day, setDay] = useState(false);
  const [year, setYear] = useState(false);
  const [valueSince, setValueSince] = useState(initialDay);
  const [valueTo, setValueTo] = useState(new Date().toISOString());
  const { promiseInProgress } = usePromiseTracker();
  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }));

  const sinpeDashBoardStyle = styles();

  const { formulario } = useForm<any>({
    dateInitial: valueSince,
    dateFinish: new Date(),
  });

  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Dashboard de SINPE Móvil"} />
      <SideBar />
      <div className={sinpeDashBoardStyle.content}>
        <div className={sinpeDashBoardStyle.offset}></div>

        <div
          style={{
            width: "100%",
            height: "80vh",
            position: "relative",
            top: "60px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%" }}>
            <Toolbar>
              <Button
                disabled={day}
                onClick={() => {
                  setDay(true);
                  setWeek(false);
                  setMount(false);
                  setYear(false);
                  let h = new Date();
                  let newDate: any = h.getDate() - 1;
                  h.setDate(newDate);
                  newDate = h;

                  formulario.dateInitial = newDate;
                  setValueSince(newDate);
                }}
                sx={{ flexGrow: 1 }}
              >
                24h
              </Button>
              <Button
                disabled={week}
                onClick={() => {
                  setDay(false);
                  setWeek(true);
                  setMount(false);
                  setYear(false);
                  let h = new Date();
                  let newDate: any = h.getDate() - 7;
                  h.setDate(newDate);
                  newDate = h;

                  formulario.dateInitial = newDate;
                  setValueSince(newDate);
                }}
                sx={{ flexGrow: 1 }}
              >
                1S
              </Button>
              <Button
                disabled={mount}
                onClick={() => {
                  setDay(false);
                  setWeek(false);
                  setMount(true);
                  setYear(false);
                  let h = new Date();
                  let newDate: any = h.getDate() - 30;
                  h.setDate(newDate);
                  newDate = h;

                  formulario.dateInitial = newDate;
                  setValueSince(newDate);
                }}
                sx={{ flexGrow: 1 }}
              >
                1M
              </Button>
              <Button
                disabled={year}
                onClick={() => {
                  setDay(false);
                  setWeek(false);
                  setMount(false);
                  setYear(true);
                  let h = new Date();
                  let newDate: any = h.getDate() - 365;
                  h.setDate(newDate);
                  newDate = h;

                  formulario.dateInitial = newDate;
                  setValueSince(newDate);
                }}
                sx={{ flexGrow: 1 }}
              >
                1A
              </Button>

              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Desde"
                  value={valueSince}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue: any) => {
                    if (newValue !== null) {
                      setValueSince(newValue);
                      formulario.dateInitial = DateTransform(newValue);
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => (
                    <TextField
                      size="small"
                      variant="standard"
                      style={{
                        width: "200px",
                        marginRight: "10px",
                        height: "60px",
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Hasta"
                  value={valueTo}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue: any) => {
                    if (newValue !== null) {
                      setValueTo(newValue);
                      formulario.dateFinish = DateTransform(newValue);
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => (
                    <TextField
                      size="small"
                      variant="standard"
                      style={{ width: "200px", height: "60px" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Toolbar>
          </div>
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "10px" }}
          >
            <Typography>Resumen de SINPE Móvil entrante</Typography>
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Widget dateEnd={valueTo} dateStart={valueSince} />
          </div>
        </div>
      </div>

      {promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default Sinpedashboard;
