import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../../../api/GlobalApi'
import { trackPromise } from 'react-promise-tracker'

const HandleMasiveLinks = async(data: any, setButton: (c: boolean) => void, button: boolean) => {
    try {
        await trackPromise(axios.post(API_ADMIN + '/v5/linkpay/saveLinkPayMassive',
        {
            linksItems: data
        },
        { headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },}
        ))
          
        swal('Exitoso', 'Se Crearon los links con exito','success');
        
    } catch (error) {
        swal('Error', 'No se pudo crear los link masivos', 'error')
    }
}

export default HandleMasiveLinks
