import swal from "sweetalert";
import { trackPromise} from 'react-promise-tracker'
import { API_ADMIN } from "../../../api/GlobalApi";
import DateTransform from "../../../hooks/utils/DateTransform";

const HandleGenerateReport = (formulario:any) => {
   
  let data = {
    reportType:  formulario.reportType,
    dateInitial: DateTransform(formulario.dateInitial),
    dateFinish: DateTransform(formulario.dateFinish),
  };
  
  trackPromise(fetch(API_ADMIN + "/v5/linkpay/downloadReport", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if(response.status === 200) {
        response.blob().then((blob) => {
          
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${formulario.reportType}-${formulario.dateInitial}-${formulario.dateFinish}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
      }else if(response.status === 505){
        swal("Error", "Los datos ingresados no son correctos", "error");
      }else{
        swal("Error", "La consulta no ha generado resultados", "error");
      }
      
    }))
    ;
};


export default HandleGenerateReport
