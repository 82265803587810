import swal from "sweetalert";
import { trackPromise} from 'react-promise-tracker'
import { API_ADMIN } from "../../api/GlobalApi";
import DateTransform from "../../hooks/utils/DateTransform";
import { DownloadReportAdaBody } from "../../rlinkPay/interfaces/DownloadReportListAdas";

export const ReportListAdas = async (
    formulario: DownloadReportAdaBody
      
  ) => {
    let data = {
      dateStart: DateTransform(formulario.dateStart),
      dateEnd: DateTransform(formulario.dateEnd),
      reportName: formulario.reportName,
      ownerAccountIdNumber: formulario.ownerAccountIdNumber,
      page: null,
      size: null
    };
    
    trackPromise(fetch(API_ADMIN + "/v5/adas/downloadReportAda", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if(response.status === 200) {
          response.blob().then((blob) => {
            
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${formulario.reportName}-${formulario.dateStart}-${formulario.dateEnd}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
        }else if(response.status === 505){
          swal("Error", "Los datos ingresados no son correctos", "error");
        }else{
          swal("Error", "La consulta no ha generado resultados", "error");
        }
        
      }))
      ;
  };
  
