import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React, { useContext } from 'react'
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import { ContextLayOut } from '../layoutContext/LayOutContext';
import "./style/sidebar.css";

const HomeList = (clasess: any) => {
    const { widths,isOpen } = useContext(ContextLayOut);
    return (
        <Link style={{textDecoration: 'none'}} to='/home'>
        <ListItem
          className={clasess.clasess.items}
          style={{ width: `${widths - 5}px`, paddingRight: 0 }}
          button
        >
          <ListItemIcon className={clasess.clasess.icon}>
            <FaHome aria-label="Home" className="icon" />
          </ListItemIcon>
          {isOpen ? (
            ""
          ) : (
            <ListItemText className={clasess.clasess.tittle}>Principal</ListItemText>
          )}
        </ListItem>
        </Link>
    )
}

export default HomeList
