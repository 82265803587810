import {
  CreateUsersResponse,
} from "../../globalInterfaces/CreateUserInterface";
import axios from "axios";
import swal from "sweetalert";
import { API_ADMIN } from "../../api/GlobalApi";
import { trackPromise } from "react-promise-tracker";

const HandleCreateUsers = (formulario:any) => {
  
  const submitHandle = async () => {
    try {
      await trackPromise(axios.post<CreateUsersResponse>(
        API_ADMIN + "/v5/auth/signup",
        {
          username: formulario.username,
          password: formulario.password,
          email: formulario.email,
          customerIdNumber: formulario.customerIdNumber,
          customerTypeIdNumber: formulario.customerTypeIdNumber,
          countryId: 506
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ));
      swal("Usuario creado con exito", "", "success");
      
      
    } catch (e) {
      swal("Error", "Ah ocurrido un error Intente mas tarde", "error");
    }
  };

  submitHandle();
};

export default HandleCreateUsers;
