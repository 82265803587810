import { useEffect, useState } from "react";
import {
  CardInfo,
  ListCardInfoReq,
  ListCardInfoResponse,
  InfoCardResponse
} from "../interfaces/CardsInterfaces";
import HandleListCardInfo from "../utils/HandleListCardInfo";
import { useForm } from "../../hooks/useForm";
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Toolbar } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import ReusableDialogs from "../../reusableFunctionalComponents/ReusableDialogs";
import useValidateJwt from "../../hooks/useValidateJwt";
import CardDetail from "../virtualCard/CardDetail";
import HandleGetCardData from "../utils/HandleGetCardData";

const ListCardInfoArticle = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [dif, setDif] = useState<string>("");
  const [data, setData] = useState<ListCardInfoResponse>();
  const [searchDialog, setSearchDialog] = useState<boolean>(false);
  const [card, setCard] = useState<CardInfo>();
  const [page, setPage] = useState(1);
  const mouseDownHandler = useValidateJwt();
  const [cardInfo, setCardInfo] = useState<InfoCardResponse>()
  const [virtualCard, setVirtualCard] = useState<boolean>(false);
  const { formulario, handleChange } = useForm<ListCardInfoReq>({
    card_id: 0,
    dateStart: "",
    dateEnd: "",
    state: "",
    privilege: "",
    page: 0,
    size: 7,
    pages: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    formulario.page = page;
  }, [page]);
 
  useEffect(() => {
    HandleListCardInfo(setData, formulario, page);
  }, [searchDialog, page]);

  return (
    <div onMouseDown={mouseDownHandler} className="articleContainer">
      {!virtualCard?<div className="paperContainer">
        <Paper className="muiPaper">
          <TableContainer className="tableContainer">
            <Toolbar>
              <Button
                onClick={(e) => {
                  history.push("/createNewCard");
                }}
                style={{ color: "#000" }}
              >
                <AddIcon className="addIcon" aria-label="Solicitar tarjeta" />{" "}
                Solicitar tarjeta
              </Button>
              <Typography style={{ flexGrow: 1 }}> </Typography>

              <Button
                onClick={() => {
                  setDif("filter");
                  handleClickOpen();
                }}
                variant="contained"
              >
                <SearchIcon />
              </Button>
            </Toolbar>
            <Table style={{ zIndex: 19 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="cell">Id</TableCell>
                  <TableCell className="cell">Cliente</TableCell>
                  <TableCell className="cell">Fecha</TableCell>
                  <TableCell className="cell">Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.cardInfos?.map((card) => (
                  <TableRow
                    className="rowTable"
                    onClick={async () => {
                      setDif("edit");
                      setCard(card);
                      sessionStorage.setItem('cardNumber',card?.cards[0]?.card)
                      const virtual = () => {
                        HandleGetCardData(card.cards[0].card, setCardInfo);
                        setVirtualCard(true)
                      }
                      card.cards.length > 0? virtual(): handleClickOpen();
                    }}
                    key={card.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="row">{card.id}</TableCell>
                    <TableCell className="row">{card.cardName}</TableCell>
                    <TableCell className="row">{card.createAt}</TableCell>
                    <TableCell className="row">{card.state}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              page={formulario.page}
              count={data?.totalPages}
              color="primary"
              shape="rounded"
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </TableContainer>

          <ReusableDialogs
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            form={formulario}
            dif={dif}
            dateSince={formulario.dateStart}
            to={formulario.dateEnd}
            component={"listCardInfo"}
            handleClose={handleClose}
            setSearchDialog={setSearchDialog}
            searchDialog={searchDialog}
            handleChange={handleChange}
            card={card}
            page={page}
            cardNumber={data?.cardInfos[0]?.cards[0]?.card}
          />
        </Paper>
      </div>:CardDetail(cardInfo,setVirtualCard)}
    </div>
  );
};

export default ListCardInfoArticle;