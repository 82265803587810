import axios from "axios";
import React from "react";
import { trackPromise } from "react-promise-tracker";
import { GetDataLinkPayResponse } from "../../../interfaces/PayInterface";
import { API_ADMIN } from "../../../../api/GlobalApi";

export const HandleGetDataLinkPay = async (
  setIconClientPay: (c: string) => void,
  setAmount: (c: number) => void,
  routerPayKey: string,
  setDetail: (c: string) => void,
  setId: (c: string) => void,
  setPayKey:(c: string) => void,
  setProps:(c: string) => void,
  setPropsImg:(c: string) => void,
  setExpired:(c: boolean) => void,
  setTextLegend:(c: string) => void,
  setSuccessImg:(c: string) => void,
  setTextLegendSuccess:(c: string) => void,
  setUrlImgCard:(c: string) => void,
  setUrlTermsAndConditions:(c: string) => void,
  setCurrency:(c: string) => void,
  setProcessPay:(c: string) => void,
  setMessageErrorPay:(c: string) => void,
  history:any
) => {
  try {
    const res = await trackPromise(
      axios.post<GetDataLinkPayResponse>(
        API_ADMIN + "/v5/linkpay/getDataLinkPay",
        {
          payKey: routerPayKey,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    );
    console.log(res);
    setDetail(res?.data?.detailPay);
    setId(res?.data?.userClientIdNumber);
    setAmount(res?.data?.amountPay);
    setIconClientPay(res?.data?.linkProps[0]?.urlIcon);
    setPayKey(res?.data?.payKey);
    setProps(res?.data?.linkProps[0]?.templatePay);
    setPropsImg(res?.data?.linkProps[0]?.urlExpiredLink);
    setExpired(res?.data?.expired);
    setTextLegend(res?.data?.linkProps[0]?.textLegend);
    setSuccessImg(res?.data?.linkProps[0]?.urlImgSuccess);
    setTextLegendSuccess(res?.data?.linkProps[0]?.textLegendSuccess);
    setUrlImgCard(res?.data?.linkProps[0]?.urlImgCard);
    setUrlTermsAndConditions(res?.data?.urlTermsAndConditions);

    if (res.data.currencyPay === "CRC") {
      setCurrency("₡");
    }
    if (res.data.currencyPay === "USD") {
      setCurrency("$");
    }
    if (res.data.currencyPay === "EUR") {
      setCurrency("€");
    }
    if (res.data.expired === true && res.data.statePay !== "COMPLETED") {
      setProcessPay("ERROR");
      setMessageErrorPay(
        "El link se encuentra expirado y no puede ser utilizado"
      );
    }
    if (res.data.statePay === "Finalizado") {
      setProcessPay("SUCCESS");
    }
  } catch (e: any) {
    console.log(e);
    if (e?.data?.valid !== true) {
      localStorage.setItem("valid", e?.data?.valid);
      history.push("/errorPay");
    }
    setProcessPay("ERROR");
  }
};
