import "./App.css";
import AppRoutes from "./routes/Routes";
import { useReducer } from "react";
import { AuthContext } from "./routes/auth/store/context/AuthContext";
import { AuthReducer } from "./routes/auth/store/reducer/AuthReducer";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/Theme";
import { ContextLayOutProvider } from "./layout/layoutContext/LayOutContext";
import { LinkPayContextProvider } from "./rlinkPay/components/payV2/linkPayContext/LinkPayContext";
import { ContextLoginProvider } from "./components/login/context/LoginContext";
import { FrecOptionContextProvider } from "./chatbot/components/generateDashboard/context/FrecOptionsContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ContextFilterIdNumProvider } from "./rlinkPay/components/generateLinkPay/context/FilterIdNumContext";
import { NewCardProvider } from "./cards/context/NewCardContext";
const init = () => {
  let sessionUser: any = sessionStorage.getItem("user");
  let user: any;
  if (!sessionUser) {
    user = sessionUser;
  } else {
    user = JSON.parse(sessionUser);
  }
  return user;
};

function App() {
  const [user, dispatchUser] = useReducer(AuthReducer, {}, init);

  return (
    
    <ThemeProvider theme={theme}>
      <LinkPayContextProvider>
     <NewCardProvider>
      <FrecOptionContextProvider>
      <ContextLayOutProvider>
        <ContextFilterIdNumProvider>
        <ContextLoginProvider>
        
          <AuthContext.Provider value={{ user, dispatchUser }}>
            <AppRoutes />
          </AuthContext.Provider>
        
        </ContextLoginProvider>
        </ContextFilterIdNumProvider>
      </ContextLayOutProvider>
      </FrecOptionContextProvider>
      </NewCardProvider>
      </LinkPayContextProvider>
    </ThemeProvider>
    
  );
}

export default App;
