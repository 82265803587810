import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import HandleDashboard from '../handles/HandleDashboard';

const Widget = (dateEnd: any, dateStart: any) => {
    const [arrayData, setArrayData] = useState([]);
    const [arrayHeaders, setArrayHeaders] = useState([]);
    
    useEffect(() => {
        HandleDashboard(dateEnd.dateStart, dateEnd.dateEnd,setArrayData, setArrayHeaders);
    },[dateEnd.dateStart,dateEnd.dateEnd])

    const charts = {
        chart: {
          height: 350,
          width: 600,
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            },
            autoSelected: "zoom"
          }
        },
      }
    
      const options = {
        series: [
          {
            name: 'Resumen de Sinpe movil entrante',
            data: arrayData,
          },
        ],
    
        charts: [
          {
            toolbar: {
              show: false,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true
              },
              autoSelected: "zoom"
            }
          },
        ],
        
        dataLabels: {
          enabled: true
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        responsive: [
          {
            breakpoint: 2000,
            options: {
              chart: {
                width: 1000,
                height: 300
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          },{
            breakpoint: 1500,
            options: {
              chart: {
                width: 850,
                height: 300
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 800,
                height: 300
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          },
          {
            breakpoint: 1000,
            options: {
              chart: {
                width: 600
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          },
          {
            breakpoint: 800,
            options: {
              chart: {
                width: 400
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          },
          {
            breakpoint: 600,
            options: {
              chart: {
                width: 300
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          },
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 200
              },
              
              
              legend: {
                position: "bottom"
              },
              
            },
            
          }
        ],
    
    
        xaxis: {
          categories: arrayHeaders,
        },
      };
      return (
    
       
          <ReactApexChart 
                options={options}
                series={options.series}
                width={'100%'}
                charts={options.charts}
                
                
              />    
      );
    };

export default Widget
