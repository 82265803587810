import { useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";
import { Ada, AdaListPost } from "../rlinkPay/interfaces/AdaListInterface";
import GetListAdas from "./utils/GetListAdas";
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Toolbar } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import NavBar from "../layout/navBar/NavBar";
import SideBar from "../layout/sideBar/SideBar";
import { useHistory } from "react-router-dom";
import ReusableDialogs from "../reusableFunctionalComponents/ReusableDialogs";
import useValidateJwt from "../hooks/useValidateJwt";

const ListAdas = () => {
  const mouseDownHandler = useValidateJwt();
  const [dif, setDif] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [ada, setAda] = useState<Ada[]>([]);
  const search: boolean = false;
  const history = useHistory();
  const pages: number = 0;
  const [page, setPage] = useState<number>(1);
  const [id, setId] = useState<number>(0);
  const { formulario } = useForm<AdaListPost>({
    page: "1",
    size: "7",
    ownerAccountIdNumber: "0",
    dateStart: "",
    dateEnd: "",
  });

  useEffect(() => {
    GetListAdas(setAda, formulario);
  }, [page, search]);

  useEffect(() => {
    formulario.page = `${page}`;
  }, [page]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Listado de cobros recurrentes"} />
      <SideBar />
      <div className="articleContainer">
        <div className="paperContainer">
          <Paper className="muiPaper">
            <TableContainer
              style={{ marginTop: "90px" }}
              className="tableContainer"
            >
              <Toolbar>
                {/* <Button
                  onClick={(e) => {
                    history.push("/saveSinpeMassive");
                  }}
                  style={{ color: "#000" }}
                >
                  <AddIcon className="addIcon" aria-label="Crear Link" /> Crear
                  Sinpe Masivo
                </Button> */}
                <Typography style={{ flexGrow: 1 }}> </Typography>

                <Button
                  onClick={() => {
                    setDif("filter");
                    handleClickOpen();
                  }}
                  variant="contained"
                >
                  <SearchIcon />
                </Button>
              </Toolbar>
              <Table style={{ zIndex: 19 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="cell">Id</TableCell>
                    <TableCell className="cell">Nombre del cliente</TableCell>
                    <TableCell className="cell">
                      Identificacion de cliente
                    </TableCell>
                    <TableCell className="cell">Cuenta de cliente</TableCell>
                    <TableCell className="cell">Moneda</TableCell>
                    <TableCell className="cell">Monto</TableCell>
                    <TableCell className="cell">Estado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ada.map((datum: Ada) => (
                    <TableRow
                      className="rowTable"
                      onClick={() => {
                        setDif("edit");
                        setId(datum.id);
                        handleClickOpen();
                      }}
                      key={datum.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="row">{datum.id}</TableCell>
                      <TableCell className="row">{datum.ada_type}</TableCell>
                      <TableCell className="row">{datum.description}</TableCell>
                      <TableCell className="row">
                        {datum.expirationDate}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                page={Number(formulario.page)}
                count={pages}
                color="primary"
                shape="rounded"
                hideNextButton={true}
                hidePrevButton={true}
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </TableContainer>
          </Paper>
        </div>
      </div>
      <ReusableDialogs
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        dif={dif}
        component={"listAda"}
        handleClose={handleClose}
        id={id}
        detail={""}
      />
    </div>
  );
};

export default ListAdas;
