import { Grid } from "@material-ui/core";
import { Button, TextField, Typography } from "@mui/material";
import React from "react";
import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "../../hooks/useForm";
import HandleCreateOrEditPin from "../handles/HandleCreateOrEditPin";

const CreatePin = () => {

    const styles = makeStyles((theme) => ({
        offset: theme.mixins.toolbar,
        content: {
          padding: theme.spacing(3),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
    
        },
      }));
    
      const pinStyles = styles();

      const {formulario, handleChange} = useForm({
        pin: ''
      });

      const onSubmit = () => {
        HandleCreateOrEditPin(formulario.pin);
      }
  return (
    <div style={{ display: "flex" }}>
      <NavBar route={"Historial de links"} />
      <SideBar />
      <div className={pinStyles.content}>
        <div className={pinStyles.offset}></div>

        <div
          style={{
            width: "100%",
            height: "80vh",
            position: "relative",
            top: "40px",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Typography sx={{ textAlign: "center", width: "238px" }}>
              Crear Pin
            </Typography>
            <Grid style={{ flexDirection: "column" }} xs={12} sm={12} container>
              <Grid style={{ marginTop: "10px" }} item>
                <TextField size="small" label="Pin"
                    name='pin' 
                    value={formulario.pin}
                    onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    marginTop: "10px",
                    width: "238px",
                    backgroundColor: "#808080",
                  }}
                  variant="contained"
                  onClick={onSubmit}
                >
                  Crear Pin
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePin;
