import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { DetailSinpeOperationMassiveResponse } from '../interfaces/DetailSinpeOperationMassive'
import { trackPromise } from 'react-promise-tracker'

const HandleDetailSinpeOperationMassive = async(setDetail: (c: DetailSinpeOperationMassiveResponse) => void, id: number | string) => {
    try {
        const res = await trackPromise(axios.get(API_ADMIN + `/v5/sinpe/detailSinpeOperationMassive/?id=${id}`,
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        }
        ))
        setDetail(res.data);
    } catch (error) {
        swal('Error', 'Error', 'error');
    }
}

export default HandleDetailSinpeOperationMassive
