import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { API_ADMIN } from "../../../../api/GlobalApi";
import { ReportListResponse } from "../../../../chatbot/interfaces/ReportListInterface";
import swal from "sweetalert";

const HandleDashboarLinkPay = async (formulario:any, form:any) => {

  try {

    const res = await trackPromise( axios.post(
      API_ADMIN + "/v5/linkpay/reportList",
      {
        dateInitial:form.dateInitial,
        dateFinish: form.dateFinish,
        reportType: formulario,
        requiredFor: ""
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
    );
    return res.data.data

    
  } catch (e) {
    swal("Error", "Se ha producido un error al obtener los datos", "error");
  }
    
}

export default HandleDashboarLinkPay
