import "./generateReports.css";
import { makeStyles } from "@material-ui/core/styles";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import { usePromiseTracker } from "react-promise-tracker";
import { lazy, Suspense} from "react";
import useValidateJwt from "../../../hooks/useValidateJwt";
const GenerateReportArticle = lazy(() => import('./GenerateReportArticle'));
const SideBar = lazy(() => import('../../../layout/sideBar/SideBar'));
const NavBar = lazy(() => import('../../../layout/navBar/NavBar'))
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 20;
`;


const GenerateReportChatBot = () => {
  const mouseDownHandler = useValidateJwt();
  const {promiseInProgress} = usePromiseTracker();
  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'

    },
  }));
  

  const style1 = styles();
  return (
      <div onMouseDown={mouseDownHandler} style={{display: 'flex'}}>
        <Suspense fallback={<div>cargando</div>}>
        <NavBar route={'Crear Reporte'}/>
        <SideBar/>
        <div className={style1.content}>
        <div className={style1.offset}></div>
        <GenerateReportArticle/>  
        </div> 
        </Suspense>
        {promiseInProgress === true ? (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1000000,
              left:0
            }}
          >
            <FadeLoader color="#fc923f" loading={true} css={override} />
          </div>
        ) : null}       
    </div>    
  );
};

export default GenerateReportChatBot;