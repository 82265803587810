import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { NewCardResponse } from "../interfaces/CardsInterfaces";

type NewCardContextType = {
    newCards: NewCardResponse[];
    setNewCards: Dispatch<SetStateAction<NewCardResponse[]>>;
  };
  
export const NewCardContext = createContext<NewCardContextType>({
    newCards: [],
    setNewCards: () => {},
});

export const NewCardProvider: FC = ({ children }) => {
  const [newCards, setNewCards] = useState<NewCardResponse[]>([]);
  return (
    <NewCardContext.Provider value={{ newCards, setNewCards }}>
      {children}
    </NewCardContext.Provider>
  );
};
