import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import SummarizeIcon from "@mui/icons-material/Summarize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useContext, useEffect, useState } from "react";
import "./style/sidebar.css";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import PaymentsIcon from '@mui/icons-material/Payments';

const SinpeMassiveList = (clasess: any) => {

    const [display,setDisplay] = useState<string>('none');
  const { widths, isOpen, setIsOpen } = useContext(ContextLayOut);

  useEffect(() => {
    if(isOpen){
        setDisplay('none');
    }
  },[isOpen]);
   
    return (
        <>
      <ListItem
        className={clasess.clasess.items}
        onClick={(e) => {
          setDisplay(
            display === "" ? "none" : display === "none" ? "" : "none"
          );
          if (isOpen !== false) {
            setIsOpen(false);
          }
        }}
        style={{ width: `${widths - 5}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon className={clasess.clasess.icon}>
          <PaymentsIcon aria-label="Chat Bot" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>
            Sinpe Masivo{" "}
            {display === "none" ? (
              <KeyboardArrowUpIcon
                style={{ position: "relative", top: "6px" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ position: "relative", top: "6px" }}
              />
            )}
          </ListItemText>
        )}
      </ListItem>
      <List style={{ display: `${display}` }}>
        <Link style={{ textDecoration: "none" }} to="/listSinpeMassive">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <BarChartIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Listado de masivas</ListItemText>
          </ListItem>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/sinpeMassiveDashboard">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <SummarizeIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Dashboard</ListItemText>
          </ListItem>
        </Link>
      </List>
    </>
    )
}

export default SinpeMassiveList
