import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import "./linkpay.css";
import Loading from "../../../components/globalUtils/Loading";
import { HandleProcessLinkpay } from './handle/HandleProcessLinkpay';

const LinkPayArticle = ({
  props,
  urlTermsAndConditions,
  urlImgCard,
  iconClientPay,
  detail,
  iban,
  setIban,
  terms,
  setTerms,
  button,
  amount,
  currency,
  id,
  setProcessPay,
  setMessageErrorPay,
  payKey,
  textLegend,
}: any) => {
  const [validationForm, setValidation] = useState(true);
  const [clientId, setClientId] = useState("");

  const handlePay = async () => {
    setValidation(false);
    sessionStorage.setItem("paykey", payKey);
    HandleProcessLinkpay(
      setMessageErrorPay,
      setProcessPay,
      payKey,
      iban,
      id,
      setValidation
    );
  };

  return (
    <Container className="fondos" maxWidth="sm">
      <Grid container maxWidth="sm">
        <Grid item xs={12}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                width: "40%",
                height: "40%",
                display: "flex",
              }}
              src={iconClientPay || ""}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                width: "90%",
                height: "90%",
                display: "flex",
              }}
              src={urlImgCard || ""}
            />
          </Box>
        </Grid>
        <Box
          border={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 1,
            m: 1,
            "& .MuiTextField-root": { m: 1, width: "100%" },
            maxWidth: "370px",
            width: "100%",
          }}
        >
          <Grid item xs={12}>
            <TextField
              label="Detalle"
              multiline
              maxRows={4}
              variant="outlined"
              value={detail}
              size="small"
              inputProps={{
                min: 0,
                style: { textAlign: "center", fontSize: 16 },
              }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              fullWidth
            />
          </Grid>
        </Box>
        {props === "template_v2" ? (
          <ValidatorForm style={{ width: "100%" }} onSubmit={handlePay}>
            <Box
              border={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
                m: 1,
                "& .MuiTextField-root": { m: 1, width: "100%" },
                maxWidth: "370px",
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                <TextValidator
                  label="Número de identificación"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  value={clientId}
                  size="small"
                  name="id"
                  inputProps={{
                    min: 0,
                    style: { textAlign: "center", fontSize: 16 },
                  }}
                  onChange={(e: any) => {
                    setClientId(e.target.value);
                  }}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                  validators={[`matchRegexp:^${id}$`]}
                  errorMessages={[
                    "Identificación no corresponde con la registrada en el link",
                  ]}
                  fullWidth
                />
              </Grid>
            </Box>
          </ValidatorForm>
        ) : (
          <div style={{ display: "none" }}></div>
        )}

        <ValidatorForm style={{ width: "100%" }} onSubmit={handlePay}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              "& .MuiTextField-root": { m: 1, width: "100%" },
              maxWidth: "370px",
            }}
          >
            <Grid style={{ width: "80%", alignItems: "center" }} item xs={12}>
              <TextValidator
                label="Cuenta IBAN"
                name="userClientIdNumber"
                variant="outlined"
                value={iban}
                size="small"
                onChange={(e: any) => setIban(e.target.value)}
                placeholder="Ingrese su cuenta IBAN"
                inputProps={{
                  min: 0,
                  style: { textAlign: "center", fontSize: 16 },
                }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                validators={["matchRegexp:^(cr|Cr|CR|cR)[0-9]{20}$"]}
                errorMessages={["La cuenta IBAN consta de CR + 20 dígitos"]}
                fullWidth
              />
            </Grid>
          </Box>
        </ValidatorForm>
        <Grid item xs={12}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 1,
            }}
          >
            <FormControlLabel
              control={<Checkbox onChange={() => setTerms(!terms)} />}
              label={
                <a
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  href={urlTermsAndConditions}
                  rel="noreferrer"
                >
                  Acepto Términos y Condiciones
                </a>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 1,
            }}
          >
            <Button
              onClick={handlePay}
              variant="contained"
              disabled={
                props === "template_v2"
                  ? terms !== false && iban.length === 22 && clientId === id
                    ? false
                    : true
                  : terms !== false &&
                    iban.length === 22 &&
                    validationForm === true
                  ? false
                  : true
              }
              fullWidth
              sx={{ textTransform: "capitalize" }}
            >
              {button === true
                ? `Pagar: ${currency}${amount.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}`
                : `Pagar: ${currency}${amount.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}`}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 1,
            }}
          >
            <Typography fontSize={10}>
              Con el respaldo de Ridivi Costa Rica - www.ridivi.com
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ marginBottom: "60px" }} item xs={12}>
          <Box
            border={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: 1,
            }}
          >
            <Typography sx={{ textAlign: "justify" }} fontSize={10}>
              {textLegend}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* {promiseInProgress === true ? <Loading /> : null} */}
    </Container>
  );
};

export default LinkPayArticle;
