import { useState } from "react";
import { useForm } from "../../../hooks/useForm";
import DownloadReport from "../../../reusableFunctionalComponents/DownloadReport";
import {useHistory} from 'react-router-dom'
import HandleGenerateReport from "./HandleGenerateReport";

const GenerateReportLinkPayArticle = () => {
  const [valueSince, setValueSince] = useState<any>(new Date().toISOString());
  const [valueTo, setValueTo] = useState<any>(new Date().toISOString());
  const history = useHistory();
  const route = history.location.pathname
  const { handleChange, formulario } = useForm({
    reportType: "ListLink",
    dateInitial: new Date().toISOString(),
    dateFinish: new Date().toISOString(),
  });

  const submitForm = async (e: any) => {
    e.preventDefault(); 
    setTimeout(() => {HandleGenerateReport(formulario)},800);
        
  };

  return (
    <div>
      {DownloadReport(
        submitForm,
        formulario,
        handleChange,
        valueSince,
        setValueSince,
        valueTo,
        setValueTo,
        route
      )}
    </div>
  );
};

export default GenerateReportLinkPayArticle;
