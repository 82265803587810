import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PinIcon from '@mui/icons-material/Pin';
import "./style/sidebar.css";

const SecurityList = (clasess: any) => {

    const [display, setDisplay] = useState<string>("none");
    const [secureDisplay, setSecureDisplay] = useState<string>("none");
    const { widths, isOpen, setIsOpen } = useContext(ContextLayOut);
    
    useEffect(() => {
        if(isOpen){
            setDisplay('none');
        }
    },[isOpen])
    
  return (
    <>
      {/* <ListItem
        className={clasess.clasess.items}
        onClick={(e) => {
          setSecureDisplay(
            secureDisplay === "" ? "none" : display === "none" ? "" : "none"
          );
          if (isOpen !== false) {
            setIsOpen(false);
          }
        }}
        style={{ width: `${widths - 5}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon className={clasess.clasess.icon}>
          <VpnKeyOutlinedIcon aria-label="Seguridad" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>
            Seguridad{" "}
            {secureDisplay === "none" ? (
              <KeyboardArrowUpIcon
                style={{ position: "absolute", right: '20px' }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ position: "absolute", right: '20px' }}
              />
            )}
          </ListItemText>
        )}
      </ListItem>
      <List style={{ display: `${secureDisplay}` }}>
        <Link style={{ textDecoration: "none" }} to="/listUsers">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <PeopleAltIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Usuarios</ListItemText>
          </ListItem>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/pin">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <PinIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Pin</ListItemText>
          </ListItem>
        </Link>
      </List> */}
    </>
  );
};

export default SecurityList;
