import { Button } from "@material-ui/core";
import useBase64Image from "../../hooks/useBase64Image";
import HandleUploadImages from "../utils/HandleUploadImages";
import { usePromiseTracker } from "react-promise-tracker";
import Loading from "../../components/globalUtils/Loading";
import useValidateJwt from "../../hooks/useValidateJwt";

const FilesUpload = ({idNumber, setDisplay}: any) => {
  const { base64Image: base64Image1, handleFileInputChange: handleFileInputChange1 } = useBase64Image();
  const { base64Image: base64Image2, handleFileInputChange: handleFileInputChange2 } = useBase64Image();
  const upload = () => {
    HandleUploadImages(base64Image1, idNumber, setDisplay);
    HandleUploadImages(base64Image2, idNumber, setDisplay);
  }
  const mouseDownHandler = useValidateJwt();

  return (
    <div onMouseDown={mouseDownHandler} style={{display: 'flex', width: '100%', height: '70vh', position: 'relative', top: '100px'}}>
      
      <label htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="image1"
          type="file"
          onChange={handleFileInputChange1}
        />

        <Button color="primary" variant="contained" component="span">
          Foto frontal
        </Button>
      </label>
      
      <br/>
      
      
      <label style={{ marginLeft: '20px'}} htmlFor="upload-photo2">
        <input
          style={{ display: "none" }}
          id="upload-photo2"
          name="image2"
          type="file"
          onChange={handleFileInputChange2}
        />
      
        <Button color="primary" variant="contained" component="span">
          Foto trasera
        </Button>
      </label>
      
      <div style={{width: '100%', position: 'absolute', top: '50px'}}>
      <Button variant="contained" style={{width: '24%'}}  disabled={base64Image1 !== '' && base64Image1 !== ''? false: true} onClick={upload}>Enviar</Button>
      </div>

    </div>
  );
};

export default FilesUpload;
