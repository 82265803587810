import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HandleGenerateDashboard from "../../chatbot/components/generateDashboard/HandleGenerateDashboard";
import HandleDashboarLinkPay from "../../rlinkPay/components/linkPayDashboard/handles/HandleDashboarLinkPay";

const VisitResumeUsersGraphicWidget = ({
  form,
  change,
  reportType,
  page,
  name,
}: any) => {
  const [data, setData] = useState<any>();
  const [arrayData, setArrayData] = useState<any>(["1"]);
  const [arrayHeaders, setArrayHeaders] = useState<any>();

  useEffect(() => {
    const getData = () => {
      let arrayMessagesString: any[] = [];
      let arrayHeaders: any[] = [];
      if (page !== "linkpay") {
        if (data !== undefined) {
          data.map((resp: any) => {
            arrayMessagesString.push(JSON.stringify(resp.Usuarios_Integer));
            arrayHeaders.push(JSON.stringify(resp.Fecha_String));
          });
        }
        if (arrayMessagesString !== [] && arrayHeaders !== []) {
          setArrayData(arrayMessagesString);
          setArrayHeaders(arrayHeaders);
        }
      } else {
        if (data !== undefined) {
          data.map((resp: any) => {
            arrayMessagesString.push(
              JSON.stringify(resp.CantidadFinalizado_Integer)
            );
            arrayHeaders.push(JSON.stringify(resp.Fecha_String));
          });
        }
        if (arrayMessagesString !== [] && arrayHeaders !== []) {
          setArrayData(arrayMessagesString);
          setArrayHeaders(arrayHeaders);
        }
      }
    };
    getData();
  }, [data]);

  useEffect(() => {
    const prueba = async () => {
      if (page === "linkpay") {
        const result = await HandleDashboarLinkPay(reportType, form);
        setData(result);
      } else {
        const result = await HandleGenerateDashboard(reportType, form);
        setData(result);
      }
    };
    setTimeout(() => {
      prueba();
    }, 1000);
  }, [change]);

  const titles = {
    title: {
      text: "Resumen Semana - Finalizados",
      align: "left",
    },
  };
  const charts = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
  };

  const options = {
    series: [
      {
        name: name,
        data: arrayData,
      },
    ],

    dataLabels: {
      enabled: true,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },

    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 500,
            height: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: 425,
            height: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 400,
            height: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 200,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            width: 150,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 100,
          },

          legend: {
            position: "bottom",
          },
        },
      },
    ],

    xaxis: {
      categories: arrayHeaders,
    },
  };
  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="area"
      title={titles.title.text}
      charts={charts.chart}
    />
  );
};

export default VisitResumeUsersGraphicWidget;
