import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import SummarizeIcon from '@mui/icons-material/Summarize';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddLinkSharpIcon from '@mui/icons-material/AddLinkSharp';
import ListIcon from '@mui/icons-material/List';
import BarChartIcon from "@mui/icons-material/BarChart";
import "./style/sidebar.css";

const RLinkList = (clasess: any) => {

    const { widths,isOpen, setIsOpen } = useContext(ContextLayOut);
    const [displayLink,setDisplayLink] = useState<string>('none');

    useEffect(() => {
        if(isOpen) {
            setDisplayLink('none');
        }
    },[isOpen])

  return (
    <>
      <ListItem
        className={clasess.clasess.items}
        onClick={(e) => {
          setDisplayLink(
            displayLink === "" ? "none" : displayLink === "none" ? "" : "none"
          );
          if (isOpen !== false) {
            setIsOpen(false);
          }
        }}
        style={{ width: `${widths - 5}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon 
        className={clasess.clasess.icon}
        >
          <FaLink aria-label="RLink" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>
            RLink{" "}
            {displayLink === "none" ? (
              <KeyboardArrowUpIcon
                style={{ position: "relative", top: "6px", left: "56px" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ position: "relative", top: "6px", left: "56px" }}
              />
            )}
          </ListItemText>
        )}
      </ListItem>
      <List style={{ display: `${displayLink}` }}>
        <Link style={{ textDecoration: "none" }} to="/dashboardLinkPay">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <BarChartIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Dashboard</ListItemText>
          </ListItem>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/linkHistory">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <AddLinkSharpIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Links</ListItemText>
          </ListItem>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/massiveLinks">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <ListIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Masivos</ListItemText>
          </ListItem>
        </Link>

         <Link style={{ textDecoration: "none" }} to="/reportLinkPay">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <SummarizeIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Reportes</ListItemText>
          </ListItem>
        </Link>

      
      </List>
    </>
  );
};

export default RLinkList;