import { useContext } from 'react'
import { LinkPaycontext } from './linkPayContext/LinkPayContext';
import './errorPay.css'

const ErrorPay = () => {

    const {iconClientPay, propsImg} = useContext(LinkPaycontext);
    return (
        <div className="errorPayContainer">
            <img src={iconClientPay} className='logoHeader'/>
            <div style={{position: 'absolute', top:'20%'}}>
            </div>
            <img src={propsImg} className='imgError'/>
            <div className='msgContainer'>
                
                <p className='msgFooter'>Con el respaldo de Ridivi Costa Rica -  www.ridivi.com</p>
            </div>
        </div>
    )
}

export default ErrorPay
