import { Button, InputLabel, Select } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import HandleSaveSinpeOperationMassive from "../utils/HandleSaveSinpeOperationMassive";
import { MenuItem } from "@material-ui/core";
import useValidateJwt from "../../hooks/useValidateJwt";

const SaveSinpeOperationMassiveArticle = () => {
  const [data, setData] = useState<any[]>([]);
  const [request, setRequest] = useState<any[]>([]);
  const [validateFile, setValidateFile] = useState<boolean>(true);
  const [currency, setCurrency] = useState<string>("CRC");
  const [transactionType, setTransactionType] = useState<string>("dtr");
  const mouseDownHandler = useValidateJwt();

  const readUploadFile = (e: any) => {
    e.preventDefault();
    setValidateFile(false);

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  useEffect(() => {
    const formatData = data.map(
      ({ Identificación, Cuenta_Iban, Monto, Descripción, Servicio }) => ({
        fromId: "000000000",
        fromIban: "CR00000000000000000000",
        toId: Identificación,
        toIban: Cuenta_Iban,
        time: transactionType,
        service: Servicio,
        currency: currency,
        amount: Monto,
        text: Descripción,
      })
    );

    setRequest(formatData);
  }, [data]);

  const submitMassiveLinks = () => {
    setValidateFile(true);
    HandleSaveSinpeOperationMassive(request);
  };

  return (
    <div onMouseDown={mouseDownHandler}
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "200px" }}>
        <InputLabel style={{ marginTop: "10px" }} id="demo-simple-select-label">
          Moneda
        </InputLabel>
        <Select
          style={{ width: "170px", height: "35px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label=""
          value={currency}
          onChange={(e: any) => setCurrency(e.target.value)}
        >
          <MenuItem value="CRC">Colones</MenuItem>
          <MenuItem value="USD">Dolares</MenuItem>
        </Select>
      </div>
      <div
        style={{ width: "200px", position: "absolute", marginLeft: "190px" }}
      >
        <InputLabel style={{ marginTop: "10px" }} id="demo-simple-select-label">
          Tipo de transferencia
        </InputLabel>
        <Select
          style={{ width: "170px", height: "35px" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label=""
          value={transactionType}
          onChange={(e: any) => setTransactionType(e.target.value)}
        >
          <MenuItem value="tft">Enviar dinero</MenuItem>
          <MenuItem value="dtr">Solicitar dinero</MenuItem>
        </Select>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          display: "flex",
          position: "relative",
          top: "20px",
        }}
      >
        <label htmlFor="upload-photo">
          <input
            style={{ display: "none" }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            accept=".xlsx"
            onChange={readUploadFile}
          />

          <Button
            color="primary"
            size="small"
            component="span"
            aria-label="add"
            variant="contained"
            style={{ textTransform: "capitalize" }}
            onClick={() => setValidateFile(false)}
          >
            <AddIcon /> Seleccionar archivo
          </Button>
        </label>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          position: "relative",
          top: "-20px",
        }}
      >
        <a href="https://storage.googleapis.com/ridivi_util/ridivi/BaseLink.xlsx">
          Descargar plantilla{" "}
        </a>
      </div>

      <Button
        onClick={submitMassiveLinks}
        variant="contained"
        sx={{
          marginTop: "10px",
          width: "20%",
          height: "35px",
          textTransform: "capitalize",
        }}
        disabled={validateFile}
      >
        Cargar Archivo
      </Button>
    </div>
  );
};

export default SaveSinpeOperationMassiveArticle;
