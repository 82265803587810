import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import HandleListSinpe from "./handles/HandleListSinpe";
import { usePromiseTracker } from "react-promise-tracker";
import { Sinpe } from "../rlinkPay/interfaces/ListSinpeInterface";
import SearchIcon from "@mui/icons-material/Search";
import ReusableDialogs from "../reusableFunctionalComponents/ReusableDialogs";
import Loading from "../components/globalUtils/Loading";
import useValidateJwt from "../hooks/useValidateJwt";

const SinpeArticle = () => {
  const [pages, setPages] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [dif, setDif] = useState<string>("");
  const [sinpe, setSinpe] = useState<Sinpe[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { promiseInProgress } = usePromiseTracker();
  const [selectedValue, setSelectedValue] = useState("");
  const [name, setName] = useState<string>("");
  const [idNumber, setIdNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [idUser, setIdUser] = useState("");
  const [amount, setAmount] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [dateSince, setDateSince] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [searchDialog, setSearchDialog] = useState(false);
  const [data, setData] = useState({});
  const mouseDownHandler = useValidateJwt();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    HandleListSinpe(
      setPages,
      page,
      setSinpe,
      description,
      name,
      phoneNumber,
      idNumber,
      idUser,
      amount,
      state,
      dateSince,
      to
    );
  }, [searchDialog]);

  return (
    <div onMouseDown={mouseDownHandler} className="articleContainer">
      <div className="paperContainer">
        <Paper className="muiPaper">
          <TableContainer className="tableContainer">
            <Toolbar>
              <Typography style={{ flexGrow: 1 }}> </Typography>

              <Button
                onClick={() => {
                  setDif("filter");
                  handleClickOpen();
                }}
                variant="contained"
              >
                <SearchIcon />
              </Button>
            </Toolbar>
            <Table style={{ zIndex: 19 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="cell">Id</TableCell>
                  <TableCell className="cell">Cliente</TableCell>
                  <TableCell className="cell">Identificación</TableCell>
                  <TableCell className="cell">Monto</TableCell>
                  <TableCell className="cell">Teléfono</TableCell>
                  <TableCell className="cell">Fecha</TableCell>
                  <TableCell className="cell">Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sinpe.map((link: Sinpe) => (
                  <TableRow
                    className="rowTable"
                    onClick={async () => {
                      setDif("edit");
                      setData(
                        link
                          ? {
                              id: link.id,
                              idUser: link.idUser,
                              movNum: link.movNum,
                              amount: link.amount,
                              description: link.description,
                              name: link.name,
                              phone: link.phone,
                              idClient: link.idClient,
                              customerAccount: link.customerAccount,
                              active: link.active,
                              createAt: link.createAt,
                              state: link.state,
                            }
                          : ""
                      );
                      handleClickOpen();
                    }}
                    key={link.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="row">{link.id}</TableCell>
                    <TableCell className="row">{link.name}</TableCell>
                    <TableCell className="row">{link.idClient}</TableCell>
                    <TableCell className="row">{"₡ " + link.amount}</TableCell>
                    <TableCell className="row">{link.phone}</TableCell>
                    <TableCell className="row">
                      {link.createAt.slice(0, 19)}
                    </TableCell>
                    <TableCell
                      style={{ textTransform: "capitalize" }}
                      className="row"
                    >
                      {link.state}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={pages}
              color="primary"
              shape="rounded"
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </TableContainer>

          <ReusableDialogs
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            component={"sinpe"}
            dif={dif}
            setDateSince={setDateSince}
            setTo={setTo}
            setName={setName}
            setIdNumber={setIdNumber}
            setPhoneNumber={setPhoneNumber}
            setIdUser={setIdUser}
            setAmount={setAmount}
            setState={setState}
            setDescription={setDescription}
            setSearchDialog={setSearchDialog}
            searchDialog={searchDialog}
            data={data}
            setDif={setDif}
          />
        </Paper>
      </div>
      {promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default SinpeArticle;
