import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { FaWindows } from 'react-icons/fa';
import NavBar from '../../layout/navBar/NavBar'
import SideBar from '../../layout/sideBar/SideBar'
import Chart from './widgets/Chart';
import PrimerWidget from './widgets/PrimerWidget';
import SegundoWidget from './widgets/SegundoWidget';

const Home = () => { 
     
    const styles = makeStyles((theme) => ({
        offset: theme.mixins.toolbar,
        content: {
          padding: theme.spacing(3),
          width:'100%'
        },
      }));

      const style3 = styles()

    return (
        <div style={{display: 'flex'}}>
            <NavBar route={'Inicio'}/>
            <SideBar/>
            <div className={style3.content}>
                {/* <div className={style.offset}></div>
                <div style={{width: "100%",height: "30%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Chart/>
                </div>
                </div>
                <PrimerWidget/>
                <SegundoWidget/> */}
                 
            </div>
        </div>
    )
}

export default Home
