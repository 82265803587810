import { useEffect, useState } from "react";
import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import { ReportSinpeOperationMassiveResponse } from "../interfaces/ReportSinpeOperationMassive";
import HandleSinpeOperationMassiveStateReport from "../utils/HandleSinpeOperationMassiveStateReport";
import ReactApexChart from "react-apexcharts";
import { Typography } from "@mui/material";
import useValidateJwt from "../../hooks/useValidateJwt";

const SinpeOperationMassiveState = () => {
  const [data, setData] = useState<ReportSinpeOperationMassiveResponse>({
    Completado: 0,
    Ejecutando: 0,
    Guardado: 0,
  });
    const mouseDownHandler = useValidateJwt();

  useEffect(() => {
    HandleSinpeOperationMassiveStateReport(setData);
  }, []);

  const options = {
    series: [data?.Completado, data?.Ejecutando, data?.Guardado],

    dataLabels: {
      enabled: true,
    },
    labels: ["Completado", "Ejecutando", "Guardado"],
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 450,
            height: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: 300,
            height: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 400,
            height: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: 300,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 200,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            width: 150,
          },

          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 100,
          },

          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

    
  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex", width: "100%", height: "100%" }}>
      <NavBar />
      <SideBar />

      <div
        style={{
          width: "100%",
          height: "80vh",
          position: "relative",
          top: "90px",
          flexDirection: "column",
        }}
      >
        <Typography style={{ width: "100%", textAlign: "center" }}>
          Reporte de estados de sinpe masivo
        </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <ReactApexChart
            width={"100%"}
            options={options}
            series={options.series}
            type="pie"
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

export default SinpeOperationMassiveState;
