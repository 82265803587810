import { createContext, FC, useState } from "react";
import { ResumeFrecStore } from "./store/ResumeFrecStore";
import { FreqOptionsType } from "./types/FreqOptionsType";

export const FrecOptionContext =
  createContext<FreqOptionsType>(ResumeFrecStore);

export const FrecOptionContextProvider: FC = ({ children }) => {
  const [Bienvenida, setBienvenida] = useState<number>(0);
  const [Reservas, setReservas] = useState<number>(0)
  const [Descuentos, setDescuentos] = useState<number>(0)
  const [Descripción, setDescripción] = useState<number>(0)
  const [ClubVac, setClubVac] = useState<number>(0)
  const [HoraEntrada, setHoraEntrada] = useState<number>(0)
  const [Grupos, setGrupos] = useState<number>(0)
  const [AmaLlaves, setAmaLlaves] = useState<number>(0)
  const [Minivacaciones, setMinivacaciones] = useState<number>(0)
  const [Restricción, setRestricción] = useState<number>(0)
  const [Contactar, setContactar] = useState<number>(0)
 
  return (
    <FrecOptionContext.Provider
      value={{
        Bienvenida, setBienvenida,
        Reservas, setReservas,
        Descuentos, setDescuentos,
        Descripción, setDescripción,
        ClubVac, setClubVac,
        HoraEntrada, setHoraEntrada,
        Grupos, setGrupos,
        AmaLlaves, setAmaLlaves,
        Minivacaciones, setMinivacaciones,
        Restricción, setRestricción,
        Contactar, setContactar,
       
       
      }}
    >
      {children}
    </FrecOptionContext.Provider>
  );
};
