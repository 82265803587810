import { useEffect, useState, Suspense } from "react";
import axios from "axios";
import swal from "sweetalert";
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import {
  Link,
  LinkHistoryResponse,
} from "../../interfaces/LinkHistoryInterface";
import { trackPromise } from "react-promise-tracker";
import { API_ADMIN } from "../../../api/GlobalApi";
import { Toolbar } from "@material-ui/core";
import "./styles/icon.css";
import HandleEditLinkPay from "../editLink/HandleEditLinkPay";
import "./styles/linkHistoryArticle.css";
import SearchIcon from "@mui/icons-material/Search";
import ReusableDialogs from "../../../reusableFunctionalComponents/ReusableDialogs";
import DateTransform from "../../../hooks/utils/DateTransform";

const LinkHistoryArticle = () => {
  let date = new Date();
  let initialDate = date.getDate() - 7;
  date.setDate(initialDate);
  const [links, setLinks] = useState<any>([]);
  const link: any = undefined;
  const history = useHistory();
  const enter: boolean = true;
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [payKey, setPayKey] = useState<any>();
  const [dateSince, setDateSince] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [dif, setDif] = useState<string>("");
  const [searchDialog, setSearchDialog] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>("");
  const [idNumber, setIdNumber] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [detail, setDetail] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    const validation = async () => {
      const getLinks = async () => {
        trackPromise(
          axios.post<LinkHistoryResponse>(
            API_ADMIN + "/v5/linkpay/listLinkPay",
            {
              page: page,
              size: 7,
              dateStart: DateTransform(dateSince),
              dateEnd: DateTransform(to),
              currency: currency,
              idNumber: idNumber,
              state: state,
              name: name,
              invoiceNumber: invoiceNumber,
              detail: detail,
            },
            // dateSince === ''?
            // API_ADMIN +
            // `/v5/linkpay/listLinkPay?idType=3&idCust=${sessionStorage.getItem(
            //   "customerId"
            // )}&size=8&searchValue=${search}&page=${page}`:
            // API_ADMIN +
            // `/v5/linkpay/listLinkPay?idType=3&idCust=${sessionStorage.getItem(
            //   "customerId"
            // )}&size=8&searchValue=${search}&page=${page}&dateStart=${dateSince}&dateEnd=${to}`,
            {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            }
          )
        )
          .then((response) => {
            if (response.data.links) {
              setLinks(response.data.links);

              setPages(response.data.pageCount);
            }
          })
          .catch((error) => {
            swal("Error", "No se pudo cargar el listado de links", "error");
          });
      };
      setTimeout(() => {
        getLinks();
      }, 800);
    };
    validation();
  }, [page, enter, searchDialog]);

  useEffect(() => {
    navigator.clipboard.writeText(link);
  }, [link, page]);

  return (
    <div className="articleContainer">
      <Suspense fallback={<div></div>}>
        <div className="paperContainer">
          <Paper className="muiPaper">
            <TableContainer className="tableContainer">
              <Toolbar>
                <Button
                  onClick={(e) => {
                    history.push("/generateLinkPay");
                  }}
                  style={{ color: "#000" }}
                >
                  <AddIcon className="addIcon" aria-label="Crear Link" /> Crear
                  Link
                </Button>
                <Typography style={{ flexGrow: 1 }}> </Typography>

                <Button
                  onClick={() => {
                    setDif("filter");
                    handleClickOpen();
                  }}
                  variant="contained"
                >
                  <SearchIcon />
                </Button>
              </Toolbar>
              <Table style={{ zIndex: 19 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="cell">Id</TableCell>
                    <TableCell className="cell">Cliente</TableCell>
                    <TableCell className="cell">Identificación</TableCell>
                    <TableCell className="cell">Estado</TableCell>
                    <TableCell className="cell">Fecha</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {links.map((link: Link) => (
                    <TableRow
                      className="rowTable"
                      onClick={async () => {
                        setDif("edit");
                        sessionStorage.setItem("payKey", link.payKey);
                        const data = await HandleEditLinkPay(link.payKey);
                        setPayKey(data);

                        handleClickOpen();
                      }}
                      key={link.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="row">{link.id}</TableCell>
                      <TableCell className="row">
                        {link.userClientName.substring(0, 12) +
                          " " +
                          link.userClientLastName.substring(0, 12)}
                      </TableCell>
                      <TableCell className="row">
                        {link.userClientIdNumber}
                      </TableCell>
                      <TableCell className="row">{link.statePay}</TableCell>
                      <TableCell className="row">
                        {link.createAt.substring(0, 10)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                page={page}
                count={pages}
                color="primary"
                shape="rounded"
                hideNextButton={true}
                hidePrevButton={true}
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </TableContainer>

            <ReusableDialogs
              selectedValue={selectedValue}
              open={open}
              onClose={handleClose}
              payKey={payKey}
              dif={dif}
              dateSince={dateSince}
              setDateSince={setDateSince}
              setTo={setTo}
              to={to}
              setSearchDialog={setSearchDialog}
              searchDialog={searchDialog}
              setCurrency={setCurrency}
              setIdNumber={setIdNumber}
              setState={setState}
              setName={setName}
              setInvoiceNumber={setInvoiceNumber}
              component={"linkHistory"}
              setDetail={setDetail}
              handleClose={handleClose}
              page={page}
              setPage={setPage}
              id={links.id}
            />
          </Paper>
        </div>
      </Suspense>
      
    </div>
  );
};

export default LinkHistoryArticle;
