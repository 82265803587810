import { useState } from 'react';

const useBase64Image = () => {
  const [base64Image, setBase64Image] = useState<string>('');

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result as string);
      };
    }
  };

  return {
    base64Image,
    handleFileInputChange,
  };
};

export default useBase64Image;
