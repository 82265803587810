import { useEffect, useState, lazy, Suspense } from "react";
import { GenerateLinkPayPost } from "../../interfaces/GenerateLinkPayInterface";
import { useForm } from "./../../../hooks/useForm";
import HandleGenerateLinkPay from "./handles/HandleGenerateLinkPay";
import swal from "sweetalert";
import "./styles/labels.css";
import "./styles/generateLinkpay.css";
import { useContext } from "react";
import GenerateLinkpayArticleValidation from "./validations/GenerateLinkpayArticleValidation";
import { ContextFilterIdNum } from "./context/FilterIdNumContext";
const GenerateEditLinkPay = lazy(
  (): any => import("../../../reusableFunctionalComponents/GenerateEditLinkPay")
);
export const GenerateLinkPayArticle = () => {
  const [link, setLink] = useState<any>("");
  const [button, setButton] = useState(true);
  const [refreshData, setRefreshData] = useState(true);
  const [mailNoti, setMailNoti] = useState<boolean | number>(false);
  const [smsNoti, setSmsNoti] = useState<boolean | number>(false);
  const { name, lastName, email, phoneNumber } = useContext(ContextFilterIdNum);
  const falsed: boolean = false;
  const falsed1: boolean = false;
  const [saveADA, setSaveADA] = useState<boolean>(false);

  const data = {
    customerIdNumber: "",
    customerTypeIdNumber: "",
    userClientName: "",
    userClientLastName: "",
    userClientIdNumber: "",
    userClientTypeNumber: "",
    userClientPhone: "",
    userClientEmail: "",
    amountPay: "",
    detailPay: "",
    dueDatePay: "",
    currencyPay: "",
    expirationTimePay: 1440,
    payKey: "",
    invoiceNumber: "",
    
  };

  const { handleChange, formulario } = useForm<GenerateLinkPayPost>({
    customerIdNumber: "",
    customerTypeIdNumber: "",
    userClientName: "",
    userClientLastName: "",
    userClientIdNumber: "",
    userClientTypeNumber: "0",
    userClientPhone: "",
    userClientEmail: "",
    amountPay: "",
    detailPay: "",
    dueDatePay: "",
    currencyPay: "CRC",
    expirationTimePay: 1440,
    invoiceNumber: "",
    sendMailNotification: false,
    sendSmsNotification: false
  });

  useEffect(() => {
    if(mailNoti){
      setMailNoti(1)
    }
    if(smsNoti){
      setSmsNoti(1)
    }
  },[mailNoti, smsNoti])

  useEffect(() => {
    
      formulario.userClientName = "";
      formulario.userClientLastName = "";
      formulario.userClientPhone = "";
      formulario.userClientEmail = "";
      
  }, []);

  useEffect(() => {
    GenerateLinkpayArticleValidation(
      formulario,
      setButton,
      name,
      lastName,
      email,
      phoneNumber
    );
  }, [formulario]);

 
  useEffect(() => {
    setRefreshData(true);
  }, [refreshData]);

  const submitForm = (e: any) => {
    e.preventDefault();

    const validateToken = async () => {
      const result = await HandleGenerateLinkPay(formulario, mailNoti, smsNoti, saveADA);
      setLink(result);
      setRefreshData(!refreshData);
    };
    validateToken();
  };
  const submitCopy = (): void => {
    const copy = () => {
      navigator.clipboard.writeText(link);
      if (link !== "" && link !== null) {
        swal("Exito", "Su link se copió al portapapeles", "success");
        setRefreshData(!refreshData);
      }
    };
    copy();
  };

  const da = {
    formulario,
    handleChange,
    submitForm,
    data,
    submitCopy,
    refreshData,
    link,
    button,
    falsed,
    falsed1,
    setMailNoti,
    mailNoti,
    smsNoti,
    setSmsNoti,
    saveADA,
    setSaveADA,
  };
  return (
    <div style={{ display: "flex" }}>
      <Suspense fallback={<div>cargando</div>}>
        <GenerateEditLinkPay datas={da} />
      </Suspense>
    </div>
  );
};

export default GenerateLinkPayArticle;
