import { useEffect, useState } from 'react'
import { useForm } from '../../hooks/useForm'
import HandleListSinpeOperationMassive from '../utils/HandleListSinpeOperationMassive';
import {
    Button,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TextFieldProps,
    Typography,
  } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Toolbar } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { ListSinpe } from '../interfaces/ListSinpeOperationMassiveInterface';
import NavBar from '../../layout/navBar/NavBar';
import SideBar from '../../layout/sideBar/SideBar';
import ReusableDialogs from '../../reusableFunctionalComponents/ReusableDialogs';
import { DetailSinpeOperationMassiveResponse } from '../interfaces/DetailSinpeOperationMassive';
import HandleDetailSinpeOperationMassive from '../utils/HandleDetailSinpeOperationMassive';
import { useHistory } from 'react-router-dom';
import useValidateJwt from '../../hooks/useValidateJwt';


const ListSinpeOperationMassive = () => {
    const [pages, setPages] = useState<number>(0);
    const [search, setSearch] = useState<boolean>(false);
    const [page,setPage] = useState<number>(1);
    const [data, setData] = useState<ListSinpe[]>([]);
    const [dif, setDif] = useState<string>('');
    const [open,setOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [id,setId] = useState<number>(1);
    const [detail,setDetail] = useState<DetailSinpeOperationMassiveResponse>();
    const history = useHistory();
    const mouseDownHandler = useValidateJwt();
    const {formulario} = useForm({
        page: 1,
        size: 7,
        id: 0,
        idUser: 0,
        state: "",
        dateStart: "",
        dateEnd: ""
    });
     
    const handleClickOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
      formulario.page = page
    },[page])

    const handleClose = (value: string) => {
      setOpen(false);
      setSelectedValue(value);
    };

    useEffect(() => {
        HandleListSinpeOperationMassive(formulario, setData,setPages)
        
    },[search, page])

    useEffect(() => {
      HandleDetailSinpeOperationMassive(setDetail, id);
    },[id]);

     

    return (
      <div onMouseDown={mouseDownHandler} style={{display: "flex"}}>
        <NavBar route={'Listado de Sinpe Masivo'}/>
          <SideBar/>
        <div className="articleContainer">
          
            <div className="paperContainer">
        <Paper className="muiPaper">
        <TableContainer style={{marginTop: '90px'}} className="tableContainer">
            <Toolbar>
              <Button
                onClick={(e) => {
                history.push("/saveSinpeMassive");
                }}
                style={{ color: "#000" }}
              >
                <AddIcon className="addIcon" aria-label="Crear Link" /> Crear
                Sinpe Masivo
              </Button>
              <Typography style={{ flexGrow: 1 }}> </Typography>


              <Button onClick={() => {
                // setDif('filter');
                // handleClickOpen();
              }} variant='contained'><SearchIcon/></Button>
              
            </Toolbar>
            <Table style={{ zIndex: 19 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="cell">Id de sinpe masivo</TableCell>
                  <TableCell className="cell">Id de cliente</TableCell>
                  <TableCell className="cell">Estado</TableCell>
                  <TableCell className="cell">Fecha</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((datum: ListSinpe) => (
                  <TableRow
                    className="rowTable"
                    onClick={ () => {
                      setDif('edit')
                      setId(datum.id);
                      handleClickOpen();
                    }}
                    key={datum.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="row">{datum.id}</TableCell>
                    <TableCell className="row">
                      {datum.id_user}
                    </TableCell>
                    <TableCell className="row">
                      {datum.state}
                    </TableCell>
                    <TableCell className="row">
                      {datum.create_at.substring(0, 10)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              page={formulario.page}
              count={pages}
              color="primary"
              shape="rounded"
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(event, value) => {
                
                setPage(value);
              }}
            />
          </TableContainer>
        </Paper>
        </div>
        </div>
        <ReusableDialogs
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            dif={dif}
            component={'listSinpeMassive'}
            handleClose={handleClose}
            id={id}
            detail={detail}
          />
      </div>
        
      
      
    )
}

export default ListSinpeOperationMassive
