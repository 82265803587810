import { API_ADMIN } from '../../api/GlobalApi'
import axios from 'axios'
import swal from 'sweetalert'
import { LockUnlockCard } from '../interfaces/CardsInterfaces'
import { trackPromise } from 'react-promise-tracker'

const HandleLockUnlockCard = async(lock: string, form: LockUnlockCard ) => {
    
    try{
       const res = await trackPromise( axios.post(
            API_ADMIN + `/v5/card/${sessionStorage.getItem('backOffice') === 'backOffice'?lock+'BackOffice':lock}`,
            form,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        ))
      
        swal('Exitoso', `Se pudo ${lock === "unlockCard"? "desbloquear": "bloquear"} la tarjeta de forma correcta`, 'success')
    }catch(e){
        
        swal('Error', `No se pudo ${lock === "unlockCard"? "desbloquear": "bloquear"} la tarjeta`,'error')
    }
}

export default HandleLockUnlockCard;
