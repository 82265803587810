import axios from "axios";
import { API_ADMIN } from "../../../api/GlobalApi";
import swal from "sweetalert";
import { trackPromise } from "react-promise-tracker";

const HandlechangePassword = async (user: string) => {
  try {
    await trackPromise(
      axios.post(API_ADMIN + "/v5/auth/forgetpassword", {
        username: user,
        link: "https://admin.ridivipay.com/changePass/key={key}",
      })
    );

    swal(
      "Exitoso",
      "Se envió un correo para recuperar la contraseña",
      "success"
    );
  } catch (e: any) {
    let error = e.response.data.code;
    error == 124
      ? swal("Error", "Usuario inválido", "error")
      : swal("Error", "No se pudo enviar el correo intente mas tarde", "error");
  }
};

export default HandlechangePassword;
