import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, Select, TextField, TextFieldProps } from '@mui/material';
import enLocale from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from '@mui/lab/DatePicker';
import { MenuItem } from '@material-ui/core';

const DialogLinkHistoryFilter = (formulario:any, handleChange: any, props: any) => {
  const {dateSince, to, setDateSince, setTo} = props; 
  return (
        <>
        <DialogTitle className="titleDialog">Buscar</DialogTitle>
        <Grid container alignItems="center" justifyContent="center" spacing="15">
          
          <Grid xs={5} sm={5} item>
          <FormControl fullWidth>
                    <InputLabel>Estado</InputLabel>
                    <Select
                     
                      size="small"
                      label="Moneda"
                      name="state"
                      value={
                        formulario.state
                      }
                      onChange={handleChange}
                    >
                       <MenuItem value="Pendiente">Pendiente</MenuItem>
                      <MenuItem value="Vencido">Vencido</MenuItem>
                      <MenuItem value="Finalizado">Finalizado</MenuItem>
                      
                    </Select>
                  </FormControl>
  
                  <FormHelperText> </FormHelperText>
          </Grid>
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-11px" }}
                    label="Número de Identificación"
                    name="idNumber"
                    value={
                      formulario.idNumber
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Número de Factura"
                    name="invoiceNumber"
                    value={
                      formulario.invoiceNumber
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Nombre"
                    name="name"
                    value={
                      formulario.name
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Detalle"
                    name="detail"
                    value={
                      formulario.detail
                    }
                    size="small"
                    onChange={handleChange}
                    // validators={[`${regexp}`]}
                    // errorMessages={[
                    //   "Ingrese su número de identificación sin guiones ni espacios",
                    // ]}
                    // InputLabelProps={{ className: "labels" }}
  
                    fullWidth
                  />
          </Grid>
         
          <Grid xs={5} sm={5} item>
          <FormControl sx={{position:'relative', top:'6px'}} fullWidth>
                    <InputLabel>Moneda</InputLabel>
                    <Select
                     
                      size="small"
                      label="Moneda"
                      name="currency"
                      value={
                        formulario.currency
                      }
                      onChange={handleChange}
                    >
                     <MenuItem value="CRC">Colones</MenuItem>
                      <MenuItem value="USD">Dólares</MenuItem>
                      <MenuItem value="EUR">Euros</MenuItem>
                    </Select>
                  </FormControl>
  
                  <FormHelperText sx={{position:'relative', top:'6px'}}> </FormHelperText>
          </Grid>
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          
          <Grid xs={5} sm={5} item>
          <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Desde"
                    value={dateSince}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                       
                        setDateSince(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
          <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                  
                >
                  <DatePicker
                  
                    label="Hasta"
                    value={to}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                       
                        setTo(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '-5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid item xs={12}></Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              
              aria-label="Crear Link"
              variant="contained"
              onClick={() => {
                props.setSearchDialog(!props.searchDialog)
                props.setPage(1)
              }}
              fullWidth
            >
              Buscar
            </Button>
          </Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              
              aria-label="Crear Link"
              variant="contained"
              onClick={() => {
                window.location.reload();
              }}
              fullWidth
            >
              Quitar filtros
            </Button>
          </Grid>
          
        </Grid>
        </>
    )
}

export default DialogLinkHistoryFilter
