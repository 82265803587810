import { Button, DialogTitle, Grid, TextField, TextFieldProps } from '@mui/material';
import enLocale from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTransform from '../../../hooks/utils/DateTransform';
import { useEffect, useState } from 'react';

const FilterCardInfo = (props: any) => {
  const {form, handleChange, setSearchDialog, searchDialog} = props.props;
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

 

    useEffect(() => {
        form.dateStart = DateTransform(dateStart);
    },[dateStart]);

    useEffect(() => {
      form.dateEnd = DateTransform(dateEnd);
    },[dateEnd]);

    const search = () => {
      setSearchDialog(!searchDialog)
    }

    return (
        <>
        <DialogTitle className="titleDialog">Buscar</DialogTitle>
        <Grid container alignItems="center" justifyContent="center" spacing="15">
          
          
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-11px" }}
                    label="Id"
                    name="card_id"
                    value={
                      form?.card_id
                    }
                    size="small"
                    onChange={handleChange}
                    fullWidth
                  />
          </Grid>
          
          <Grid xs={5} sm={5} item>
          <TextField
                    
                    style={{ position: "relative", top: "-5px" }}
                    label="Estado"
                    name="state"
                    value={
                      form?.state
                    }
                    size="small"
                    onChange={handleChange}
                    fullWidth
                  />
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       
          <Grid xs={5} sm={5} item>
          <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Desde"
                    value={dateStart}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                       
                        setDateStart(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid xs={5} sm={5} item>
          <LocalizationProvider
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                  
                >
                  <DatePicker
                  
                    label="Hasta"
                    value={dateEnd}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                       
                        setDateEnd(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '-5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid item xs={12}></Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              
              aria-label="Crear Link"
              variant="contained"
              onClick={search}
              fullWidth
            >
              Buscar
            </Button>
          </Grid>
          <Grid xs={11} sm={10.3} item>
            <Button
              style={{background: "gray", marginBottom: "10px",textTransform: "capitalize" }}
              
              aria-label="Crear Link"
              variant="contained"
              onClick={() => {
                window.location.reload();
              }}
              fullWidth
            >
              Quitar filtros
            </Button>
          </Grid>
          
        </Grid>
        </>
    )
}

export default FilterCardInfo
