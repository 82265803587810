import { createContext, FC, useState } from "react";

export type filterIdNumType = { 
        name: string,
        setName: (c: string) => void,
        lastName: string,
        setLastName: (c: string) => void,
        email: string,
        setEmail: (c: string) => void,
        phoneNumber: string,
        setPhoneNumber: (c: string) => void;
}

export const filterIdNumStore = {
    name: '',
    setName: () => {},
    lastName: '',
    setLastName: () => {},
    email: '',
    setEmail: () => {},
    phoneNumber: '',
    setPhoneNumber: () => {},
}

export const ContextFilterIdNum = createContext<filterIdNumType>(filterIdNumStore);

export const ContextFilterIdNumProvider: FC = ({ children}) => {
    const [name, setName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    return (
        <ContextFilterIdNum.Provider value={{name,setName,setLastName,lastName,email,setEmail,setPhoneNumber, phoneNumber}}>
            {children}
        </ContextFilterIdNum.Provider>
    )
}