
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import SummarizeIcon from '@mui/icons-material/Summarize';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import BarChartIcon from "@mui/icons-material/BarChart";
import "./style/sidebar.css";
import { ListItem,ListItemIcon, ListItemText, List } from "@material-ui/core";

const SinpeList = (clasess: any) => {

    const { widths, isOpen, setIsOpen } = useContext(ContextLayOut);
    const [sinpeDisplay, setSinpeDisplay] = useState<string>('none');
    
    useEffect(() => {
        if(isOpen) {
            setSinpeDisplay('none')
        }
    },[isOpen])
    
  return (
    <>
      <ListItem
        className={clasess.clasess.items}
        onClick={(e) => {
          setSinpeDisplay(
            sinpeDisplay === "" ? "none" : sinpeDisplay === "none" ? "" : "none"
          );
          if (isOpen !== false) {
            setIsOpen(false);
          }
        }}
        style={{ width: `${widths - 5}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon className={clasess.clasess.icon}>
          <SendToMobileIcon aria-label="Chat Bot" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>
            SINPE Móvil
            {sinpeDisplay === "none" ? (
              <KeyboardArrowUpIcon
                style={{ position: "relative", top: "6px", left: "11px" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ position: "relative", top: "6px", left: "11px" }}
              />
            )}
          </ListItemText>
        )}
      </ListItem>
      
      <List style={{ display: `${sinpeDisplay}` }}>
      <Link style={{ textDecoration: "none" }} to="/sinpeDashBoard">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <BarChartIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Dashboard</ListItemText>
          </ListItem>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/sinpe">
          <ListItem className={clasess.items} button>
            <ListItemIcon>
              <PlaylistAddCheckIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Verificador</ListItemText>
          </ListItem>
        </Link>
        
        <Link style={{ textDecoration: "none" }} to="/sinpeReports">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <SummarizeIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Reportes</ListItemText>
          </ListItem>
        </Link>
      </List>
    </>
  );
};

export default SinpeList;
