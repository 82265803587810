import { usePromiseTracker } from "react-promise-tracker";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../../layout/navBar/NavBar";
import SideBar from "../../../layout/sideBar/SideBar";
import GenerateReportLinkPayArticle from "./GenerateReportLinkPayArticle";
import useValidateJwt from "../../../hooks/useValidateJwt";
import Loading from "../../../components/globalUtils/Loading";

const GenerateReportLinkPay = () => {
  const mouseDownHandler = useValidateJwt();
  const { promiseInProgress } = usePromiseTracker();

  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }));

  const style22 = styles();
  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Crear Reporte"} />
      <SideBar />
      <div className={style22.content}>
        <div className={style22.offset}></div>
        <GenerateReportLinkPayArticle />
      </div>
      {promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default GenerateReportLinkPay;
