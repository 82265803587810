import useValidateJwt from "../../hooks/useValidateJwt";
import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import SinpeReportsArticle from "./SinpeReportsArticle";
import { makeStyles } from "@material-ui/core/styles";
const SinpeReports = () => {
  const mouseDownHandler = useValidateJwt();
  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }));

  const sinpeReportsStyle = styles();

  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Crear Reporte"} />
      <SideBar />
      <div className={sinpeReportsStyle.content}>
        <div className={sinpeReportsStyle.offset}></div>
        <SinpeReportsArticle />
      </div>
    </div>
  );
};

export default SinpeReports;
