export const ResumeFrecStore = {
    Bienvenida: 1,
    setBienvenida: () =>{},
    Reservas: 10,
    setReservas: () =>{},
    Descuentos: 0,
    setDescuentos: () =>{},
    Descripción: 0,
    setDescripción: () =>{},
    ClubVac: 0,
    setClubVac: () =>{},
    HoraEntrada: 0,
    setHoraEntrada: () =>{},
    Grupos: 0,
    setGrupos: () =>{},
    AmaLlaves: 0,
    setAmaLlaves: () =>{},
    Minivacaciones: 0,
    setMinivacaciones: () =>{},
    Restricción: 0, 
    setRestricción: () =>{},
    Contactar: 0  ,
    setContactar: () =>{},          
}