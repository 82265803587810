import { useForm } from "../hooks/useForm";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DownloadReportAdaBody } from "../rlinkPay/interfaces/DownloadReportListAdas";
import { Button, Card, Grid, TextField, TextFieldProps } from "@mui/material";
import enLocale from "date-fns/locale/en-US";
import { ValidatorForm } from "react-material-ui-form-validator";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTransform from "../hooks/utils/DateTransform";
import { ReportListAdas } from "./utils/ReportListAdas";
import NavBar from "../layout/navBar/NavBar";
import SideBar from "../layout/sideBar/SideBar";
import useValidateJwt from "../hooks/useValidateJwt";
import { useState } from "react";
const GenerateAdaReport = () => {
  const mouseDownHandler = useValidateJwt();
  const [valueSince, setValueSince] = useState<any>(new Date().toISOString());
  const [valueTo, setValueTo] = useState<any>(new Date().toISOString());
  const { formulario } = useForm<DownloadReportAdaBody>({
    dateStart: new Date().toISOString(),
    dateEnd: new Date().toISOString(),
    reportName: "adaList",
    ownerAccountIdNumber: "",
    page: null,
    size: null,
  });
  const submitForm = async (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      ReportListAdas(formulario);
    }, 800);
  };
  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Reporte de pagos recurrentes"} />
      <SideBar />
      <Card
        style={{
          height: "80vh",
          width: "100%",
          backgroundColor: "rgba(255,255,255,0)",
          marginTop: "60px",
        }}
      >
        <ValidatorForm onSubmit={submitForm}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="30"
          >
            <Grid
              style={{ position: "relative", top: "30px" }}
              xs={12}
              sm={5.2}
              item
            >
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Desde"
                  value={valueSince}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      // sessionStorage.setItem("token", prueba )
                      setValueSince(newValue);
                      formulario.dateStart = DateTransform(newValue);
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField style={{ width: "100%" }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            &nbsp;
            <Grid
              style={{ position: "relative", top: "30px" }}
              xs={12}
              sm={5.2}
              item
            >
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Hasta"
                  value={valueTo}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      // sessionStorage.setItem("token", prueba )
                      setValueTo(newValue);
                      formulario.dateEnd = DateTransform(newValue);
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField style={{ width: "100%" }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              style={{ position: "relative", top: "30px" }}
              xs={12}
              sm={10.5}
              item
            >
              <Button
                style={{
                  background: "gray",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={submitForm}
                fullWidth
              >
                Generar Reporte
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Card>
    </div>
  );
};

export default GenerateAdaReport;
