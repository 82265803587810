import { Box, Button, Grid, Typography } from '@mui/material'
import { useContext } from "react";
import { useHistory } from 'react-router-dom';
import HandleDownloadTiket from './handle/HandleDownloadTiket';
import { LinkPaycontext } from "./linkPayContext/LinkPayContext";
const Success = () => {
  const history = useHistory();
  const paykey: string = history.location.pathname.slice(5)
    const {iconClientPay, successImg, textLegendSuccess} = useContext(LinkPaycontext)

      
    return (
        <Grid container>
            <Grid item xs={12}>
              <Box
                border={0}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 1,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "20%",
                    height: "20%",
                    display: "flex",
                  }}
                  src={`${iconClientPay}`}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                border={0}
                component="form"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 1,
                  mt: 3,
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <Typography fontSize={20}>SU PAGO SE REALIZO CON EXITO</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                border={0}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 1,
                  mt: 3,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "20%",
                    height: "20%",
                    display: "flex",
                    p: 0,
                  }}
                  src={successImg}
                />
              </Box>
            </Grid>

            <Grid sx={{display: "flex", alignItems: "center", justifyContent: "center"}} item xs={12}>
                  <Button onClick={() => HandleDownloadTiket(paykey)}>Descargar comprobante</Button>
            </Grid>

            <Grid item xs={12}>
              <Box
                border={0}
                component="form"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 1,
                  mt: 3,
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <Typography sx={{textAlign: 'center'}} fontSize={20}>
                 {textLegendSuccess}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                border={0}
                component="form"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 1,
                  mt: 3,
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <Typography fontSize={10}>
                  Con el respaldo de Ridivi Costa Rica - www.ridivi.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
    )
}

export default Success
