import React from 'react'

const DialogValidations = (dataLink:any, setSimbol: (c:string) => void, setTypeId:(c:string) => void, setExp: (c:string) => void) => {
    
        if (dataLink?.currencyPay === "CRC") {
          setSimbol("₡");
        } else if (dataLink?.currencyPay === "USD") {
          setSimbol("$");
        } else if (dataLink?.currencyPay === "EUR") {
          setSimbol("€");
        }

        if (dataLink?.userClientTypeNumber === "0") {
          setTypeId("Persona Física Nacional");
        } else if (dataLink?.userClientTypeNumber === "3") {
          setTypeId("Persona Jurídica");
        } else if (dataLink?.userClientTypeNumber === "1") {
          setTypeId("Dimex");
        } else if (dataLink?.userClientTypeNumber === "9") {
          setTypeId("Pasaporte");
        }

        if (dataLink?.expirationTimePay === 1440) {
          setExp("1 Dia");
        } else if (dataLink?.expirationTimePay === 2880) {
          setExp("2 Dias");
        } else if (dataLink?.expirationTimePay === 4320) {
          setExp("3 Dias");
        } else if (dataLink?.expirationTimePay === 5760) {
          setExp("4 Dias");
        } else if (dataLink?.expirationTimePay === 7200) {
          setExp("5 Dias");
        } else if (dataLink?.expirationTimePay === 8640) {
          setExp("6 Dias");
        } else if (dataLink?.expirationTimePay === 1080) {
          setExp("7 Dias");
        }
}

export default DialogValidations
