import { useContext, useEffect, useState } from "react";
import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import enLocale from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField, 
  TextFieldProps
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { MenuItem } from "@material-ui/core";
import { useForm } from "../../hooks/useForm";
import {
  CardTypesResponse,
  GetUserDataCardResponse,
  ListAccounts,
  NewCardPost,
} from "../interfaces/CardsInterfaces";
import HandleGetDataUser from "../utils/HandleGetDataUser";
import HandleGetAccountsIban from "../utils/HandleGetAccountsIban";
import HandleNewCard from "../utils/HandleNewCard";
import FilesUpload from "./FilesUpload";
import { NewCardContext } from "../context/NewCardContext";
import Resume from "./Resume";
import Loading from "../../components/globalUtils/Loading";
import { usePromiseTracker } from "react-promise-tracker";
import useValidateJwt from "../../hooks/useValidateJwt";
import { useHistory } from "react-router-dom";
import DateTransform from "../../hooks/utils/DateTransform";
import { HandleGetcardTypes } from "../utils/HandleGetcardTypes";

const NewCard = () => {
  const history = useHistory();
  const mouseDownHandler = useValidateJwt();
  const promiseInProgress = usePromiseTracker();
  const [display, setDisplay] = useState<string>('')
  const { setNewCards } = useContext(NewCardContext);
  const [dateEnd,setDateEnd] = useState('');
  const [cardTypes, setCardTypes] = useState<CardTypesResponse[]>([]);
  const [dataUser, setDataUser] = useState<GetUserDataCardResponse>({
    userIdNumber: 0,
    userName: "",
  });
  let id = sessionStorage.getItem("userIdNumber");
  const [accountsCRC, setAccountsCRC] = useState<ListAccounts>([]);
  const [accountsUSD, setAccountsUSD] = useState<ListAccounts>([]);
  const [newCard, setNewCard] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<number>(0);
  const { formulario, handleChange } = useForm<NewCardPost>({
    cardType:             'PS',
    name:                 "",
    secondLastName:       "",
    district:             "default",
    clientType:           "",
    cardName:             "",
    internalNum:          "",
    lastName:             "",
    birthDate:            "",
    mail:                 `${sessionStorage.getItem("email")}`,
    phone:                "50383969283",
    gender:               "M",
    country:              "1",
    identificationNumber: "",
    countryBirth:         "",
    delegation:           "default",
    typeIdentification:   "01",
    city:                 "default",
    postalCode:           0,
    cellPhone:            "",
    externalNum:          "",
    nationality:          "",
    street:               "default",
    latitude:             "0.0",
    longitude:            "0.0",
    occupation:           "",
    profession:           "",
    turnBusiness:         "",
    entityBirth:          "01",
      
  });
console.log(dataUser.userName)
  useEffect(() => {
    formulario.birthDate = DateTransform(dateEnd);
  },[dateEnd])

  useEffect(() => {
    HandleGetDataUser(setDataUser, setNewCard);
    HandleGetAccountsIban(setAccountsCRC, "CRC");
    HandleGetAccountsIban(setAccountsUSD, "USD");
  }, []);

  useEffect(() => {
    HandleGetcardTypes(setCardTypes)
  },[]);

  useEffect(() => {
    if(dataUser.userName!== undefined && dataUser.userName!== ""){
    formulario.name =  dataUser?.userName?.split(" ")[0];
    formulario.lastName =   dataUser?.userName?.split(" ").length === 4 ? dataUser?.userName?.split(" ")[2]:dataUser?.userName?.split(" ")[1] ;
    formulario.secondLastName = dataUser?.userName?.split(" ").length === 4 ? dataUser?.userName?.split(" ")[3]: dataUser?.userName?.split(" ")[2];
    formulario.identificationNumber = `${dataUser?.userIdNumber}`;
    formulario.cardName = dataUser?.userName?.split(" ")[0]+" "+dataUser?.userName?.split(" ")[2];
    }
  },[dataUser])
  
  console.log(dataUser?.userName?.split(" "));

  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex", position: "relative" }}>
      <NavBar route={"Crear Tarjeta"} />
      <SideBar />
      {!newCard ? (
        <ValidatorForm style={{ display: "flex", position: "relative", marginTop: '100px' }} onSubmit={() => {}}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing="30"
          >
            <Grid xs={12} sm={5} item>
              <FormControl
                style={{ position: "relative", top: "15px" }}
                fullWidth
              >
                <InputLabel>Tipo de tarjeta</InputLabel>
                <Select
                  size="small"
                  name="cardType"
                  label="Tipo de Tarjeta"
                  value={formulario.cardType}
                  onChange={handleChange}
                >
                  {
                    cardTypes.map((type) => (
                      <MenuItem key={type.code} value={type.code}>{type.description}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              <FormHelperText style={{ position: "relative", top: "15px" }}>
                Seleccione el tipo de tarjeta
              </FormHelperText>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item>
              <FormControl
                style={{ position: "relative", top: "15px" }}
                fullWidth
              >
                <InputLabel>Género</InputLabel>
                <Select
                  size="small"
                  name="gender"
                  label="Género"
                  value={formulario.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Femenino</MenuItem>
                </Select>
              </FormControl>

              <FormHelperText style={{ position: "relative", top: "15px" }}>
                Seleccione el genero
              </FormHelperText>
            </Grid>
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ zIndex: 1 }}
                label="Nombre de la tarjeta"
                size="small"
                name="cardName"
                value={dataUser?.userName!== "" && dataUser?.userName!== undefined?dataUser?.userName: formulario.cardName }
                disabled={dataUser?.userName!== ""?true:false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item>
              <TextValidator
                label="Teléfono"
                size="small"
                name="cellPhone"
                value={formulario.cellPhone}
                onChange={handleChange}
                validators={["matchRegexp:^[0-9]{8}$"]}
                errorMessages={[
                  "Ingrese su número de teléfono sin guiones ni espacios",
                ]}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-10px" }}
                label="Código Postal"
                size="small"
                name="postalCode"
                value={formulario.postalCode}
                onChange={handleChange}
                errorMessages={["Ingrese monto"]}
                fullWidth
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Nombre"
                name="name"
                value={
                  dataUser?.userName !== undefined && dataUser?.userName !== ""?(dataUser?.userName?.split(" ")[0]?
                  dataUser?.userName?.split(" ")[0]:
                  '' ): formulario.name
                }
                size="small"
                disabled={dataUser?.userName !== ""?true:false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Primer apellido"
                name="lastName"
                value={
                 
                  dataUser?.userName !== undefined && dataUser?.userName !== ""?(dataUser?.userName?.split(" ").length === 4 ? dataUser?.userName?.split(" ")[2]: dataUser?.userName?.split(" ")[1]):
                  formulario.lastName
                  
                }
                size="small"
                disabled={dataUser?.userName !== ""?true:false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Segundo Apellido"
                name="secondLastName"
                value={
                  
                  dataUser?.userName !== undefined && dataUser?.userName !== ""?(dataUser?.userName?.split(" ").length === 4 ? dataUser?.userName?.split(" ")[3]:dataUser?.userName?.split(" ")[2]):
                  formulario.secondLastName
                    
                }
                size="small"
                disabled={dataUser?.userName.split(" ").length > 2 ?true:false} 
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Numero de identificación"
                name="identificationNumber"
                value={id}
                size="small"
                disabled={id?true: false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            { formulario.cardType !== "PS"?(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item>
              <FormControl
                style={{ position: "relative", top: "-12px" }}
                fullWidth
              >
                <InputLabel style={{ width: "100%" }}>iban USD</InputLabel>
                
                  <Select
                    size="small"
                    name="iban_usd"
                    label="iban USD"
                    value={accountsUSD[0]?.iban}
                    // onChange={handleChange}
                    
                  >
                    {accountsUSD.length !== 0? accountsUSD.map((account) => (
                    <MenuItem key={account.id} value={`${account.iban}`}>
                      {account.iban}
                    </MenuItem>
                     )): <MenuItem value={""}>No hay cuentas</MenuItem>}
                  </Select>
               
              </FormControl>

              <FormHelperText style={{ position: "relative", top: "-12px" }}>
                Seleccione la cuenta en dólares
              </FormHelperText>
            </Grid>
            <Grid xs={12} sm={5} item>
              <FormControl
                style={{ position: "relative", top: "-12px" }}
                fullWidth
              >
                <InputLabel>iban CRC</InputLabel>
                
                  <Select
                    size="small"
                    name="iban_crc"
                    label="iban CRC"
                    value={accountsCRC[0]?.iban}
                    // onChange={handleChange}
                    
                  >
                    {accountsCRC.length !== 0? accountsCRC.map((account) => (
                    <MenuItem key={account.id} value={`${account.iban}`}>
                      {account.iban}
                    </MenuItem>
                    )):<MenuItem value={""}>No hay cuentas</MenuItem>}
                  </Select>
                
              </FormControl>

              <FormHelperText style={{ position: "relative", top: "-12px" }}>
                Seleccione la cuenta en colones
              </FormHelperText>
            </Grid></>):'' }
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item style={{ position: "relative", top: "-11px", left: '20px' }}>
          <LocalizationProvider
          
                  locale={enLocale}
                  dateAdapter={AdapterDateFns}
                  
                >
                  <DatePicker
                  
                    label="Fecha de nacimiento"
                    value={dateEnd}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      if (newValue !== null) {
                       
                        setDateEnd(newValue);
                        
                      }
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => <TextField size="small" variant='outlined' fullWidth style={{ marginLeft: '-5%', height:'60px',}} {...params} />}
                  />
                </LocalizationProvider>
          </Grid>
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Profesión"
                size="small"
                multiline
                name="profession"
                value={formulario.profession}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid xs={12} sm={5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Ocupacion"
                size="small"
                multiline
                name="occupation"
                value={formulario.occupation}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={10.5} item>
              <TextValidator
                style={{ position: "relative", top: "-21px" }}
                label="Rama de negocio"
                size="small"
                multiline
                name="turnBusiness"
                value={formulario.turnBusiness}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={12} item>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            
            <Button
              style={{
                background: "gray",
                textTransform: "capitalize",
                width: "40%",
              }}
              aria-label="Solicitar tarjeta"
              variant="contained"
              onClick={() =>
                HandleNewCard(formulario, setRequestId, setNewCard, setNewCards)
              }
            >
              Solicitar tarjeta
            </Button>
          </div>
          </Grid>
          </Grid>
         
        </ValidatorForm>
      ) : (
        <>
        {formulario.cardType !== "PS"?<div style={{display: `${display}`}}>
        <FilesUpload idNumber={requestId} setDisplay={setDisplay} />
        </div>:history.push('/listCardInfo')}
        { formulario.cardType !== "PS"? <div style={{display: `${display==='none'?'':'none'}`, width: '100%'}}>
          <Resume/>
        </div>: ''}
        </>
      )}
       {promiseInProgress.promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default NewCard;