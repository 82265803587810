import axios from "axios"
import swal from "sweetalert"
import { API_ADMIN } from "../../api/GlobalApi"
import { NewCardPost, NewCardResponse } from "../interfaces/CardsInterfaces"
import { trackPromise } from "react-promise-tracker"

const HandleNewCard = async(formulario: NewCardPost, setRequestId: (c: number) => void, setNewCard:(c: boolean) => void, setNewCards: (c: NewCardResponse[]) => void) => {
   try{
    const res = await trackPromise(axios.post<NewCardResponse>(
        API_ADMIN + '/v5/card/newCard',
        formulario,
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        }
    ))
      setNewCard(true);
      setRequestId(res.data.id);
      setNewCards([res.data]);

    swal('Exitoso', 'Se creo la solicitud de forma correcta', 'success');
   }catch (e) {
    swal('error','error','error');
    setNewCard(false);
   }
}

export default HandleNewCard;