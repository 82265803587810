import axios from "axios"
import { API_ADMIN } from "../../api/GlobalApi"
import { CardTypesResponse } from "../interfaces/CardsInterfaces"
import swal from "sweetalert"

export const HandleGetcardTypes = async(setData: (c: CardTypesResponse[] )=> void) => {
  try {
    const {data} = await axios.get<CardTypesResponse[]>(API_ADMIN + '/v5/card/listCardType',
    {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
    }
    )
    setData(data)
  } catch (error) {
    swal('Error','No se cargaron los tipos de tarjetas','error')
  }
}
