import { createContext, FC, useState } from "react";



export type login = {
    token: string,
    setToken:(c: string) => void,
    refresh: string,
    setRefresh: (c:string) => void,
  }

export const prueba = {
    token: "",
    setToken: () => {},
    refresh: "",
    setRefresh: () => {},
};

export const ContextLogin = createContext<login>(prueba);

export const ContextLoginProvider:FC = ({ children}) => {
    const [token, setToken] = useState<string>("");
    const [refresh, setRefresh] = useState<string>("")
    return (
        <ContextLogin.Provider value={{token,setToken,refresh,setRefresh}}>
            {children}
        </ContextLogin.Provider>
    )
}