import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import HandleMasiveLinks from "./handles/HandleMasiveLinks";
import swal from "sweetalert";

const MasiveLinksArticle = () => {
  const [data, setData] = useState<any[]>([]);
  const [request, setRequest] = useState<any[]>([]);
  const [validateFile, setValidateFile] = useState<boolean>(true);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    setValidateFile(false);

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const customerIdNumber = sessionStorage.getItem("customerId");
  const customerTypeIdNumber = sessionStorage.getItem("customerTypeId");

  useEffect(() => {
   try {
    const formatData = data.map(
      ({
        Identificación,
        Tipo_de_Identificación,
        Nombre,
        Apellidos,
        Correo,
        Teléfono,
        Moneda,
        Monto,
        Número_de_factura,
        Tiempo_de_Expiración,
        Envío_por_correo,
        Detalle,
        Envío_por_sms,
      }) => ({
        amountPay: Monto,
        userClientIdNumber: Identificación,
        customerIdNumber: customerTypeIdNumber,
        customerTypeIdNumber: customerTypeIdNumber,
        userClientTypeNumber: Tipo_de_Identificación,
        userClientLastName: Apellidos,
        userClientName: Nombre,
        userClientEmail: Correo,
        userClientPhone: Teléfono,
        currencyPay: Moneda,
        detailPay: Detalle,
        sendMailNotification:
          Envío_por_correo.localeCompare("si", undefined, {
            sensitivity: "base",
          }) === 0
            ? 1
            : 0,
        sendSmsNotification:
          Envío_por_sms.localeCompare("si", undefined, {
            sensitivity: "base",
          }) === 0
            ? 1
            : 0,
        invoiceNumber: Número_de_factura,
        expirationTimePay:
          Tiempo_de_Expiración === 1
            ? 1440
            : Tiempo_de_Expiración === 2
            ? 2880
            : Tiempo_de_Expiración === 3
            ? 4320
            : Tiempo_de_Expiración === 4
            ? 5760
            : Tiempo_de_Expiración === 5
            ? 7200
            : Tiempo_de_Expiración === 6
            ? 8640
            : Tiempo_de_Expiración === 7
            ? 10080
            : 1440,
      })
    );

    const insertDataCustomer = formatData.map((data) => {
      data["customerIdNumber"] = customerIdNumber;
      data["customerTypeIdNumber"] = customerTypeIdNumber;
      return data;
    });

    setRequest(insertDataCustomer);
   } catch (error) {
    setValidateFile(true);
    swal('Error', 'Formato de archivo incompatible, por favor descarge la plantilla eh intente de nuevo', 'error')
   }
  }, [data]);

  const submitMassiveLinks = () => {
    setValidateFile(true);
    HandleMasiveLinks(request, setValidateFile, validateFile);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100px",
          display: "flex",
          position: "relative",
          top: "20px",
        }}
      >
        <label htmlFor="upload-photo">
          <input
            style={{ display: "none" }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            accept=".xlsx"
            onChange={readUploadFile}
          />

          <Button
            color="primary"
            size="small"
            component="span"
            aria-label="add"
            variant="contained"
            style={{ textTransform: "capitalize" }}
            onClick={() => setValidateFile(false)}
          >
            <AddIcon /> Seleccionar archivo
          </Button>
        </label>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          position: "relative",
          top: "-20px",
        }}
      >
        <a href="https://storage.googleapis.com/ridivi_util/ridivi/BaseLink.xlsx">
          Descargar plantilla{" "}
        </a>
      </div>

      <Button
        onClick={submitMassiveLinks}
        variant="contained"
        sx={{
          marginTop: "10px",
          width: "20%",
          height: "35px",
          textTransform: "capitalize",
        }}
        disabled={validateFile}
      >
        Cargar Archivo
      </Button>
    </div>
  );
};

export default MasiveLinksArticle;
