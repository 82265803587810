import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, TextField, TextFieldProps, Toolbar, Typography } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { useForm } from "../hooks/useForm";
import NavBar from "../layout/navBar/NavBar";
import SideBar from "../layout/sideBar/SideBar";
import { css } from "@emotion/react";
import enLocale from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { usePromiseTracker } from "react-promise-tracker";
import { makeStyles } from "@material-ui/core/styles";
import VisitResumeUsersGraphicWidget from "./reusableDashboardWidgets/VisitResumeUsersGraphicWidget";
import VisitResumeMessagesGraphicWidget from "./reusableDashboardWidgets/VisitResumeMessagesGraphicWidget";
import DateTransform from "../hooks/utils/DateTransform";
import useValidateJwt from "../hooks/useValidateJwt";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 20;
`;


const GenerateDashboard = () => {
  const mouseDownHandler = useValidateJwt();
    const daysBack: number = 7
    let date = new Date();
    let initialDate = date.getDate() - daysBack;
    date.setDate(initialDate)
    let initialDay: any = date ;
    const [refresh, setRefresh] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const { promiseInProgress } = usePromiseTracker();
    const [valueSince, setValueSince] = useState<any>(initialDay);
    const [valueTo, setValueTo] = useState<any>(new Date());
    const [refreshGraphics, setRefreshGraphics] = useState<boolean>(false);
    const [day, setDay] = useState<boolean>(false);
    const [week, setWeek] = useState<boolean>(true);
    const [mount, setMount] = useState<boolean>(false);
    const [year, setYear] = useState<boolean>(false);
    const styles = makeStyles((theme) => ({
      offset: theme.mixins.toolbar,
      content: {
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
    }));
    const history = useHistory();
    
  const {formulario, } = useForm<any>({
  dateInitial: DateTransform(valueSince),
  dateFinish: DateTransform(new Date())
    });
  
   useEffect(() => {
     setRefreshGraphics(!refreshGraphics)
   },[valueSince,valueTo])
  
    useEffect(() => {
      setValueSince(initialDay)
    },[])
  
    useEffect(() => {
      const valid = async () => {
        const tok = sessionStorage.getItem("token");
        const refre = sessionStorage.getItem("refreshToken");
        if (tok !== null && refre !== null) {
          setToken(tok);
          setRefresh(refre);
        }
        
      };
      valid();
    }, [refresh,history,token]);
  
    const style8 = styles();
  
    return (
      <div onMouseDown={mouseDownHandler} style={{ display: "flex", width: "100%", height: "100%" }}>
            
        <Suspense fallback={<div>cargando</div>}>
        <NavBar route={"Dashboard"} />
        <SideBar />
        <div className={style8.content}>
          <div className={style8.offset}></div>
  
          <div
            style={{
              position: "relative",
              top: "50px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
           
            <div style={{ marginTop: "30px", flexDirection: "column" }}>
            <Toolbar>
              <Button 
              disabled={day}
              onClick={() => {
                setDay(true);
                setWeek(false);
                setMount(false);
                setYear(false);
                 let h = new Date();
                let  newDate: any = h.getDate() - 1
                h.setDate(newDate)
                newDate = h
               
                formulario.dateInitial = newDate;
                setValueSince(newDate)}} sx={{flexGrow: 1}}>24h</Button>
              <Button 
              disabled={week}
              onClick={() => {
                setDay(false);
                setWeek(true);
                setMount(false);
                setYear(false);
                 let h = new Date();
                let  newDate: any = h.getDate() - 7
                h.setDate(newDate)
                newDate = h
               
                formulario.dateInitial = newDate;
                setValueSince(newDate)}} sx={{flexGrow: 1}}>1S</Button>
              <Button 

              disabled={mount}
              onClick={() => {
                setDay(false);
                setWeek(false);
                setMount(true);
                setYear(false);
                 let h = new Date();
                let  newDate: any = h.getDate() - 30
                h.setDate(newDate)
                newDate = h
                
                formulario.dateInitial = newDate;
                setValueSince(newDate)}} sx={{flexGrow: 1}}>1M</Button>
              <Button 
              disabled={year}
              onClick={() => {
                setDay(false);
                setWeek(false);
                setMount(false);
                setYear(true);
                 let h = new Date();
                let  newDate: any = h.getDate() - 365
                h.setDate(newDate)
                newDate = h
                
                formulario.dateInitial = newDate;
                setValueSince(newDate)}} sx={{flexGrow: 1}}>1A</Button>
             
           
            
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  label="Desde"
                  value={valueSince}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      formulario.dateInitial =newValue;
                      setValueSince(newValue);
                     
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField size="small" variant='standard' style={{ width: "200px" , marginRight: '10px', height:'60px' }} {...params} />}
                />
              </LocalizationProvider>
        
        
              <LocalizationProvider
                locale={enLocale}
                dateAdapter={AdapterDateFns}
                
              >
                <DatePicker
                
                  label="Hasta"
                  value={valueTo}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      formulario.dateFinish = newValue;
                      setValueTo(newValue);
                      
                    }
                  }}
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps
                  ) => <TextField size="small" variant='standard' style={{ width: "200px", height: "60px"}} {...params} />}
                />
              </LocalizationProvider>
            
            
            </Toolbar>
           
              <Typography style={{ width: "100%", textAlign: "center" }}>
                Resumen de Mensajes
              </Typography>
              <VisitResumeMessagesGraphicWidget form={formulario} change={refreshGraphics} reportType={'ReportVisitFrecD'} title='Resumen de Mensajes' />
              <Toolbar style={{ width: "100%" }}>
                <span style={{ width: "50%" }}>
                  <Typography style={{ width: "100%", textAlign: "center" }}>
                    Resumen de Usuarios
                  </Typography>
                  <VisitResumeUsersGraphicWidget form={formulario} change={refreshGraphics} reportType={'ReportVisitFrecD'} name='Resumen de Usuarios' />
                </span>
                <div style={{ width: "10%" }}></div>
                <span style={{ width: "50%", display: "flex" }}>
                  <div style={{ height: "340px" }}>
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      Opciones más Frecuentes
                    </Typography>
  
                    {/* <ResumeFrecOptionsGraphicWidget form={formulario} change={refreshGraphics} page={'chatbot'}/> */}
                  </div>
                </span>
              </Toolbar>
            </div>
          </div>
        </div>
        </Suspense>
        {promiseInProgress === true ? (
          <div
            style={{
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1000000,
              left: 0,
            }}
          >
            <FadeLoader color="#fc923f" loading={true} css={override} />
          </div>
        ) : null}
      </div>
    );
}

export default GenerateDashboard
