import { useEffect, useState, useContext, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { LinkPaycontext } from "./linkPayContext/LinkPayContext";
import { usePromiseTracker } from "react-promise-tracker";
import swal from "sweetalert";
import LinkPayArticle from "./LinkPayArticle";
import Loading from "../../../components/globalUtils/Loading";
import { HandleGetDataLinkPay } from "./handle/HandleGetDataLinkPay";

const LinkPayV2 = () => {
  const {
    setUrlImgCard,
    setTextLegendSuccess,
    setSuccessImg,
    setTextLegend,
    setPropsImg,
    messageErrorPay,
    setButton,
    terms,
    iban,
    setDetail,
    setId,
    setAmount,
    setIconClientPay,
    setCurrency,
    setProcessPay,
    processPay,
    setPayKey,
    setMessageErrorPay,
    urlImgCard,
    iconClientPay,
    setTerms,
    button,
    amount,
    currency,
    id,
    detail,
    setIban,
    payKey,
    textLegend,
  } = useContext(LinkPaycontext);

  const history = useHistory();
  const routerPayKey: string = history.location.pathname.slice(5);
  const { promiseInProgress } = usePromiseTracker();
  const [props, setProps] = useState<any>();
  const [expired, setExpired] = useState<any>();
  const [urlTermsAndConditions, setUrlTermsAndConditions] =
    useState<string>("");

  const validation = () => {
    if (routerPayKey.length !== 64) {
      history.push("/errorPay");
    }
  };

  useEffect(() => {
    validation();
    sessionStorage.setItem("paykey", routerPayKey);
    HandleGetDataLinkPay(
      setIconClientPay,
      setAmount,
      routerPayKey,
      setDetail,
      setId,
      setPayKey,
      setProps,
      setPropsImg,
      setExpired,
      setTextLegend,
      setSuccessImg,
      setTextLegendSuccess,
      setUrlImgCard,
      setUrlTermsAndConditions,
      setCurrency,
      setProcessPay,
      setMessageErrorPay,
      history
    );
  }, []);

  useEffect(() => {
    const validation = () => {
      if (terms === true && iban.length === 22) {
        setButton(true);
      } else {
        setButton(false);
      }
    };
    validation();
  }, [iban, terms]);

  useEffect(() => {
    if (processPay === "SUCCESS") {
      history.push("/success");
    } else if (processPay === "ERROR" && expired !== true) {
      swal("Error", messageErrorPay, "error");
      setProcessPay("");
    }
  }, [processPay, expired, messageErrorPay, history]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Suspense fallback={<div>cargando</div>}>
        <div style={{ display: "flex", maxWidth: "450px", height: "100%" }}>
          {processPay === "ERROR" && expired !== true ? (
            <>
              {swal("Error", messageErrorPay, "error")}
              {setProcessPay("")}
              <LinkPayArticle
                props={props}
                urlTermsAndConditions={urlTermsAndConditions}
                urlImgCard={urlImgCard}
                iconClientPay={iconClientPay}
                detail={detail}
                iban={iban}
                setIban={setIban}
                terms={terms}
                setTerms={setTerms}
                button={button}
                amount={amount}
                currency={currency}
                id={id}
                setProcessPay={setProcessPay}
                setMessageErrorPay={setMessageErrorPay}
                payKey={payKey}
                textLegend={textLegend}
              />
            </>
          ) : (
            <>
              {messageErrorPay ===
              "El link se encuentra expirado y no puede ser utilizado" ? (
                history.push("/errorPay")
              ) : (
                <LinkPayArticle
                  props={props}
                  urlTermsAndConditions={urlTermsAndConditions}
                  urlImgCard={urlImgCard}
                  iconClientPay={iconClientPay}
                  detail={detail}
                  iban={iban}
                  setIban={setIban}
                  terms={terms}
                  setTerms={setTerms}
                  button={button}
                  amount={amount}
                  currency={currency}
                  id={id}
                  setProcessPay={setProcessPay}
                  setMessageErrorPay={setMessageErrorPay}
                  payKey={payKey}
                  textLegend={textLegend}
                />
              )}
            </>
          )}
          {promiseInProgress === true ? <Loading /> : null}
        </div>
      </Suspense>
    </div>
  );
};

export default LinkPayV2;
