import axios from 'axios'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { trackPromise } from 'react-promise-tracker'

const HandleAssign = async(id: any) => {
    try {
        await trackPromise( axios.post(
            API_ADMIN + '/v5/SinpeMovilEntrante/assignSinpe',
            {
                id: id
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                }
            }
        ))
        swal('Exitoso', 'Se asignó el SINPE Móvil entrante de forma correcta', 'success');
    } catch (error) {
        swal('Error', 'No se pudo asignar el SINPE Móvil entrante. Intente más tarde', 'danger');
    }
    
}

export default HandleAssign
