import { linkpayInterface } from "./linkPayContextInterface";

export const linkPayData: linkpayInterface = {
    id: "",
    setId: () => {},
    detail: "",
    setDetail: () => {},
    iban: "",
    setIban: () => {},
    amount: 0,
    setAmount: () => {},
    button: true,
    setButton: () => {},
    terms: true,
    setTerms: () => {},
    currency: "",
    setCurrency: () => {},
    processPay: "",
    setProcessPay: () => {},
    messageErrorPay: "",
    setMessageErrorPay: () => {},
    iconClientPay: "",
    setIconClientPay: () => {},
    payKey: "",
    setPayKey: () => {},
    propsImg:"",
    setPropsImg: () => {},
    textLegend: '',
    setTextLegend: () => {},
    successImg:'',
    setSuccessImg: () => {},
    textLegendSuccess: '',
    setTextLegendSuccess: () => {},
    urlImgCard: '',
    setUrlImgCard: () => {},
  };