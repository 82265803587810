import NavBar from '../../layout/navBar/NavBar'
import SideBar from '../../layout/sideBar/SideBar'
import CreateUsersArticle from './CreateUsersArticle'
import { makeStyles } from "@material-ui/core/styles";
const CreateUsers = () => {

    const styles = makeStyles((theme) => ({
        offset: theme.mixins.toolbar,
        content: {
          padding: theme.spacing(3),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
    
        },
      }));
    
      const style2 = styles();

    return (
        <div style={{ display: "flex" }}>
        
        <NavBar route={'Crear usuario'}/>
        <SideBar />
        <div className={style2.content}>
          <div className={style2.offset}></div>
              
              
              <CreateUsersArticle />
              
              
        
        </div>
       
      </div>
    )
}

export default CreateUsers
