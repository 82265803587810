import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { API_ADMIN } from '../../../api/GlobalApi';
import { EditLinkPayPost } from '../../interfaces/EditLinkPayInterface';
import swal from "sweetalert";

const HandleProcessEdit = async(paykey: string,formulario: any, smsNoti: boolean, mailNoti: boolean) => {
    try {
        await trackPromise(axios.post<EditLinkPayPost>(
          API_ADMIN + "/v5/linkpay/editLinkPay",
          {
            payKey:            paykey,
            userClientPhone:   formulario.userClientPhone,
            userClientEmail:   formulario.userClientEmail,
            amountPay:         formulario.amountPay,
            detailPay:         formulario.detailPay,
            expirationTimePay: formulario.expirationTimePay,
            statePay:          'Pendiente',
            invoiceNumber: formulario.invoiceNumber,
            sendSmsNotification: smsNoti === true ? 1: 0,
            sendEmailNotification: mailNoti === true ? 1: 0
          },
    
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })

          
        );
         
        swal("Exito", "El link se edito de forma correcta", "success");
        
      } catch (e) {
        swal("Error", "No se pudo editar el link, intente mas tarde", "error");
      }
}

export default HandleProcessEdit
