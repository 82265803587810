import "./auth.css";
import { Tab, Tabs, Toolbar } from "@mui/material";
import LoginArticle from "./LoginArticle";
import React from "react";
import Forgot from "./Forgot";
import Loading from "../globalUtils/Loading";
import { usePromiseTracker } from "react-promise-tracker";

export const Login = () => {
  const promiseInProgress = usePromiseTracker();
  const [tab, setTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <div className="loginContainer">
        <div className="loginForm">
          <Toolbar>
            <Tabs
              sx={{ width: "100%" }}
              value={tab}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab
                sx={{ flexGrow: 1, color: `${tab === 0 ? "blue" : ""}` }}
                value={0}
                label="Iniciar sesión"
              />
              <Tab
                sx={{ flexGrow: 1, color: `${tab !== 0 ? "blue" : ""}` }}
                value={1}
                label="Olvidé mi contraseña"
              />
            </Tabs>
          </Toolbar>

          {tab === 0 ? <LoginArticle /> : <Forgot />}
        </div>
      </div>
      {promiseInProgress.promiseInProgress === true ? <Loading /> : null}

    </>
  );
};
export default Login;
