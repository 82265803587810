import { createContext, FC, useState } from "react";



export type layout = {
    widths: number
    setWidths:(c: number) => void
    isOpen: boolean
    setIsOpen:(c: boolean) => void
  }

export const prueba = {
    widths: 260,
    setWidths: () => {},
    isOpen: false,
    setIsOpen: () => {},

};

export const ContextLayOut = createContext<layout>(prueba);

export const ContextLayOutProvider: FC = ({ children}) => {
    const [widths, setWidths] = useState<number>(260);
    const [isOpen,setIsOpen] = useState<boolean>(false);
    return (
        <ContextLayOut.Provider value={{widths,setWidths,isOpen,setIsOpen}}>
            {children}
        </ContextLayOut.Provider>
    )
}