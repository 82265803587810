import axios from "axios";
import { useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { trackPromise } from "react-promise-tracker";
import { API_ADMIN } from "../../api/GlobalApi";
import { FreqOptionsResponse } from "../../chatbot/interfaces/FreqoptionsInterface";
import {FrecOptionContext} from './../../chatbot/components/generateDashboard/context/FrecOptionsContext';
const ResumeFrecOptionsGraphicWidget = ({form, change, page}:any) => {
    const {Bienvenida, setBienvenida,
      Reservas, setReservas,
      Descuentos, setDescuentos,
      Descripción, setDescripción,
      ClubVac, setClubVac,
      HoraEntrada, setHoraEntrada,
      Grupos, setGrupos,
      AmaLlaves, setAmaLlaves,
      Minivacaciones, setMinivacaciones,
      Restricción, setRestricción,
      Contactar, setContactar,} = useContext(FrecOptionContext);
       
    const [labels, setLabels] = useState<string[]>([])
    
  
    let prueba = new Date();
    prueba.setDate(prueba.getDate() - 6)
    
    
    useEffect(() => {
      
      const Series = async() => {
        // const result:any = await HandleGenerateDashboard(formulario);
        if(page !== 'linkpay'){
        try {
  
          const res = await trackPromise( axios.post<FreqOptionsResponse>(
            API_ADMIN + "/v5/chatbot/reportList",
            {
              dateInitial: form.dateInitial,
              dateFinish: form.dateFinish,
              reportType: 'ReportVisitOptionFrec',
              phoneNumber: "",
              requiredFor: "graphics"
            },
      
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            })
          );
          setBienvenida(res.data.Bienvenida);
          setReservas(res.data.Reservas);
          setDescuentos(res.data.Descuentos);
          setDescripción(res.data.Descripción);
          setClubVac(res.data.ClubVac);
          setHoraEntrada(res.data.HoraEntrada);
          setGrupos(res.data.Grupos);
          setAmaLlaves(res.data.AmaLlaves);
          setRestricción(res.data.Restricción);
          setMinivacaciones(res.data.Minivacaciones);
          setContactar(res.data.Contactar);
          setLabels(Object.keys(res.data))
          
        } catch (e) {
         
        }
      }else{
        try {

          const res = await trackPromise( axios.post(
            API_ADMIN + "/v5/linkpay/reportList",
            {
              dateInitial:  form.dateInitial,
              dateFinish: form.dateFinish,
              reportType: 'StaticsState',
              requiredFor: ""
            },
      
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            })
          );
          setBienvenida(res.data.data[0].Pendiente);
          setReservas(res.data.data[0].Finalizado);
          setDescripción(res.data.data[0].Vencido);
          setHoraEntrada(res.data.data[0].Eliminado);
         
          setLabels(Object.keys(res.data.data[0]))
        } catch (e) {
         
        }
      }
      }
      setTimeout(() => {Series();},800)
    },[change]
  
    )
  
  
    const titles = {
      title: {
        text: 'Opciones Más Frecuentes',
        align: 'left'
      }
    }
    const charts = {
      chart: {
        type: 'pie',
        height: 350,
        zoom: {
          enabled: true
        }
      }
    }
  
  
    const options = {
      series: page !== 'linkpay'? [Bienvenida,Reservas,Descuentos,Descripción,ClubVac,HoraEntrada,Grupos, AmaLlaves,Minivacaciones,Restricción,Contactar]: [Bienvenida,Reservas,Descuentos,Descripción,],
     
      dataLabels: {
        enabled: true,
        
      },
     labels:labels,
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              width: 450,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },{
          breakpoint: 1500,
          options: {
            chart: {
              width: 300,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 400,
              height: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 300
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 800,
          options: {
            chart: {
              width: 200
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              width: 150
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 100
            },
            
            
            legend: {
              position: "bottom"
            },
            
          },
          
        }
      ],
    };
    return (
      
            <ReactApexChart
              width={425}
              options={options}
              series={options.series}
              type="pie"
              height={425}
            />
         
    );
  };
  
  export default ResumeFrecOptionsGraphicWidget;