import React from 'react'
import NavBar from '../../layout/navBar/NavBar'
import SideBar from '../../layout/sideBar/SideBar'
import ListCardInfoArticle from './ListCardInfoArticle'
import Loading from '../../components/globalUtils/Loading'
import { usePromiseTracker } from 'react-promise-tracker'
import useValidateJwt from '../../hooks/useValidateJwt'

const ListCardInfo = () => {
    const promiseInProgress = usePromiseTracker();
    const mouseDownHandler = useValidateJwt();

    return (
        <div onMouseDown={mouseDownHandler} style={{display: "flex"}}>
            <NavBar route={'Listado de tarjetas'}/>
            <SideBar />
            <div style={{width: "100%",height:'80vh', position:'relative', top: '50px'}}>
                <ListCardInfoArticle/>  
            </div>
            {promiseInProgress.promiseInProgress === true ? <Loading /> : null}

        </div>
    )
}

export default ListCardInfo;
