import { MenuItem } from "@material-ui/core";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { CreateUsersPost } from "../../globalInterfaces/CreateUserInterface";
import { useForm } from "../../hooks/useForm";
import HandleCreateUsers from "./HandleCreateUsers";

const CreateUsersArticle = () => {
  const [repeatPass, setRepeatPass] = useState<string>("");
  const [valid, setValid] = useState<string>("");
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const { handleChange, formulario } = useForm<CreateUsersPost>({
    username: "",
    email: "",
    password: "",
    customerIdNumber: "",
    customerTypeIdNumber: "0",
  });
  let regularExp: string = "matchRegexp:^"+`${formulario.password}`;
  let regexp: string =
    formulario.customerTypeIdNumber === "3"
      ? "matchRegexp:^3[0-9]{9}$"
      : formulario.customerTypeIdNumber === "1"
      ? "matchRegexp:^1[0-9]{11}$"
      : formulario.customerTypeIdNumber === "9"
      ? "matchRegexp:^[0-9,a-z,A-Z]{3,16}$"
      : "matchRegexp:^[0-9]{9}$";

  useEffect(() => {
    if (!formulario.password.match("^(?=.*[0-9])")) {
      setValid("La contraseña requiere al menos un   número");
    } else if (!formulario.password.match("(?=.*[a-z])")) {
      setValid("La contraseña requiere almenos una letra   minúscula");
    } else if (!formulario.password.match("(?=.*[A-Z])")) {
      setValid("La contraseña requiere almenos una letra   mayúscula");
    } else if (
      !formulario.password.match("(?=.*[@!/*-+()¿.,;:{}`~<>|°¬!?¡¨[_#$%^&+=])$")
    ) {
      if (
        !formulario.password.match(
          "(?=.*[@!/*-+()¿.,;:{}`~<>|°¬!?¡¨[_#$%^&+=])"
        )
      ) {
        setValid("La contraseña requiere almenos un caracter especial");
      } else {
        setValid("La contraseña tiene que tener minimo 8 caracteres");
      }
    }
  }, [formulario.password]);
useEffect(() => {
  const adminButton = () => {
    if(formulario.email.length>5 &&
      formulario.password.length >= 8 &&
      formulario.username.length >= 3 &&
      repeatPass === formulario.password){
        setDisabled(false)
      }
  }
  adminButton()
},[formulario.password,formulario.username,formulario.email,repeatPass]);
 
 
  useEffect(() => {
    setRefreshData(false)
  },[refreshData])
  return (
    <Card
      style={{
        height: "80vh",
        width: "100%",
        backgroundColor: "rgba(255,255,255,0)",
        marginTop: "60px",
      }}
    >
      <ValidatorForm onSubmit={() => {}}>
        <Grid
          sx={{ marginTop: "30px" }}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="30"
        >
          <Grid xs={12} sm={5.2} item>
            <TextValidator
              //   style={{ position: "relative", top: "5px" }}
              label="Usuario"
              name="username"
              value={refreshData !== true ?formulario.username: formulario.username = ''}
              onChange={handleChange}
              validators={["matchRegexp:^[a-z,A-Z, ,ñ,´]{3,30}$"]}
              errorMessages={["Ingrese su nombre de usuario"]}
              // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
          </Grid>

          <Grid xs={12} sm={5.2} item>
            <TextValidator
              //   style={{ position: "relative", top: "5px" }}
              label="Correo electrónico"
              name="email"
              value={refreshData !== true ?formulario.email: formulario.email = ''}
              onChange={handleChange}
              validators={["isEmail"]}
              errorMessages={["Ingrese su correo electrónico"]}
              // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
          </Grid>

          <Grid xs={12} sm={5.2} item>
            <FormControl
              style={{ position: "relative", top: "15px", width: "100%" }}
            >
              <InputLabel>Tipo de Identificación</InputLabel>
              <Select
                name="customerTypeIdNumber"
                label="Tipo de Identificación"
                value={refreshData !== true ?formulario.customerTypeIdNumber: formulario.customerTypeIdNumber = '0'}
                onChange={handleChange}
              >
                <MenuItem value="0">Persona Física Nacional</MenuItem>
                <MenuItem value="3">Persona Jurídica</MenuItem>
                <MenuItem value="1">Dimex</MenuItem>
                <MenuItem value="9">Pasaporte</MenuItem>
              </Select>
            </FormControl>

            <FormHelperText style={{ position: "relative", top: "15px" }}>
              Seleccione el tipo de identificación
            </FormHelperText>
          </Grid>
          <Grid xs={12} sm={5.2} item>
            <TextValidator
              style={{ position: "relative", top: "5px" }}
              label="  número de identificación"
              name="customerIdNumber"
              value={refreshData !== true ?formulario.customerIdNumber: formulario.customerIdNumber = ''}
              onChange={handleChange}
              validators={[`${regexp}`]}
              errorMessages={[
                "Ingrese su número de identificación sin guiones ni espacios",
              ]}
              // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
          </Grid>
          <Grid sx={{ marginTop: "10px" }} xs={12} sm={5.2} item>
            <TextValidator
              //   style={{ position: "relative", top: "5px" }}
              label="Contraseña"
              name="password"
              value={refreshData !== true ?formulario.password: formulario.password =''}
              onChange={handleChange}
              validators={[
                "matchRegexp:" +
                  "^(?=.*[0-9])" +
                  "(?=.*[a-z])(?=.*[A-Z])" +
                  "(?=.*[@!/*-+()¿.,;:{}`~<>|°¬!?¡¨[_#$%^&+=])" +
                  "(?=\\S+$).{8,20}$",
              ]}
              errorMessages={[valid]}
              // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
          </Grid>
          <Grid sx={{ marginTop: "10px" }} xs={12} sm={5.2} item>
            <TextValidator
              //   style={{ position: "relative", top: "5px" }}
              label="Confirmar contraseña"
              name="repeatPass"
              value={repeatPass}
              onChange={(e: any) => {
                setRepeatPass(e.target.value);
              }}
              validators={[regularExp]}
              errorMessages={[
                "Reescribe tu contraseña",
              ]}
            // InputLabelProps={{ className: "labels" }}

              fullWidth
            />
          </Grid>
          <Grid sx={{ marginTop: "10px" }} xs={12} sm={10.5} item>
            <Button
              style={{
                background: "gray",
                color: "#fff",
              }}
              disabled={disabled}
              variant="contained"
              onClick={() => {

                HandleCreateUsers(formulario);
                setRefreshData(true);
                setRepeatPass('')
              }}
              fullWidth
            >
              Crear usuario
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Card>
  );
};

export default CreateUsersArticle;
