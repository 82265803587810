
import axios from "axios";
import swal from "sweetalert";
import { trackPromise} from 'react-promise-tracker'
import { ReportListResponse } from '../../interfaces/ReportListInterface'
import { API_ADMIN } from "../../../api/GlobalApi";




const HandleGenerateReport = async (
  formulario:string, form:any
    
) => {

  try {

    const res = await trackPromise( axios.post(
      API_ADMIN + "/v5/chatbot/reportList",
      {
        dateInitial:  form.dateInitial,
        dateFinish: form.dateFinish,
        reportType: formulario,
        phoneNumber: "",
        requiredFor: "graphics"
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
    );
    return res.data?.data

    
  } catch (e) {
    swal("Error", "Se ha producido un error al obtener los datos", "error");
  }
};

export default HandleGenerateReport;
