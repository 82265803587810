import axios from "axios";
import swal from "sweetalert";
import { API_ADMIN } from "../../api/GlobalApi";
import { trackPromise } from "react-promise-tracker";

const HandleSaveSinpeOperationMassive = async (sinpe_items: any) => {
  try {
     await trackPromise(
      axios.post(
        API_ADMIN + "/v5/sinpe/saveSinpeOperationMassive",
        {
          sinpeItems: sinpe_items,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
    );
  } catch (e) {
    swal("Error", "Error", "error");
  }
};

export default HandleSaveSinpeOperationMassive;
