import { Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import { Suspense, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { API_ADMIN } from '../../api/GlobalApi';
import {useHistory} from 'react-router-dom';
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";
import './../../rlinkPay/components/linkHistory/styles/linkHistoryArticle.css'
import HandleAction from './handles/HandleAction';
import Loading from '../globalUtils/Loading';

const ListUsersArticle = () => {
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const [pages, setPages] = useState<string>("");
    const [users, setUsers] = useState<any>([]);
    const { promiseInProgress } = usePromiseTracker();
    useEffect(() => {
        const validation = async () => {
          const getLinks = async () => {
            trackPromise(
              axios.get(
                API_ADMIN +
                  `/v5/catalog/user/page?page=${page}&size=6`,
                {
                  headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                  },
                }
              )
            )
              .then((response) => {
                
                if(response.data.content){
                    setPages(response.data.totalPages)
                    setUsers(response.data.content)
                }
               
              })
              .catch((error) => {
                swal("No se pudo cargar", "", "error");
              });
          };
          setTimeout(() => {
            getLinks();
          }, 800);
        };
        validation();
      }, [page]);
 
    return(
    <div className="articleContainer">
    <Suspense fallback={<div></div>}>
    <div className="paperContainer">
      <Paper className="muiPaper">
        <TableContainer className="tableContainer">
          <Toolbar>
            <Button
              onClick={(e) => {
                history.push("/createUsers");
              }}
              style={{ color: "#000" }}
            >
              <AddIcon className="addIcon" aria-label="Crear Link" /> Crear
              usuario
            </Button>
            <Typography style={{ flexGrow: 1 }}> </Typography>
           
          </Toolbar>
          <Table style={{ zIndex: 19 }}>
            <TableHead>
              <TableRow>
                <TableCell className="cell">Id</TableCell>
                <TableCell className="cell">Usuario</TableCell>
                <TableCell className="cell">Correo</TableCell>
                <TableCell className="cell">Estado</TableCell>
                <TableCell className="cell">Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((link: any) => (
                <TableRow
                  className="rowTable"
                  onClick={ () => {
                  
                  }}
                  key={link.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="row">{link.id}</TableCell>
                  <TableCell className="row">{link.username}</TableCell>
                  <TableCell className="row">{link.email}</TableCell>
                  <TableCell className="row">{link.active === true ? 'Activo': 'Inactivo'}</TableCell>
                  <TableCell className="row">{link.active === true ? <Button onClick={() => {HandleAction(link.active, link.id);}}>Desactivar</Button>: <Button onClick={() => {HandleAction(link.active, link.id)}}>Activar</Button>}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Number(pages)}
            color="primary"
            shape="rounded"
            hideNextButton={true}
            hidePrevButton={true}
            onChange={(event, value) => {
              setPage(value - 1);
            }}
          />
        </TableContainer>
        
      </Paper>
      
    </div>
    </Suspense>
        {promiseInProgress === true ? <Loading /> : null}
  </div>
 )
}

export default ListUsersArticle
