import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ContextLayOut } from "../layoutContext/LayOutContext";
import SummarizeIcon from '@mui/icons-material/Summarize';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import "./style/sidebar.css";
import AddCardIcon from '@mui/icons-material/AddCard';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const AdaList = (clasess: any) => {
    const { widths,isOpen, setIsOpen } = useContext(ContextLayOut);
    const [displayLink,setDisplayLink] = useState<string>('none');

    useEffect(() => {
        if(isOpen) {
            setDisplayLink('none');
        }
    },[isOpen])
    return (
        <>
      <ListItem
        className={clasess.clasess.items}
        onClick={(e) => {
          setDisplayLink(
            displayLink === "" ? "none" : displayLink === "none" ? "" : "none"
          );
          if (isOpen !== false) {
            setIsOpen(false);
          }
        }}
        style={{ width: `${widths - 5}px`, paddingRight: 0 }}
        button
      >
        <ListItemIcon 
        className={clasess.clasess.icon}
        >
          <AutorenewIcon aria-label="RLink" className="icon" />
        </ListItemIcon>
        {isOpen ? (
          ""
        ) : (
          <ListItemText className={clasess.clasess.tittle}>
            Cobros {displayLink === "none" ? (
              <KeyboardArrowUpIcon
                style={{ position: "relative", top: "20px", left: "45px" }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ position: "relative", top: "20px", left: "45px" }}
              />
            )} recurrentes{" "}
            
          </ListItemText>
        )}
      </ListItem>
      <List style={{ display: `${displayLink}` }}>
        {/* <Link style={{ textDecoration: "none" }} to="/dashboardLinkPay">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <BarChartIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Dashboard</ListItemText>
          </ListItem>
        </Link> */}

        <Link style={{ textDecoration: "none" }} to="/listAda">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <AddCardIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Pagos recurrentes</ListItemText>
          </ListItem>
        </Link>


         <Link style={{ textDecoration: "none" }} to="/adaReport">
          <ListItem className={clasess.clasess.items} button>
            <ListItemIcon>
              <SummarizeIcon className="icon" />
            </ListItemIcon>
            <ListItemText className={clasess.clasess.tittles}>Reportes</ListItemText>
          </ListItem>
        </Link>

      
      </List>
    </>
    )
}

export default AdaList
