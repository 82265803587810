import axios from 'axios'
import React from 'react'
import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { ListCardInfoReq, ListCardInfoResponse } from '../interfaces/CardsInterfaces'
import { trackPromise } from 'react-promise-tracker'

const HandleListCardInfo = async(setData:(c: ListCardInfoResponse) => void, form: ListCardInfoReq, page: number) => {
    const url = sessionStorage.getItem('backOffice') === 'backOffice'? `/v5/card/listCardInfoBackOffice?dateStart=${form.dateStart}&dateEnd=${form.dateEnd}&state=${form.state}&&page=${page}&id=${form.card_id}&identificationNumber=${form.privilege}` :`/v5/card/listCardInfo?card_id=${form.card_id}&dateStart=${form.dateStart}&dateEnd=${form.dateEnd}&state=${form.state}&page=${page}`
    try{
        const res = await trackPromise( axios.get<ListCardInfoResponse>(
            API_ADMIN + url ,
            {headers: {Authorization: "Bearer " + sessionStorage.getItem("token") }}
        ))
        setData(res.data);
    }catch (e){
        swal("Error", "no se pudo cargar el listado de solicitudes de tarjeta", "error")
    }
}

export default HandleListCardInfo

