import NavBar from "../../layout/navBar/NavBar";
import SideBar from "../../layout/sideBar/SideBar";
import SaveSinpeOperationMassiveArticle from "./SaveSinpeOperationMassiveArticle";
import { makeStyles } from "@material-ui/core/styles";
import { usePromiseTracker } from "react-promise-tracker";
import useValidateJwt from "../../hooks/useValidateJwt";
import Loading from "../../components/globalUtils/Loading";

const SaveSinpeOperationMassive = () => {
  const mouseDownHandler = useValidateJwt();
  const promiseInProgress  = usePromiseTracker();
  const styles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  }));

  const style20 = styles();
  return (
    <div onMouseDown={mouseDownHandler} style={{ display: "flex" }}>
      <NavBar route={"Crear links masivos"} />
      <SideBar />
      <div className={style20.content}>
        <div className={style20.offset}></div>

        <div
          style={{
            width: "100%",
            height: "80vh",
            position: "relative",
            top: "40px",
          }}
        >
          <SaveSinpeOperationMassiveArticle />
        </div>
      </div>
      {promiseInProgress.promiseInProgress === true ? <Loading /> : null}
    </div>
  );
};

export default SaveSinpeOperationMassive;
