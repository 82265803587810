import { trackPromise } from "react-promise-tracker";
import { GetDataLinkPayResponse } from "../../../interfaces/GetDataLinkPay";
import axios from "axios";
import { API_ADMIN } from "../../../../api/GlobalApi";

export const HandleProcessLinkpay = async(setMessageErrorPay: (c: string) => void, setProcessPay:(c: string) => void,payKey: string, iban: string, id: string, setValidation: (c: boolean) => void) => {
    try {
        await trackPromise(
          axios.post<GetDataLinkPayResponse>(
            API_ADMIN + "/v5/linkpay/processLinkPay",
            {
              payKey: payKey,
              externalAccount: iban,
              externalId: id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
        );
  
        setValidation(true);
        setProcessPay("SUCCESS");
      } catch (e: any) {
        let codeError = e?.response?.data?.code;
        setValidation(true);
        switch (codeError) {
          case "101" || "105": {
            setMessageErrorPay("Cuenta IBAN Inválida");
            break;
          }
          case "107" || "114": {
            setMessageErrorPay(
              "La identificación no coincide con la persona propietaria de la cuenta"
            );
            break;
          }
          case "122": {
            setMessageErrorPay("Fondos insuficientes");
            break;
          }
          case "106": {
            setMessageErrorPay(
              "La moneda de la cuenta iban es diferente a la moneda de pago"
            );
            break;
          }
          default: {
            setMessageErrorPay(
              "Ha ocurrido un error al procesar el link, intente más tarde"
            );
            break;
          }
        }
        setProcessPay("ERROR");
      }
}
