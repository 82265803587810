import { Dialog } from '@mui/material';
import SimpleDialogLinkHistory from '../rlinkPay/components/linkHistory/LinkHistoryDialog';
import SimpleDialogMassive from '../rlinkPay/components/masivesLinks/SimpleDialogMassive';
import SinpeDialogs from '../sinpe/SinpeDialogs';
import CloseIcon from '@mui/icons-material/Close';
import DialogInfo from '../sinpeMassive/sinpeMassiveDialogs/DialogInfo';
import FilterCardInfo from '../cards/listCardInfo/dialogs/FilterCardInfo';
import SampleCardInfoDialogs from '../cards/listCardInfo/dialogs/SampleCardInfoDialogs';
import FilterMovements from '../cards/movements/dialogs/FilterMovements';
import './dialogs.css'

const ReusableDialogs = (props: any,) => {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
      };

    

      return(
        <Dialog className='dialogs' onClose={handleClose} open={open}>
          <CloseIcon style={{color: 'grey', position: 'absolute', right: '5px', top: '5px', cursor: 'pointer'}} onClick={handleClose}/>
            {
               props.component === 'linkHistory'?
               SimpleDialogLinkHistory(props):
               props.component === 'massiveLinkHistory'?
               SimpleDialogMassive(props):
               props.component === 'sinpe'? SinpeDialogs(props):
               props.component === 'listSinpeMassive'?  DialogInfo(props.detail):
               props.component === 'listCardInfo'? SampleCardInfoDialogs(props):
               props.component === 'movements'? FilterMovements(props):''
            }
        </Dialog>
      )
}

export default ReusableDialogs
