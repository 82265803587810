import axios from 'axios'

import swal from 'sweetalert'
import { API_ADMIN } from '../../api/GlobalApi'
import { ListSinpe } from '../interfaces/ListSinpeOperationMassiveInterface'
import { trackPromise } from 'react-promise-tracker'

const HandleListSinpeOperationMassive = async(form: any, setData: (c: ListSinpe[]) => void, setPages: (c: number) => void) => {
    try {
        const res = await trackPromise(axios.post(API_ADMIN + '/v5/sinpe/listSinpeOperationMassive', 
        form,
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        }
        ))
        setData(res.data.list);
        setPages(res.data.pageCount);
    } catch (error) {
       swal('Error', 'Error', 'error') 
    }
}

export default HandleListSinpeOperationMassive
