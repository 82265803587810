import { trackPromise } from "react-promise-tracker";
import { API_ADMIN } from "../../../../api/GlobalApi";
import swal from "sweetalert";
import DateTransform from "../../../../hooks/utils/DateTransform";


const HandleGenerateReport = (paykey: string) => {

    let data = {
      payKey: sessionStorage.getItem('paykey')
    };
    
    trackPromise(fetch(API_ADMIN + "/v5/linkpay/getLinkPayBill", {
      method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if(response.status === 200) {
          response.blob().then((blob) => {
            
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `comprobante${DateTransform(new Date())}.pdf`
            );
            document.body.appendChild(link);
            link.click();
          })
        }else{
          swal("Error", "No se pudo descargar el comprobante", "error");
        }
        
      }))
      ;
  };
  
  
  export default HandleGenerateReport